import { useContext, useEffect, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { Heading } from "../Heading";
import { ICertification, ICompany, IEntity } from "../../models";
import DataTable from "../DataTable/DataTable";
import {
	GridRenderCellParams,
	GridSortItem,
	GridSortModel,
	GridValueFormatterParams,
} from "@mui/x-data-grid";
import { useMutation, useQuery } from "react-query";
import { certification as certificationActions } from "../../controllers";
import { UserContext } from "../../contexts/user";
import { useSnackbar } from "notistack";
import { Filters } from "../../routes/Routes.types";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DocumentActionBar } from "../DocumentActionBar";

export function Documents() {
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const [selectedRows, setSelectedRows] = useState<Array<IEntity>>([]);
	const { user } = useContext(UserContext);
	const [certifications, setCertifications] = useState<Array<ICertification>>(
		[]
	);
	const [search] = useState("");
	const [searchParams, setSearchParams] = useSearchParams();
	const [filters, setFilters] = useState<Filters>({
		...(user?.is_superuser
			? {
					companies: searchParams.get("companies")
						? searchParams
								.get("companies")!
								.split(",")
								.map((c) => parseInt(c))
						: [],
			  }
			: {}),
	});
	const [paginationModel, setPaginationModel] = useState({
		page: 0,
		pageSize: 10,
	});
	const [sortModel, setSortModel] = useState<GridSortModel>(
		new Array<GridSortItem>({
			field: "updated_at",
			sort: "desc",
		})
	);
	const [rowCount, setRowCount] = useState<number>(0);
	const [documentLoadingId, setDocumentLoadingId] = useState(-1);

	const { mutate: refetchLink, isLoading: isLoadingRefetch } = useMutation(
		"generate-doc-link",
		({
			certificationId,
			companyId,
		}: {
			certificationId: number;
			companyId: number;
		}) => certificationActions.generateLink(true, certificationId, companyId),
		{
			retry: false,
			onSuccess: (res) => {
				setDocumentLoadingId(-1);
				window.location.href = (res as any)["redirection_url"];
			},
			onError: (error: any) => {
				console.log(error);
				setDocumentLoadingId(-1);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	const { mutate: refetchDocument, isLoading: isLoadingRefetchDocument } =
		useMutation(
			"generate-doc-link",
			({
				certificationId,
				companyId,
			}: {
				certificationId: number;
				companyId: number;
			}) =>
				certificationActions.getContractDocuments(
					true,
					certificationId,
					companyId
				),
			{
				retry: false,
				onSuccess: (res) => {
					setDocumentLoadingId(-1);
					if ((res as any)["document"]) {
						const link = document.createElement("a");
						link.href = (res as any)["document"];
						link.setAttribute("download", "");
						link.setAttribute("target", "_blank");
						document.body.appendChild(link);
						link.click();
						document.body.removeChild(link);
					}
				},
				onError: (error: any) => {
					console.log(error);
					setDocumentLoadingId(-1);
					enqueueSnackbar(t("general.errorMessage"), {
						variant: "error",
					});
				},
			}
		);

	const { refetch, isLoading, isRefetching, isFetching } = useQuery(
		"all-climeco-certifications",
		() =>
			certificationActions.documentsList(
				user?.is_superuser ? undefined : user?.company.id,
				search,
				filters.companies,
				paginationModel.page + 1,
				sortModel.length > 0
					? `${sortModel[0].sort === "desc" ? "-" : ""}${
							sortModel[0].field === "company"
								? "company__name"
								: sortModel[0].field === "estimations"
								? "estimations__product__name"
								: sortModel[0].field
					  }`
					: "-updated_at",
				paginationModel.pageSize
			),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setCertifications(res.results);
				setRowCount(res.count);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	useEffect(() => {
		refetch();
	}, [refetch, search, filters, paginationModel, sortModel]);

	useEffect(() => {
		const companiesValue = searchParams.get("companies");
		if (companiesValue !== (filters.companies?.join(",") || null)) {
			setPaginationModel((paginationModel) => {
				paginationModel.page = 0;
				return paginationModel;
			});
			setSearchParams((params) => {
				if ((filters.companies ?? []).length > 0) {
					params.set("companies", filters.companies!.join(","));
				} else {
					params.delete("companies");
				}
				return params;
			});
		}
	}, [filters, searchParams, setSearchParams]);

	const columns = [
		{
			field: "id",
			headerName: t("documents.id"),
			valueGetter: ({ value }: { value: number }) =>
				Intl.NumberFormat("en", {
					maximumFractionDigits: 4,
				}).format(value),
		},
		{
			field: "company",
			headerName: t("documents.company"),
			minWidth: 150,
			valueFormatter: (params: GridValueFormatterParams<ICompany>) => {
				if (params.value == null) {
					return "";
				}
				return params.value.name;
			},
			flex: 1,
		},
		{
			field: "customer_sign_date",
			headerName: t("documents.customerSignDate"),
			flex: 1,
			minWidth: 150,
			renderCell: (params: GridRenderCellParams<any, Date>) => {
				return (
					params.row.customer_sign_date && (
						<Typography sx={{ marginLeft: 2, fontSize: 14 }}>
							{Intl.DateTimeFormat("en", {
								day: "numeric",
								month: "numeric",
								year: "numeric",
								timeZone: "UTC",
							}).format(new Date(params.row.customer_sign_date))}
						</Typography>
					)
				);
			},
		},
		{
			field: "action",
			headerName: t("documents.actions"),
			sortable: false,
			headerAlign: "right",
			align: "right",
			minWidth: 450,
			renderCell: (row: GridRenderCellParams<any, Date>) => {
				const onClickSign = (e: React.MouseEvent<HTMLButtonElement>) => {
					e.stopPropagation();
					if (user?.is_superuser) {
						setDocumentLoadingId(row.id as number);
						refetchLink({
							certificationId: row.row.id,
							companyId: row.row.company.id,
						});
					}
				};

				return (
					<Box>
						<Button
							sx={{ minHeight: 34 }}
							disabled={isLoadingRefetch || !row.row.climeco_contract_id}
							onClick={(e) => {
								if (row.row.climeco_contract_id) {
									const link = document.createElement("a");
									link.href = `https://apps.docusign.com/api/send/api/accounts/6893ce8d-cd7b-4874-9bda-e950ce58b65a/envelopes/${row.row.climeco_contract_id}/documents/1/`;
									link.setAttribute("target", "_blank");
									document.body.appendChild(link);
									link.click();
									document.body.removeChild(link);
								}
							}}
						>
							{t("documents.openLink")}
						</Button>
						<Button
							sx={{ minHeight: 34 }}
							disabled={isLoadingRefetch || !row.row.climeco_contract_id}
							onClick={(e) => {
								if (row.row.climeco_contract_id) {
									setDocumentLoadingId(row.id as number);
									refetchDocument({
										certificationId: row.row.id,
										companyId: row.row.company.id,
									});
								}
							}}
						>
							{isLoadingRefetchDocument && documentLoadingId === row.id ? (
								<CircularProgress size={12} sx={{ color: "#25406D" }} />
							) : (
								t("documents.download")
							)}
						</Button>
						{!row.row.customer_sign_date ? (
							<Typography
								variant="body1"
								component={"span"}
								sx={{ fontSize: "12px", padding: "10px" }}
							>
								{t("documents.waitingForUser")}
							</Typography>
						) : !row.row.climeco_sign_date ? (
							<Button
								sx={{ minHeight: 34 }}
								disabled={isLoadingRefetch}
								onClick={(e) => {
									if (!row.row.customer_sign_date) {
										enqueueSnackbar(t("documents.canNotSign"), {
											variant: "error",
										});
									} else {
										setDocumentLoadingId(row.id as number);
										onClickSign(e);
									}
								}}
							>
								{isLoadingRefetch && documentLoadingId === row.id ? (
									<CircularProgress size={12} sx={{ color: "#25406D" }} />
								) : (
									t("documents.sign")
								)}
							</Button>
						) : (
							<Typography
								variant="body1"
								component={"span"}
								sx={{ fontSize: "12px", padding: "10px" }}
							>
								{t("documents.signed")}
							</Typography>
						)}
					</Box>
				);
			},
		},
	];

	if (!user?.is_superuser) {
		columns.splice(4, 1);
	}

	useEffect(() => {
		if (user) {
			if (searchParams.get("event") === "signing_complete") {
				enqueueSnackbar(t("documents.signingSuccessful"), {
					variant: "success",
					autoHideDuration: 25000,
				});
				searchParams.delete("event");
				setSearchParams(searchParams);
				setTimeout(() => {
					refetch();
				}, 25000);
			}
		}
	}, [
		searchParams,
		refetch,
		refetchLink,
		enqueueSnackbar,
		setSearchParams,
		user,
		t,
	]);

	return (
		<Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
			<Heading
				title={t("documents.heading")}
				showSearch={false}
				actions={() => {
					return user && user!.is_superuser ? (
						<DocumentActionBar
							selectedRows={selectedRows}
							refetch={refetch}
							filters={filters}
							setFilters={setFilters}
							setPaginationModel={setPaginationModel}
						/>
					) : (
						<></>
					);
				}}
			/>
			<DataTable
				rows={certifications}
				columns={columns}
				setSelectedRows={setSelectedRows}
				rowCount={rowCount}
				paginationModel={paginationModel}
				setPaginationModel={setPaginationModel}
				sortModel={sortModel}
				setSortModel={setSortModel}
				isLoading={isLoading || isRefetching || isFetching}
				sx={{
					border: "1px solid #E3E3E3",
					borderRadius: "28px",
					"& .MuiDataGrid-root": {
						border: "none",
					},
					"& .MuiDataGrid-columnHeaders": {},
					"& .MuiDataGrid-footerContainer": {
						borderTop: "none!important",
					},
				}}
			/>
		</Box>
	);
}
