import { IUser } from "../../models"
import { connectionsClient } from "../../utils/ConnectionsClient"
import { UserTypes } from "./user.types";

class User {
    async list(companyId?: number, search?: string, companies?: Array<number>, page?: number, sort: string = "-updated_at", pageSize: number = 10, signal?: AbortSignal): Promise<{ count: number, results: Array<IUser> }> {
        const endpoint = companyId ? "companies/" + companyId + "/users" : "users"
        const response = await connectionsClient.client.get(endpoint, {
            params: { limit: 999, search: search, ordering: sort, page: page?.toString() ?? "1", company__in: companies && companies.length > 0 ? companies.join(",") : undefined, page_size: pageSize, company__is_test_company: localStorage.getItem("showTestData") ? undefined : false },
            signal
        });
        return response.data;
    }

    async getCurrentUser(): Promise<Array<IUser>> {
        const endpoint = "me/"
        const response = await connectionsClient.client.get(endpoint);
        return response.data.results;
    }

    async getOne(userId: number, companyId?: number): Promise<IUser> {
        const endpoint = companyId ? "companies/" + companyId + "/users/" + userId : "users/" + userId
        const response = await connectionsClient.client.get(endpoint);
        return response.data;
    }

    async create({ username, email, password, companyId, first_name, last_name, is_anonymous, form_id }: UserTypes.Create): Promise<IUser> {
        const endpoint = companyId ? "companies/" + companyId + "/users" : "users"
        const response = await connectionsClient.client.post(endpoint, { username, email: email?.toLowerCase(), password, first_name, last_name, is_anonymous, form_id });
        return response.data;
    }

    async update({ id, username, companyId, first_name, last_name }: UserTypes.Update): Promise<IUser> {
        const endpoint = companyId ? "companies/" + companyId + "/users/" + id : "users/" + id
        const response = await connectionsClient.client.patch(endpoint, { username, first_name, last_name });
        return response.data;
    }

    async changePassword({ id, companyId, original_password, new_password }: UserTypes.Update): Promise<IUser> {
        const endpoint = (companyId ? "companies/" + companyId + "/users/" + id : "users/" + id) + "/change-password/"
        const response = await connectionsClient.client.patch(endpoint, { original_password, new_password });
        return response.data;
    }

    async delete(user: IUser, companyId?: number): Promise<{ res: string }> {
        const endpoint = companyId ? "companies/" + companyId + "/users/" + user.id : "users/" + user.id
        const response = await connectionsClient.client.delete(endpoint);
        return response.data;
    }

}

export const user = new User();