import React, { useContext, useEffect, useRef, useState } from "react";
import { PropTypes } from "./ServiceCard.types";
import { Button, CardMedia, Typography, Box } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { BasicModal } from "../BasicModal";
import { UserContext } from "../../contexts/user";
import { useMutation } from "react-query";
import { analyticsEvent as analyticsEventActions } from "../../controllers";
import { useVisibilityChange } from "@uidotdev/usehooks";
import { useTranslation } from "react-i18next";

export function ServiceCard({ service }: PropTypes) {
	const [modalOpen, setModalOpen] = useState(false);
	const [seen, setSeen] = useState(false);
	const effectRan = useRef(false);
	const componentVisible = useVisibilityChange();

	const { t } = useTranslation();
	const { user } = useContext(UserContext);
	const { mutate } = useMutation(
		"token",
		({
			name,
			label,
			analyticsEventType,
		}: {
			name: string;
			label: string;
			analyticsEventType: "CLICK" | "VIEW" | "OTHER";
		}) => {
			return analyticsEventActions.create({
				name: name,
				label: label,
				url: window.location.href,
				extra: JSON.stringify({ serviceId: service.id }),
				analyticsEventType: analyticsEventType,
				userId: user!.id,
			});
		}
	);

	useEffect(() => {
		if (componentVisible && !seen && !effectRan.current) {
			mutate({
				name: "CLIMECO_SERVICES_VIEW_ON_" + service.name,
				label: "CLIMECO_SERVICES",
				analyticsEventType: "VIEW",
			});
			setSeen(true);
		} else if (!componentVisible) {
			setSeen(false);
		}

		return () => {
			effectRan.current = true;
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [componentVisible, seen]);

	const handleCloseModal = () => {
		setModalOpen(false);
	};

	const handleLearnMoreClick = (
		e: React.MouseEvent<HTMLButtonElement> | React.MouseEvent<HTMLDivElement>
	) => {
		if (e) {
			e.stopPropagation();
		}
		window.open(service.url);
		/*setModalOpen(true);
		mutate({
			name: "CLIMECO_SERVICES_CLICK_ON_" + service.name,
			label: "CLIMECO_SERVICES",
			analyticsEventType: "CLICK",
		});*/
	};

	return (
		<>
			<Card
				variant="outlined"
				sx={{
					height: "100%",
					display: "flex",
					flexDirection: "column",
					cursor: "pointer",
					border: "1px solid #B1D1F0",
					borderRadius: 4,
				}}
				onClick={handleLearnMoreClick}
			>
				{service.image && (
					<Box sx={{ display: "flex", justifyContent: "center" }}>
						<CardMedia
							sx={{ width: "100%", height: 160, objectFit: "cover" }}
							image={service.image}
							title={service.name}
						/>
					</Box>
				)}
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						flexGrow: 1,
						padding: 2,
					}}
				>
					<CardContent>
						<Typography
							variant="body2"
							sx={{
								overflow: "hidden",
								textOverflow: "ellipsis",
								display: "-webkit-box",
								"-webkit-box-orient": "vertical",
								"-webkit-line-clamp": "3",
								textAlign: "center",
							}}
						>
							{service.name}
						</Typography>
						{/*<Typography
							variant="body2"
							sx={{
								overflow: "hidden",
								textOverflow: "ellipsis",
								display: "-webkit-box",
								"-webkit-box-orient": "vertical",
								"-webkit-line-clamp": "3",
							}}
						>
							{service.description}
						</Typography>*/}
					</CardContent>
					<CardActions sx={{ display: "flex", justifyContent: "center" }}>
						<Button
							size="small"
							onClick={handleLearnMoreClick}
							sx={{
								border: "1px solid #34918E",
								color: "#34918E",
								paddingLeft: 4,
								paddingRight: 4,
							}}
						>
							{t("general.learnMore")}
						</Button>
					</CardActions>
				</Box>
			</Card>
			<BasicModal
				showModal={modalOpen}
				handleClose={handleCloseModal}
				width={(100 - 16 + "vw") as any}
			>
				<iframe
					title="Iframe"
					src={service.url}
					width="100%"
					style={{ height: "calc(100vh - 130px)", border: 0 }}
				/>
			</BasicModal>
		</>
	);
}
