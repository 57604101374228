import React, { useState, useEffect, useContext } from "react";
import {
	Typography,
	Box,
	CircularProgress,
	Grid,
	Tabs,
	Tab,
	Button,
	Autocomplete,
	TextField,
} from "@mui/material";
import { PropTypes } from "./Dashboard.types";
import { Heading } from "../Heading";
import { Updates } from "../Updates";
import { useQuery } from "react-query";
import {
	notification as notificationActions,
	certification as certificationActions,
	form as formActions,
	service as serviceActions,
	company as companyActions,
} from "../../controllers";
import { UserContext } from "../../contexts/user";
import {
	ICertification,
	ICompany,
	INotification,
	IService,
} from "../../models";
import { useSnackbar } from "notistack";
import { useNavigate } from "react-router-dom";

import InitialStep from "../InitialStep/InitialStep";
import { QuickActions } from "../QuickActions";
import { TbGraph } from "react-icons/tb";
import { TiDocumentAdd } from "react-icons/ti";
import { MyApplications } from "../MyApplications";
import { useTranslation } from "react-i18next";
import { DisplayContext } from "../../contexts/display";
import { FormsGrid } from "../FormsGrid";
import { ServiceCard } from "../ServiceCard";
import FormResults from "../FormResults/FormResults";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
	inputLabel: {
		textTransform: "uppercase",
		fontSize: 10,
		marginTop: 20,
		marginBottom: 2,
		fontWeight: 500,
		color: "#686868",
		display: "flex",
	},
});

const lastSelectedCompanyKey = "LAST_SELECTED_COMPANY_KEY";

export function Dashboard(props: PropTypes) {
	const { user } = useContext(UserContext);
	const classes = useStyles();
	const { displayMode } = useContext(DisplayContext);
	const [forms, setForms] = useState<{
		form_id: number;
		number_of_sent_forms: number;
		number_of_started_forms: number;
		number_of_completed_forms: number;
		company_id: number;
	} | null>(null);
	const [notifications, setNotifications] = useState<INotification[] | null>(
		null
	);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();

	const [hasAmazonCertificationTodo, setHasAmazonCertificationTodo] = useState<
		boolean | null
	>(null);
	const [firstTime, setFirstTime] = useState<boolean | null>(null);
	const [certifications, setCertifications] = useState<Array<ICertification>>(
		[]
	);
	const [services, setServices] = useState<IService[]>([]);
	const { t } = useTranslation();
	const [tabIndex, setTabIndex] = useState(0);
	const [companies, setCompanies] = useState<ICompany[]>([]);
	const [selectedCompany, setSelectedCompany] = useState<ICompany | null>(null);

	const { refetch: refetchServices, isLoading: isLoadingServices } = useQuery(
		"services-list",
		() => serviceActions.list(),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				const filteredServices = res.filter((service) => {
					if (user?.is_superuser || user?.company?.is_parent_company) {
						return service.target === "BRANDS";
					} else if (user?.company?.is_supplier) {
						return service.target === "SUPPLIERS";
					}
					return false;
				});

				setServices(filteredServices);
			},
			onError: (error) => {
				console.error("Error fetching services:", error);
			},
		}
	);

	const { isLoading, refetch: refetchNotifications } = useQuery(
		"notifications",
		() => notificationActions.list(5, user?.company.id, undefined, true),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setNotifications(res.results);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	const {
		refetch: refetchClimecoCertifications,
		isLoading: isLoadingClimecoCertifications,
	} = useQuery(
		"certifications-climeco-dashboard",
		() =>
			certificationActions.list(
				true,
				user?.company.id,
				undefined,
				undefined,
				undefined,
				undefined,
				999,
				false
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setFirstTime(res.results.length > 0 ? false : true);
				const lcas = Array<number>();
				setCertifications(
					res.results.filter((certification) => {
						const foundLCA = lcas.find((lca) =>
							certification.lca_versions?.find(
								(auxLCAVersion) => auxLCAVersion.lca_id === lca
							)
						);

						if (
							foundLCA === undefined &&
							certification.lca_versions &&
							certification.lca_versions.length > 0
						) {
							lcas.push(certification.lca_versions![0].lca_id ?? -1);
						}

						return !foundLCA || certification.status === "COMPLETED";
					})
				);
				const foundCompletedCertification = res.results.find(
					(c) => c.status === "COMPLETED" && !c.is_historical
				)
					? true
					: false;

				setHasAmazonCertificationTodo(foundCompletedCertification);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	const { refetch: refetchForms, isLoading: isLoadingForms } = useQuery(
		"forms-dashboard",
		() =>
			formActions.getDashboardFormStats(
				selectedCompany ? selectedCompany.id : user?.company.id ?? -1
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setForms(res);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	useEffect(() => {
		if (user?.company?.id && displayMode === "PRODUCTS") {
			refetchNotifications();
			refetchClimecoCertifications();
		}
	}, [user, refetchNotifications, refetchClimecoCertifications, displayMode]);

	useEffect(() => {
		if (
			((!user?.is_superuser && user?.company?.id) ||
				(user?.is_superuser && selectedCompany?.id)) &&
			displayMode === "SUPPLIER"
		) {
			refetchForms();
		}
	}, [
		refetchForms,
		user?.company?.id,
		displayMode,
		selectedCompany,
		user?.is_superuser,
	]);

	useEffect(() => {
		if (
			(user?.company?.id || selectedCompany?.id) &&
			forms &&
			forms?.form_id > -1 &&
			displayMode === "SUPPLIER"
		) {
			refetchServices();
		}
	}, [refetchServices, forms, user?.company?.id, displayMode, selectedCompany]);

	const { refetch: refetchCompanies, isLoading: loadingCompanies } = useQuery(
		["companies"],
		async () => {
			if (!user?.is_superuser) return [];
			const response = await companyActions.list(
				"",
				1,
				"-updated_at",
				999,
				undefined,
				true,
				true
			);
			return response.results;
		},
		{
			enabled: false,
			onSuccess: (data) => {
				setCompanies(data);
				const lastSelectedCompany =
					localStorage.getItem(lastSelectedCompanyKey) &&
					data.find(
						(company) =>
							company.id.toString() ===
							localStorage.getItem(lastSelectedCompanyKey)
					)
						? data.find(
								(company) =>
									company.id.toString() ===
									localStorage.getItem(lastSelectedCompanyKey)
						  )
						: null;
				setSelectedCompany(lastSelectedCompany ?? data[0]);
			},
			onError: (error) => {
				console.error("Error fetching companies:", error);
				enqueueSnackbar(t("form.errorFetchingCompanies"), { variant: "error" });
			},
		}
	);

	useEffect(() => {
		if (selectedCompany) {
			localStorage.setItem(
				lastSelectedCompanyKey,
				selectedCompany.id.toString()
			);
		}
	}, [selectedCompany]);

	useEffect(() => {
		if (user?.is_superuser) {
			refetchCompanies();
		}
	}, [refetchCompanies, user]);

	return (
		<Box>
			{displayMode === "SUPPLIER" ? (
				user?.company?.is_parent_company || user?.is_superuser ? (
					<>
						<Box sx={{ maxWidth: 1000, marginTop: -6 }}>
							<Box
								sx={{
									display: "flex",
									justifyContent: "space-between",
									alignItems: "center",
								}}
							>
								<Tabs
									value={tabIndex}
									onChange={(event, newValue) => setTabIndex(newValue)}
									indicatorColor="primary"
									textColor="primary"
									sx={{
										"& .MuiTabs-indicator": {
											backgroundColor: "#003399",
											height: 3,
											transform: "translateY(-6px)",
										},
										"& .MuiTab-root": {
											textTransform: "none",
											fontWeight: "bold",
											color: "#333",
											minWidth: 0,
											padding: 0,
										},
										"& .MuiTab-root.Mui-selected": {
											color: "#003399",
										},
										"& .MuiTabs-flexContainer": {
											gap: "24px",
										},
									}}
								>
									<Tab label={t("forms.activity")} />
									<Tab label={t("forms.results")} />
								</Tabs>
								{user?.is_superuser && (
									<Box mb={3} sx={{ minWidth: 200 }}>
										<span className={classes.inputLabel}>
											{t("forms.company")}
										</span>
										<Autocomplete
											options={companies}
											getOptionLabel={(option) => option.name || ""}
											value={selectedCompany}
											onChange={(event, newValue) =>
												setSelectedCompany(newValue)
											}
											sx={{ minHeight: 50 }}
											renderInput={(params) => (
												<TextField
													{...params}
													placeholder={t("forms.searchCompany")}
													InputProps={{
														...params.InputProps,
														endAdornment: (
															<>
																{loadingCompanies ? (
																	<CircularProgress color="inherit" size={20} />
																) : null}
																{params.InputProps.endAdornment}
															</>
														),
													}}
												/>
											)}
										/>
									</Box>
								)}
							</Box>

							{isLoadingForms ? (
								<CircularProgress />
							) : forms && forms.form_id > -1 ? (
								<>
									{tabIndex === 0 && (
										<>
											<Box sx={{ marginTop: 2, marginBottom: 4, flex: 1 }}>
												<>
													<Box
														sx={{
															display: "flex",
															flexDirection: {
																xs: "column",
																sm: "row",
															},
															alignItems: "flex-start",
															justifyContent: "space-between",
															width: "100%",
															gap: 1,
															marginBottom: 3,
														}}
													>
														<Box
															sx={{
																flex: 1,
																textAlign: {
																	xs: "center",
																	sm: "left",
																},
															}}
														>
															<Typography variant="h4" sx={{ fontWeight: 600 }}>
																{t("forms.surveyStatus")}
															</Typography>

															<Typography
																variant="body2"
																sx={{
																	marginTop: 1,
																}}
															>
																{t("forms.surveySubtitle")}
															</Typography>
														</Box>

														<Button
															variant="contained"
															sx={{
																backgroundColor: "#00796B",
																color: "#fff",
																fontWeight: "bold",
																textTransform: "none",
																borderRadius: "24px",
																padding: "6px 16px",
																"&:hover": {
																	backgroundColor: "#005f52",
																},
															}}
															onClick={async () => {
																try {
																	await navigator.clipboard.writeText(
																		window.location.origin +
																			`/companies/${
																				selectedCompany
																					? selectedCompany.id
																					: user?.company.id ?? -1
																			}/form-display/${forms?.form_id}/`
																	);
																	enqueueSnackbar(
																		t("climecoCertificationProcess.linkCopied"),
																		{
																			variant: "success",
																		}
																	);
																} catch (err) {
																	enqueueSnackbar(
																		t(
																			"climecoCertificationProcess.linkCopyFailed"
																		),
																		{
																			variant: "error",
																		}
																	);
																}
															}}
														>
															{t("forms.shareForm")}
														</Button>
													</Box>

													<Box
														sx={{
															display: "flex",
															flexWrap: "wrap",
															gap: 4,
															marginTop: 2,
														}}
													>
														<Box
															sx={{
																flex: "1 1 calc(25% - 32px)",
																padding: 3,
																textAlign: "left",
																border: "1px solid #B1D1F0",
																borderRadius: 4,
																backgroundColor: "#fff",
																maxWidth: "200px",
															}}
														>
															<Typography
																variant="h4"
																sx={{ fontSize: 30, marginBottom: 1 }}
															>
																{forms?.number_of_sent_forms ?? 20}
															</Typography>
															<Typography
																variant="body2"
																sx={{ color: "textSecondary" }}
															>
																{t("forms.surveysSent")}
															</Typography>
															<Button
																variant="text"
																sx={{
																	color: "#00796B",
																	fontWeight: "bold",
																	cursor: "pointer",
																	textTransform: "none",
																	padding: 0,
																	minWidth: 0,
																	marginTop: 1,
																	fontSize: 14,
																	"&:hover": {
																		backgroundColor: "transparent",
																	},
																}}
																onClick={async () => {
																	try {
																		await navigator.clipboard.writeText(
																			window.location.origin +
																				`/companies/${
																					selectedCompany
																						? selectedCompany.id
																						: user?.company.id ?? -1
																				}/form-display/${forms?.form_id}/`
																		);
																		enqueueSnackbar(
																			t(
																				"climecoCertificationProcess.linkCopied"
																			),
																			{
																				variant: "success",
																			}
																		);
																	} catch (err) {
																		enqueueSnackbar(
																			t(
																				"climecoCertificationProcess.linkCopyFailed"
																			),
																			{
																				variant: "error",
																			}
																		);
																	}
																}}
															>
																{t("forms.shareSurvey")}
															</Button>
														</Box>

														<Box
															sx={{
																flex: "1 1 calc(25% - 32px)",
																padding: 3,
																textAlign: "left",
																border: "1px solid #B1D1F0",
																borderRadius: 4,
																backgroundColor: "#fff",
																maxWidth: "200px",
															}}
														>
															<Typography
																variant="h4"
																sx={{ fontSize: 30, marginBottom: 1 }}
															>
																{forms?.number_of_started_forms}
															</Typography>
															<Typography
																variant="body2"
																sx={{ color: "textSecondary" }}
															>
																{t("forms.surveysStarted")}
															</Typography>
															<Button
																variant="text"
																sx={{
																	color: "#00796B",
																	fontWeight: "bold",
																	cursor: "pointer",
																	textTransform: "none",
																	padding: 0,
																	minWidth: 0,
																	marginTop: 1,
																	fontSize: 14,
																	"&:hover": {
																		backgroundColor: "transparent",
																	},
																	"&:disabled": {
																		cursor: "default",
																		textDecoration: "none",
																		color: "#B0BEC5!important",
																		backgroundColor: "transparent!important",
																	},
																}}
																disabled
															>
																{t("forms.nudgeSuppliers")}
															</Button>
														</Box>

														<Box
															sx={{
																flex: "1 1 calc(25% - 32px)",
																padding: 3,
																textAlign: "left",
																border: "1px solid #B1D1F0",
																borderRadius: 4,
																backgroundColor: "#fff",
																maxWidth: "200px",
															}}
														>
															<Typography
																variant="h4"
																sx={{ fontSize: 30, marginBottom: 1 }}
															>
																{forms?.number_of_completed_forms}
															</Typography>
															<Typography
																variant="body2"
																sx={{ color: "textSecondary" }}
															>
																{t("forms.surveysCompleted")}
															</Typography>
															<Button
																variant="text"
																sx={{
																	color: "#00796B",
																	fontWeight: "bold",
																	cursor: "pointer",
																	textTransform: "none",
																	padding: 0,
																	minWidth: 0,
																	marginTop: 1,
																	fontSize: 14,
																	"&:hover": {
																		backgroundColor: "transparent",
																	},
																	"&:disabled": {
																		color: "#B0BEC5!important",
																		backgroundColor: "transparent!important",
																		cursor: "default",
																		textDecoration: "none",
																	},
																}}
																disabled
															>
																{t("forms.thankSuppliers")}
															</Button>
														</Box>

														<Box
															sx={{
																flex: "1 1 calc(25% - 32px)",
																padding: 3,
																textAlign: "left",
																border: "1px solid #B1D1F0",
																borderRadius: 4,
																backgroundColor: "#fff",
																maxWidth: "200px",
															}}
														>
															<Typography
																variant="h4"
																sx={{ fontSize: 30, marginBottom: 1 }}
															>
																{Intl.NumberFormat("en", {
																	maximumFractionDigits: 2,
																	minimumFractionDigits: 0,
																}).format(
																	((forms?.number_of_completed_forms ?? 0) /
																		(forms.number_of_sent_forms ?? 20)) *
																		100
																)}
																%
															</Typography>

															<Typography
																variant="body2"
																sx={{ color: "textSecondary" }}
															>
																{t("forms.completionRate")}
															</Typography>
															<Button
																variant="text"
																sx={{
																	color: "#00796B",
																	fontWeight: "bold",
																	cursor: "pointer",
																	textTransform: "none",
																	padding: 0,
																	minWidth: 0,
																	marginTop: 1,
																	fontSize: 14,
																	"&:hover": {
																		backgroundColor: "transparent",
																	},
																}}
																onClick={() => setTabIndex(1)}
															>
																{t("forms.seeResults")}
															</Button>
														</Box>
													</Box>
												</>
											</Box>

											<Box sx={{ marginTop: 6, marginBottom: 4, flex: 1 }}>
												<>
													{isLoadingServices ? (
														<CircularProgress />
													) : (
														services &&
														services.length > 0 && (
															<>
																<Box
																	sx={{
																		flex: 1,
																		marginBottom: 2,
																	}}
																>
																	<Typography
																		variant="h4"
																		sx={{ fontWeight: 600 }}
																	>
																		{t("forms.driveSurveyCompletion")}
																	</Typography>
																</Box>
																<Grid container spacing={3}>
																	{services.map((service) => (
																		<Grid
																			item
																			xs={12}
																			sm={6}
																			md={4}
																			key={service.id}
																		>
																			<ServiceCard service={service} />
																		</Grid>
																	))}
																</Grid>
															</>
														)
													)}
												</>
											</Box>
										</>
									)}
									{tabIndex === 1 && <FormResults forms={forms} />}
								</>
							) : (
								<Typography
									sx={{
										fontSize: 14,
										color: "#777",
									}}
								>
									No data yet
								</Typography>
							)}
						</Box>
					</>
				) : (
					<Box>
						<FormsGrid />
					</Box>
				)
			) : firstTime === null ? (
				<Box
					sx={{
						display: "flex",
						padding: 4,
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<CircularProgress size={24} />
				</Box>
			) : firstTime && user && !user.is_superuser ? (
				<Box sx={{ width: { xs: 400, md: 800 } }}>
					<InitialStep />
				</Box>
			) : (
				<>
					{!user?.is_superuser && (
						<>
							<Heading
								title={t("dashboard.quickActions")}
								subtitle=""
								showSearch={false}
								noMarginBottom
							/>
							<Box
								sx={{
									maxWidth: 800,
									width: "100%",
									display: "flex",
									flexDirection: { xs: "column", sm: "row" },
									gap: 2,
									marginBottom: 4,
								}}
							>
								<QuickActions
									title={t("dashboard.getCertified")}
									description={t("dashboard.applyToGetCertified")}
									buttonTitle={t("dashboard.getCertified")}
									icon={<TiDocumentAdd size={26} />}
									callback={() =>
										navigate("/lca", {
											state: {
												previousPage: window.location.href.includes(":3000")
													? window.location.href.split(":3000")[1]
													: window.location.href.split(".com")[1],
											},
										})
									}
									buttonType="contained"
								/>
								<QuickActions
									title={t("dashboard.stayCertified")}
									description={t("dashboard.updateProductSales")}
									buttonTitle={t("dashboard.updateSales")}
									icon={<TbGraph size={24} />}
									callback={() => navigate("/sales")}
									buttonType="outlined"
								/>
							</Box>
						</>
					)}

					{certifications && certifications.length > 0 && (
						<>
							<Heading
								title={t("dashboard.myLatestApplications")}
								subtitle=""
								showSearch={false}
								noMarginBottom
							/>

							<MyApplications
								certifications={certifications}
								loading={isLoadingClimecoCertifications}
							/>
						</>
					)}
					<Heading
						title={t("dashboard.updates")}
						subtitle=""
						showSearch={false}
						noMarginBottom
					/>
					<Box
						sx={{
							backgroundColor: "#fff",
							marginBottom: 8,
							maxWidth: "800px",
							display: "flex",
							flexDirection: "column",
						}}
					>
						{isLoading || isLoadingClimecoCertifications ? (
							<CircularProgress size={24} />
						) : notifications && notifications.length > 0 ? (
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
								}}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "column",
										flexWrap: "wrap",
									}}
								>
									{notifications
										.slice(0, 3)
										.map((notification, index, array) => {
											return (
												<Box key={notification.id}>
													{(index === 0 ||
														(index > 0 &&
															new Date(
																array[index - 1].created_at
															).getDate() !==
																new Date(array[index].created_at).getDate() &&
															new Date(
																array[index - 1].created_at
															).getMonth() !==
																new Date(array[index].created_at).getMonth() &&
															new Date(
																array[index - 1].created_at
															).getFullYear() !==
																new Date(
																	array[index].created_at
																).getFullYear())) && (
														<Typography
															sx={{
																fontSize: 12,
																color: "#777",
																textTransform: "uppercase",
																marginBottom: 1,
																fontWeight: "bold",
															}}
														>
															{new Intl.DateTimeFormat("en", {
																day: "2-digit",
																month: "2-digit",
																year: "numeric",
															}).format(new Date(notification.created_at))}
														</Typography>
													)}
													<Updates
														key={notification.id}
														notification={notification}
														callback={() => {
															setNotifications((prev) => {
																const auxNotifications = [...prev!];
																const index = auxNotifications.findIndex(
																	(n) => n.id === notification.id
																);
																if (index > -1) {
																	auxNotifications.splice(index, 1);
																}
																return auxNotifications;
															});
															refetchNotifications();
														}}
														productsLength={0}
													/>
												</Box>
											);
										})}
								</Box>
								{notifications.length > 2 && (
									<Typography
										variant="body2"
										onClick={() => {
											navigate("/notifications/");
										}}
										sx={{
											textAlign: "right",
											color: "#34918E",
											fontWeight: "bold",
											marginBottom: 4,
											marginTop: 1,
											fontSize: 12,
											cursor: "pointer",
											"&:hover": { textDecoration: "underline" },
										}}
									>
										{t("dashboard.seeMore")}
									</Typography>
								)}
							</Box>
						) : hasAmazonCertificationTodo ? (
							<Updates
								key={-1}
								notification={{
									id: -1,
									title: t("dashboard.amazonCertification"),
									message: "",
									notification_type: "START_EXTERNAL_CERTIFICATION",
									status: "",
									created_at: new Date().toISOString(),
									users: [],
									product_id: null,
									certification_process_id: null,
									lca_version: null,
									sale_id: null,
									button_title: t("dashboard.apply"),
								}}
								callback={() => {}}
								hideCheckbox={true}
								productsLength={0}
							/>
						) : (
							<Typography variant="body1">
								{t("notifications.noNew")}
							</Typography>
						)}
					</Box>
				</>
			)}
		</Box>
	);
}
