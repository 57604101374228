import { useState, useEffect, useContext } from "react";
import { Box, Button, Chip, Typography, Tooltip } from "@mui/material";
import DataTable from "../DataTable/DataTable";
import { Heading } from "../Heading";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
	GridRenderCellParams,
	GridSortItem,
	GridSortModel,
	GridValueFormatterParams,
	GridValueGetterParams,
} from "@mui/x-data-grid";
import { useQuery } from "react-query";
import { product as productActions } from "../../controllers";
import { useSnackbar } from "notistack";
import {
	ICertification,
	ICertificationSteps,
	ICompany,
	IEntity,
	IProduct,
} from "../../models";
import { UserContext } from "../../contexts/user";
import { ProductActionBar } from "../ProductActionBar";
import { Filters } from "../../routes/Routes.types";
import { ProductStatusChip } from "../ProductDetail/ProductStatusChip";
import { useTranslation } from "react-i18next";

export function Products() {
	const { t } = useTranslation();
	const { user } = useContext(UserContext);
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const [searchParams, setSearchParams] = useSearchParams();
	const [search, setSearch] = useState(searchParams.get("search") ?? "");
	const [filters, setFilters] = useState<Filters>({
		...(user?.is_superuser
			? {
					companies: searchParams.get("companies")
						? searchParams
								.get("companies")!
								.split(",")
								.map((c) => parseInt(c))
						: [],
			  }
			: {}),
	});
	const [paginationModel, setPaginationModel] = useState({
		page: searchParams.get("page") ? parseInt(searchParams.get("page")!) : 0,
		pageSize:
			searchParams.get("pageSize") &&
			!isNaN(parseInt(searchParams.get("pageSize")!))
				? parseInt(searchParams.get("pageSize")!)
				: 10,
	});
	const [sortModel, setSortModel] = useState<GridSortModel>(
		new Array<GridSortItem>({
			field: searchParams.get("order")
				? searchParams.get("order")!.replaceAll("-", "")
				: "updated_at",
			sort: searchParams.get("order")
				? searchParams.get("order")!.includes("-")
					? "desc"
					: "asc"
				: "desc",
		})
	);
	const [products, setProducts] = useState<Array<IProduct>>([]);
	const [rowCount, setRowCount] = useState<number>(0);

	const [selectedRows, setSelectedRows] = useState<Array<IEntity>>([]);

	useEffect(() => {
		const searchValue = searchParams.get("search");
		if ((searchValue ?? "") !== search) {
			setPaginationModel((paginationModel) => {
				paginationModel.page = 0;
				return paginationModel;
			});
			setSearchParams((params) => {
				if (search) {
					params.set("search", search);
				} else {
					params.delete("search");
				}
				return params;
			});
		}
	}, [search, searchParams, setSearchParams]);

	useEffect(() => {
		const companiesValue = searchParams.get("companies");
		if (companiesValue !== (filters.companies?.join(",") || null)) {
			setPaginationModel((paginationModel) => {
				paginationModel.page = 0;
				return paginationModel;
			});
			setSearchParams((params) => {
				if ((filters.companies ?? []).length > 0) {
					params.set("companies", filters.companies!.join(","));
				} else {
					params.delete("companies");
				}
				return params;
			});
		}
	}, [filters, searchParams, setSearchParams]);

	useEffect(() => {
		const pageValue = searchParams.get("page");
		const pageSizeValue = searchParams.get("page");
		if (
			pageValue !== paginationModel.page.toString() ||
			pageSizeValue !== paginationModel.pageSize.toString()
		) {
			setSearchParams((params) => {
				if (pageValue !== paginationModel.page.toString()) {
					if (paginationModel.page !== 0) {
						params.set("page", paginationModel.page.toString());
					} else {
						params.delete("page");
					}
				}
				if (pageSizeValue !== paginationModel.pageSize.toString()) {
					if (paginationModel.pageSize !== 10) {
						params.set("pageSize", paginationModel.pageSize.toString());
					} else {
						params.delete("pageSize");
					}
				}
				return params;
			});
		}
	}, [paginationModel, searchParams, setSearchParams]);

	useEffect(() => {
		const orderValue = searchParams.get("order");
		if (
			sortModel.length > 0 &&
			orderValue !==
				(sortModel[0].sort === "desc" ? "-" : "") + sortModel[0].field
		) {
			setSearchParams((params) => {
				if (
					sortModel[0].sort !== "desc" ||
					sortModel[0].field !== "updated_at"
				) {
					console.log(
						(sortModel[0].sort === "desc" ? "-" : "") + sortModel[0].field
					);
					params.set(
						"order",
						(sortModel[0].sort === "desc" ? "-" : "") + sortModel[0].field
					);
				} else {
					params.delete("order");
				}

				return params;
			});
		}
	}, [sortModel, searchParams, setSearchParams]);

	const { refetch, isLoading, isRefetching, isFetching } = useQuery(
		"products",
		({ signal }) =>
			productActions.list(
				!user?.is_superuser ? user?.company.id : undefined,
				search,
				filters.companies,
				filters.certification,
				paginationModel.page + 1,
				sortModel.length > 0
					? `${sortModel[0].sort === "desc" ? "-" : ""}${
							sortModel[0].field === "company"
								? "company__name"
								: sortModel[0].field
					  }`
					: "-updated_at",
				paginationModel.pageSize,
				signal
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setProducts(
					res.results.map((product) => {
						if (product.decertifying_request && !user?.is_superuser) {
							product.disabled = true;
						}
						return product;
					})
				);
				setRowCount(res.count);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("products.error"), { variant: "error" });
			},
		}
	);

	useEffect(() => {
		refetch();
	}, [refetch, search, filters, paginationModel, sortModel]);

	const columns = [
		{
			field: "name",
			headerName: t("products.headerNameProducts"),
			flex: 1,
			minWidth: 150,
			renderCell: (params: GridRenderCellParams<IProduct>) => {
				return (
					<Tooltip title={params.row.name}>
						<Typography
							variant="body2"
							onClick={() => {}}
							sx={{
								cursor: "pointer",
								color: "#003899",
								fontWeight: "bold",
								"&:hover": { cursor: "pointer", textDecoration: "underline" },
							}}
						>
							{params.row.name}
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			field: "asin",
			headerName: t("products.headerNameAsin"),
			flex: 1,
			minWidth: 150,
		},
		{
			field: "updated_at",
			headerName: t("products.headerNameLastUpdate"),
			valueFormatter: (params: GridValueFormatterParams<string>) => {
				if (params.value == null) {
					return "";
				}
				return Intl.DateTimeFormat("en", {
					day: "numeric",
					month: "numeric",
					year: "numeric",
					timeZone: "UTC",
				}).format(new Date(params.value));
			},
			flex: 1,
			minWidth: 150,
		},
		{
			field: "status",
			headerName: t("products.headerNameProductStatus"),
			flex: 1,
			minWidth: 150,
			renderCell: (params: GridRenderCellParams<IProduct>) => {
				return (
					<>
						<ProductStatusChip status={params.row.status} />
					</>
				);
			},
		},
		{
			field: "company",
			headerName: t("products.headerNameCompany"),
			valueFormatter: (params: GridValueFormatterParams<ICompany>) => {
				if (params.value == null) {
					return "";
				}
				return params.value.name;
			},
			flex: 1,
			minWidth: 150,
		},
		{
			field: "certification_processes",
			headerName: t("products.headerNameClimeCoCertifications"),
			sortable: false,
			renderCell: (row: GridRenderCellParams<any, Date>) => {
				if (row.row == null) {
					return "";
				}

				const certifications = row.row.certification_processes.filter(
					(certificationProcess: ICertification) =>
						certificationProcess.certification?.certification_type ===
						"CLIMECO_INTERNAL_CERTIFICATION"
				);

				const onClickCertificate = () => {
					if (certifications && certifications.length > 0) {
						navigate("/climeco-certificationss/" + certifications[0].id, {
							state: {
								previousPage: window.location.href.includes(":3000")
									? window.location.href.split(":3000")[1]
									: window.location.href.split(".com")[1],
							},
						});
					} else {
						navigate("/climeco-certifications/create", {
							state: { products: [row.row] },
						});
					}
				};

				const getStep = () => {
					if (
						certifications &&
						certifications.length > 0 &&
						certifications[0].certification_process_steps
					) {
						const rejectedStep =
							certifications[0].certification_process_steps.findIndex(
								(step: ICertificationSteps) => step.status === "REJECTED"
							);

						const pendingStep =
							certifications[0].certification_process_steps.findIndex(
								(step: ICertificationSteps) => step.status === "PENDING"
							);

						return rejectedStep > -1
							? rejectedStep
							: pendingStep > -1
							? pendingStep
							: certifications[0].certification_process_steps.length - 1;
					} else {
						return 0;
					}
				};

				const getLabel = () => {
					return certifications &&
						certifications.length > 0 &&
						certifications[0].certification_process_steps &&
						certifications[0].certification_process_steps.length > 0
						? certifications[0].certification_process_steps[getStep()].step
								?.step_title
							? t(
									"products.certification" +
										certifications[0].certification_process_steps[
											getStep()
										].step?.step_title
											.toLowerCase()
											.replace(/\s+/g, "")
							  )
							: t("products.certificationcompleted")
						: t("products.notStarted");
				};

				return certifications &&
					certifications.length > 0 &&
					certifications.find(
						(c: ICertification) =>
							c.certification_process_steps.length > 0 &&
							c.certification_process_steps[
								c.certification_process_steps.length - 2
							].status === "COMPLETED"
					) !== undefined ? (
					<Chip
						label={getLabel()}
						onClick={onClickCertificate}
						size={"small"}
						sx={{
							backgroundColor: "#6ab04c",
							color: "#fff",
							fontWeight: "bold",
							fontSize: 10,
							textTransform: "uppercase",

							"&:hover": { backgroundColor: "#6ab04c" },
						}}
					/>
				) : (
					<>
						{getLabel() === "Not started" ? (
							<>
								<Typography variant="body2">
									{t("products.notStarted")}
								</Typography>
							</>
						) : (
							<Chip
								label={getLabel()}
								onClick={onClickCertificate}
								size={"small"}
								sx={{
									backgroundColor: "#f9ca24",
									color: "#000",
									fontWeight: "bold",
									fontSize: 10,
									textTransform: "uppercase",

									"&:hover": { backgroundColor: "#f9ca24" },
								}}
							/>
						)}
					</>
				);
			},
			flex: 1,
			minWidth: 150,
		},
		{
			field: "external_processes",
			headerName: t("products.headerNameAmazonCertifications"),
			sortable: false,
			valueGetter: (params: GridValueGetterParams) => {
				return params.row.certification_processes;
			},
			renderCell: (row: GridRenderCellParams<any, Date>) => {
				if (row.row == null) {
					return "";
				}

				const climecoCertifications = row.row.certification_processes.filter(
					(certificationProcess: ICertification) =>
						certificationProcess.certification?.certification_type ===
							"CLIMECO_INTERNAL_CERTIFICATION" &&
						!certificationProcess.is_historical
				);

				const certifications = row.row.certification_processes.filter(
					(certificationProcess: ICertification) =>
						certificationProcess.certification?.certification_type ===
						"DEFAULT_EXTERNAL_CERTIFICATION"
				);

				const onClickCertificate = () => {
					if (certifications && certifications.length > 0) {
						navigate("/certifications/" + certifications[0].id);
					} else {
						navigate("/certifications/create", {
							state: { products: [row.row] },
						});
					}
				};

				const getStep = () => {
					if (
						certifications &&
						certifications.length > 0 &&
						certifications[0].certification_process_steps
					) {
						const rejectedStep =
							certifications[0].certification_process_steps.findIndex(
								(step: ICertificationSteps) => step.status === "REJECTED"
							);

						const pendingStep =
							certifications[0].certification_process_steps.findIndex(
								(step: ICertificationSteps) => step.status === "PENDING"
							);

						return rejectedStep > -1
							? rejectedStep
							: pendingStep > -1
							? pendingStep
							: certifications[0].certification_process_steps.length - 1;
					} else {
						return 0;
					}
				};

				const getLabel = () => {
					return certifications &&
						certifications.length > 0 &&
						certifications[0].certification_process_steps.length > 0 &&
						certifications[0].certification_process_steps[getStep()].step
						? certifications[0].certification_process_steps[getStep()].step
								.step_title
						: t("products.notStarted");
				};
				return certifications &&
					certifications.length > 0 &&
					certifications[0].certification_process_steps.length > 0 &&
					certifications[0].certification_process_steps[
						certifications[0].certification_process_steps.length - 2
					].status === "COMPLETED" ? (
					<ProductStatusChip status="ACTIVE" />
				) : climecoCertifications.length > 0 &&
				  (climecoCertifications[0].status === "COMPLETED" ||
						!(climecoCertifications[0].certification_process_steps ?? []).find(
							(c: ICertificationSteps) => c.status !== "COMPLETED"
						)) ? (
					<>
						{getLabel() === "Not started" ? (
							<Button onClick={onClickCertificate}>
								{t("products.certifyButton")}
							</Button>
						) : (
							<Chip
								label={getLabel()}
								onClick={onClickCertificate}
								size={"small"}
								sx={{
									backgroundColor: "#f9ca24",
									color: "#000",
									fontWeight: "bold",
									fontSize: 10,
									textTransform: "uppercase",

									"&:hover": { backgroundColor: "#f9ca24" },
								}}
							/>
						)}
					</>
				) : (
					<Typography variant="body2">{t("products.notEligible")}</Typography>
				);
			},
			flex: 1,
			minWidth: 150,
		},
	];

	if (!user?.is_superuser) {
		columns.splice(4, 1);
	}

	return (
		<>
			<Box>
				<Heading
					title={t("products.title")}
					subtitle={t("products.subtitle")}
					showSearch
					actions={() => (
						<ProductActionBar
							selectedRows={selectedRows}
							refetch={refetch}
							filters={filters}
							setFilters={setFilters}
							setPaginationModel={setPaginationModel}
						/>
					)}
					setSearch={setSearch}
					setPaginationModel={setPaginationModel}
				/>
				<DataTable
					rows={products}
					columns={columns}
					setSelectedRows={setSelectedRows}
					rowCount={rowCount}
					paginationModel={paginationModel}
					setPaginationModel={setPaginationModel}
					sortModel={sortModel}
					setSortModel={setSortModel}
					isLoading={isLoading || isRefetching || isFetching}
					sx={{
						border: "1px solid #E3E3E3",
						borderRadius: "28px",
						"& .MuiDataGrid-root": {
							border: "none",
						},
						"& .MuiDataGrid-columnHeaders": {},
						"& .MuiDataGrid-footerContainer": {
							borderTop: "none!important",
						},
					}}
				/>
			</Box>
		</>
	);
}
