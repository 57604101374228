import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
	Box,
	Typography,
	TextField,
	FormControlLabel,
	Checkbox,
	Radio,
	RadioGroup,
	Button,
	FormControl,
	FormLabel,
	Rating,
	Modal,
	IconButton,
	CircularProgress,
	LinearProgress,
	InputAdornment,
} from "@mui/material";
import { Close as CloseIcon } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import Logo from "../../assets/logo-vertical.png";
import { makeStyles } from "@mui/styles";
import { keyframes } from "@mui/system";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import {
	form as formActions,
	company as companyActions,
	user as userActions,
	token as tokenActions,
} from "../../controllers";
import { UserContext } from "../../contexts/user";
import { IResponse } from "../../models/response-model";
import { useSnackbar } from "notistack";
import { IForm, IQuestion, IUserForm } from "../../models";
import ReactCodeInput from "react-code-input";
import {
	AiOutlineEye as Visibility,
	AiOutlineEyeInvisible as VisibilityOff,
} from "react-icons/ai";

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

const useStyles = makeStyles({
	logo: {
		height: "auto",
		width: 70,
		objectFit: "contain",
	},
});

const newUserKey = "newUser";
const fromCreatedUserKey = "fromCreatedUserKey";

export const FormDisplay: React.FC = () => {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const { user, getUser, getUserPromise } = useContext(UserContext);
	const [form, setForm] = useState<IForm | null>(null);
	const [questions, setQuestions] = useState<Array<IQuestion>>([]);
	const [responses, setResponses] = useState<Array<IResponse> | null>(null);
	const [userForms, setUserForms] = useState<Array<IUserForm> | null>(null);
	const [randomPassword, setRandomPassword] = useState(
		localStorage.getItem("ANONYMOUS_USER_PW")
	);
	const [password, setPassword] = useState("");
	const [repeatPassword, setRepeatPassword] = useState("");
	const [passwordError, setPasswordError] = useState(false);
	const [token, setToken] = useState("");
	const [showPassword, setShowPassword] = useState(false);
	const passwordInputRef = useRef<HTMLInputElement | null>(null);
	const [resendDisabled, setResendDisabled] = useState(false);
	const [countdown, setCountdown] = useState(30);
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
	};
	const navigate = useNavigate();

	useEffect(() => {
		const auxRandomPassword = localStorage.getItem("ANONYMOUS_USER_PW");
		if (auxRandomPassword) {
			setRandomPassword(auxRandomPassword);
		} else {
			const letters = ["Z", "Y", "X", "W", "V", "U", "T", "S", "R", "Q"];
			const lowercaseLetters = [
				"a",
				"b",
				"c",
				"d",
				"e",
				"f",
				"g",
				"h",
				"i",
				"j",
			];
			const characters = ["!?$*"];

			const getRandomValue = (array: Array<String>) => {
				return array[Math.floor(Math.random() * array.length)];
			};

			setRandomPassword(
				(Math.random() * 999999).toString() +
					getRandomValue(letters) +
					getRandomValue(lowercaseLetters) +
					getRandomValue(characters) +
					getRandomValue(letters) +
					getRandomValue(characters) +
					getRandomValue(lowercaseLetters) +
					getRandomValue(letters) +
					getRandomValue(lowercaseLetters) +
					getRandomValue(letters) +
					getRandomValue(characters) +
					getRandomValue(lowercaseLetters) +
					getRandomValue(lowercaseLetters)
			);
		}
	}, []);

	const { id: formId, companyId } = useParams();
	const { refetch: getForm } = useQuery(
		["form", formId],
		() => formActions.getOne(Number(formId), Number(companyId)),
		{
			enabled: false,
			onSuccess: (data) => {
				setForm(data);
			},
		}
	);
	const { refetch: getQuestions } = useQuery(
		["questions", formId],
		() => formActions.getQuestions(Number(companyId), Number(formId)),
		{
			enabled: false,
			onSuccess: (data) => {
				let auxData = [];

				if (!user) {
					auxData = [
						{
							id: -1,
							title: "What is your email?",
							type: "TEXT",
							order: -2,
							form: null,
						} as IQuestion,
						{
							id: -2,
							title: "What is your company name?",
							type: "TEXT",
							order: -1,
							form: null,
						} as IQuestion,
						...data,
					];
				} else if (!user?.company) {
					auxData = [
						{
							id: -2,
							title: "What is your company name?",
							type: "TEXT",
							order: -1,
							form: null,
						} as IQuestion,
						...data,
					];
				} else {
					auxData = [...data];
				}
				setQuestions(auxData);
			},
		}
	);
	const { refetch: getUserResponses } = useQuery(
		["responses", formId],
		() => formActions.getUserResponses(Number(companyId), Number(formId)),
		{
			enabled: false,
			onSuccess(data) {
				setResponses(
					data.sort((a, b) => {
						return (
							questions.findIndex((q) => q.id === a.question?.id) -
							questions.findIndex((q) => q.id === b.question?.id)
						);
					})
				);

				let newUser = null;

				try {
					const searchParams = new URLSearchParams(window.location.search);
					newUser = searchParams.get(newUserKey);
					searchParams.delete(newUserKey);

					const newQueryString = searchParams.toString();
					const newUrl = newQueryString
						? `${window.location.pathname}?${newQueryString}`
						: window.location.pathname;

					window.history.pushState({}, "", newUrl);
				} catch (error) {
					console.log(error);
				}

				if (!responses && data.length > 0) {
					setCurrentIndex(data.length);
				} else if (newUser === "true") {
					setCurrentIndex(0);
				}
			},
		}
	);

	const { mutateAsync: deleteResponse, isLoading: isLoadingDelete } =
		useMutation(
			["delete-responses"],
			({
				responseId,
				questionId,
			}: {
				responseId: number;
				questionId: number;
			}) => {
				return formActions.deleteResponse(
					Number(companyId),
					Number(formId),
					questionId,
					responseId
				);
			},
			{
				onSuccess: async (res) => {
					await getUserResponses();
				},
				onError: (error) => {
					enqueueSnackbar(t("general.errorMessage"), { variant: "error" });
				},
			}
		);

	const { mutate: mutateResponse, isLoading: isLoadingMutate } = useMutation(
		["reset"],
		({ response, questionId }: { response: IResponse; questionId: number }) => {
			return formActions.saveResponse(
				Number(companyId),
				Number(formId),
				questionId,
				response.id,
				response.text_value,
				response.json_value,
				response.number_value,
				response.date_value,
				response.file_value
			);
		},
		{
			onSuccess: async (res) => {
				await getUserResponses();

				await new Promise((resolve) => {
					setTimeout(() => {
						resolve(true);
					}, 500);
				});

				let nextIndex = currentIndex + 1;
				if (nextIndex < visibleQuestions.length) {
					while (
						nextIndex < visibleQuestions.length &&
						!isQuestionVisible(visibleQuestions[nextIndex])
					) {
						nextIndex++;
					}

					setCurrentIndex(nextIndex);
				} else {
					setCurrentIndex(nextIndex);
				}

				/*if (currentIndex < questions.length) {
					setCurrentIndex((prev) => prev + 1);
				} else {
					handleClose();
				}*/
			},
			onError: (error) => {
				enqueueSnackbar(t("general.errorMessage"), { variant: "error" });
			},
		}
	);

	const { mutate: mutateStartReset } = useMutation(
		"start-reset",
		() => {
			return tokenActions.startReset({
				email: user?.email ?? "",
				password: "",
			});
		},
		{
			onSuccess: () => {
				enqueueSnackbar(t("auth.resetLinkSent"), { variant: "success" });
				setShowPassword(false);
			},
			onError: (error) => {
				enqueueSnackbar(t("general.errorMessage"), { variant: "error" });
			},
		}
	);

	const { isLoading: isLoadingReset, mutate: mutateReset } = useMutation(
		"reset",
		() => {
			return tokenActions.reset({ token: token, password: password });
		},
		{
			onSuccess: async () => {
				enqueueSnackbar(t("auth.passwordResetSuccess"), { variant: "success" });
				setShowPassword(false);
				getUser();

				try {
					const response = await fetch(
						`/api/companies/${companyId}/forms/${formId}/user-form/`,
						{
							method: "PATCH",
							headers: {
								"Content-Type": "application/json",
								Authorization: `Bearer ${token}`,
							},
							body: JSON.stringify({}),
						}
					);

					if (!response.ok) {
						console.log("Failed to update completion date");
					}
				} catch (error) {
					console.error(error);
				}
			},
			onError: (error) => {
				enqueueSnackbar(t("general.errorMessage"), { variant: "error" });
			},
		}
	);

	const validatePasswords = (password: string) => {
		const minLength = 8;
		const hasUpperCase = /[A-Z]/.test(password);
		const hasLowerCase = /[a-z]/.test(password);
		const hasNumber = /\d/.test(password);
		// eslint-disable-next-line no-useless-escape
		const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(password);

		return (
			password.length >= minLength &&
			hasUpperCase &&
			hasLowerCase &&
			hasNumber &&
			hasSpecialChar
		);
	};

	const { isLoading: isLoadingLogin, mutate: mutateLogin } = useMutation(
		"mutate-token",
		({ email }: { email: string }) => {
			return tokenActions.login({ email: email, password: randomPassword! });
		},
		{
			onSuccess: () => {
				const url = new URL(window.location.href);
				url.searchParams.set(newUserKey, "true");

				window.history.pushState({}, "", url);
				getUser();
			},
			onError: (error) => {
				enqueueSnackbar(t("auth.wrongCredentials"), { variant: "error" });
			},
		}
	);

	const { isLoading: isLoadingRegister, mutate: mutateRegister } = useMutation(
		"mutate-register",
		() => {
			localStorage.setItem("ANONYMOUS_USER_PW", randomPassword!);
			return userActions.create({
				first_name: "",
				email: (responses ?? [])[currentIndex].text_value!,
				password: randomPassword!,
				username: (responses ?? [])[currentIndex].text_value!,
				is_anonymous: true,
				form_id: Number(formId),
			});
		},
		{
			onSuccess: () => {
				localStorage.setItem(
					"ANONYMOUS_USER_EMAIL",
					(responses ?? [])[currentIndex].text_value!
				);
				localStorage.setItem("ANONYMOUS_USER_PW", randomPassword!);
				if (formId) {
					localStorage.setItem(fromCreatedUserKey + formId, formId?.toString());
				}
				mutateLogin({
					email: (responses ?? [])[currentIndex].text_value!,
				});
			},
			onError: (error: any) => {
				const capitalizeFirstLetter = (text: string) => {
					return text.charAt(0).toUpperCase() + text.slice(1);
				};
				if (error.response.data.email) {
					enqueueSnackbar(capitalizeFirstLetter(error.response.data.email[0]), {
						variant: "error",
					});
				} else {
					enqueueSnackbar(t("general.errorMessage"), { variant: "error" });
				}
			},
		}
	);

	const { mutate: mutateCompany, isLoading: isLoadingCompany } = useMutation(
		"mutate-company",
		() =>
			companyActions.create({
				name: (responses ?? [])[currentIndex].text_value ?? "",
				description: undefined,
				formatted_address: undefined,
				address: undefined,
				city: undefined,
				state: undefined,
				zip_code: undefined,
				country: undefined,
				phone: undefined,
				email: user?.email,
				contact_name: user?.first_name,
				contact_email: user?.email,
				contact_title: undefined,
				historically_approved: new Date(),
				seen_historically_approved_message: new Date(),
				historically_amazon_certified: false,
				legally_recognized_companies: undefined,
				government_entities: undefined,
				payor_companies: undefined,
				company_or_amazon_webpages: undefined,
				company_representative_name: undefined,
				company_representative_title: undefined,
				are_any_entities_deemed_hostile_to_usa: undefined,
				are_any_people_listed_in_ofac: undefined,
				are_any_people_engaged_in_money_laundering: undefined,
				are_any_people_subject_to_usa_trade_restrictions: undefined,
				total_first_year_forecasted_sales: undefined,
				is_supplier: true,
				is_supplier_parent_company: false,
				is_verified: new Date().toISOString(),
				parent_company_id: Number(companyId),
			}),
		{
			retry: false,
			onSuccess: async (res) => {
				await getUserPromise();
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { refetch: getUserForms } = useQuery(
		"get-user-forms",
		() =>
			formActions.getUserForms(
				form?.company ? (form.company as unknown as number) : -1,
				Number(formId ?? -1)
			),
		{
			enabled: false,
			retry: false,
			onSuccess: async (res) => {
				setUserForms(res);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { mutate: mutateUserForm } = useMutation(
		"mutate-user-forms",
		() =>
			formActions.createUserForm(
				form?.company ? (form.company as unknown as number) : -1,
				Number(formId ?? -1)
			),
		{
			retry: false,
			onSuccess: async (res) => {},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const { mutate: updateUserForm } = useMutation(
		"update-user-forms",
		({ userFormId }: { userFormId: number }) =>
			formActions.updateUserForm(
				form?.company ? (form.company as unknown as number) : -1,
				Number(formId ?? -1),
				userFormId
			),
		{
			retry: false,
			onSuccess: async (res) => {},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar("Something went wrong.", { variant: "error" });
			},
		}
	);

	const validateEmail = (email: string) => {
		return String(email)
			.toLowerCase()
			.match(
				/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
			);
	};

	const isQuestionVisible = (question: IQuestion): boolean => {
		if (!question.related_question) return true;

		const parentResponse = responses?.find(
			(res) => res.question?.id === question.related_question
		);
		const parentQuestion = questions.find(
			(q) => q.id === question.related_question
		);

		if (!parentQuestion) return false;

		if (!isQuestionVisible(parentQuestion)) return false;

		if (!parentResponse) return true;

		if (parentResponse?.question?.type === "SINGLE_CHOICE") {
			return (
				question.dependent_options?.includes(parentResponse.number_value) ??
				false
			);
		}

		if (parentResponse?.question?.type === "MULTIPLE_CHOICE") {
			return (
				question.dependent_options?.some((option) =>
					parentResponse.json_value?.includes(option)
				) ?? false
			);
		}

		return false;
	};

	useEffect(() => {
		if (formId) {
			getForm();
		}
	}, [formId, getForm]);

	useEffect(() => {
		if (form) {
			getQuestions();
		}
	}, [form, user?.company, getQuestions]);

	useEffect(() => {
		if (questions.length > 0 && !responses) {
			getUserResponses();
		}
	}, [questions, getUserResponses, responses]);

	useEffect(() => {
		if (user && form) {
			getUserForms();
		}
	}, [user, form, getUserForms]);

	useEffect(() => {
		if (userForms && userForms?.length === 0) {
			mutateUserForm();
		}
	}, [userForms, mutateUserForm]);

	const [open, setOpen] = useState(true);

	const [currentIndex, setCurrentIndex] = useState(-1);
	const [isAnimating] = useState(true);

	const visibleQuestions = useMemo(() => {
		return questions.filter((q) => isQuestionVisible(q));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [questions, responses]);

	useEffect(() => {
		if (currentIndex >= visibleQuestions.length) {
			//setCurrentIndex(visibleQuestions.length - 1);
		}
	}, [visibleQuestions, currentIndex]);

	const currentQuestion = visibleQuestions[currentIndex] || null;

	const handleResponseChange = (value: any) => {
		setResponses((prev) => {
			let auxResponses = [...prev!];
			auxResponses[currentIndex] = value;
			return auxResponses;
		});
	};

	const handleNext = () => {
		if (currentIndex === -1) {
			setCurrentIndex(0);
			return;
		}

		if (visibleQuestions[currentIndex]?.id === -1) {
			mutateRegister();
			return;
		} else if (visibleQuestions[currentIndex]?.id === -2) {
			mutateCompany();
			return;
		} else if (currentIndex < visibleQuestions.length) {
			mutateResponse({
				response: (responses ?? [])[currentIndex],
				questionId: visibleQuestions[currentIndex]?.id,
			});
			if (currentIndex === visibleQuestions.length - 1) {
				if (
					userForms &&
					userForms?.length > 0 &&
					!userForms[0].completion_date
				) {
					updateUserForm({ userFormId: userForms[0].id });
				}
				if (!user?.is_verified) {
					resendValidation();
				}
			}
		} else {
			handleClose();
		}
	};

	useEffect(() => {
		if (currentIndex > -1) {
			setResponses((responses) => {
				let auxResponses = responses ? [...responses] : null;
				if (!auxResponses) {
					auxResponses = Array<IResponse>();
				}

				if (
					currentIndex >= (auxResponses ?? []).length ||
					(currentIndex === 0 && (auxResponses ?? []).length === 0)
				) {
					auxResponses.push({
						id: -1 * new Date().getTime(),
						text_value: null,
						json_value: [],
						number_value: 0,
						date_value: null,
						file_value: null,
						question: visibleQuestions[currentIndex],
						user: user,
					});
				}

				return auxResponses;
			});
		}
	}, [currentIndex, visibleQuestions, user]);

	const handleClose = () => {
		setOpen(false);
		navigate("/");
	};

	const handleBack = async () => {
		if (currentIndex === 0) {
			setCurrentIndex(-1);
			return;
		}

		let prevIndex = currentIndex - 1;
		while (prevIndex >= 0 && !isQuestionVisible(visibleQuestions[prevIndex])) {
			prevIndex--;
		}

		if (prevIndex >= 0) {
			for (const response of responses ?? []) {
				const index = visibleQuestions.findIndex(
					(q) => response.question && q.id === response.question.id
				);
				if (response.id > -1) {
					if (index > prevIndex) {
						await deleteResponse({
							responseId: response.id,
							questionId: response!.question!.id,
						});
					}
				} else {
					const newResponses =
						responses?.filter((r) => r.id !== response.id) ?? [];
					setResponses(newResponses);
				}
			}
			setTimeout(() => {
				setCurrentIndex(prevIndex);
			}, 500);
		} else {
			setCurrentIndex(-1);
		}
	};

	const classes = useStyles();

	const isNextButtonDisabled = () => {
		let disabled = false;
		if (
			currentIndex < (responses ?? []).length &&
			visibleQuestions &&
			currentIndex < visibleQuestions.length &&
			currentIndex > -1
		) {
			switch (visibleQuestions[currentIndex].type) {
				case "TEXT":
					disabled =
						(visibleQuestions ?? [])[currentIndex].id === -1
							? !validateEmail(
									((responses ?? [])[currentIndex].text_value ?? "").trim()
							  )
							: ((responses ?? [])[currentIndex].text_value ?? "").trim()
									.length === 0;
					break;
				case "DATETIME":
					disabled =
						((responses ?? [])[currentIndex].date_value ?? "").trim().length ===
						0;
					break;
				case "MULTIPLE_CHOICE":
					disabled =
						((responses ?? [])[currentIndex].json_value ?? Array<number>())
							.length === 0;
					break;
				case "SINGLE_CHOICE":
				case "RATING":
					disabled =
						isNaN((responses ?? [])[currentIndex].number_value) === true;
					break;
				case "FILE":
					disabled = !(responses ?? [])[currentIndex].file_value;
					break;
			}
		}

		return disabled;
	};

	const getProgress = () => {
		if (currentIndex < 0) return 0;
		return Math.round(
			((currentIndex + 1) / (visibleQuestions.length + 1)) * 100
		);
	};

	const getEstimatedTime = () => {
		if (visibleQuestions.length === 0) return "";

		const timePerQuestion = 30; // Tiempo en segundos por pregunta
		const remainingQuestions = visibleQuestions.length - currentIndex; // Preguntas restantes
		const totalTimeInSeconds = remainingQuestions * timePerQuestion;

		const minutes = Math.floor(totalTimeInSeconds / 60); // Minutos enteros
		const seconds = totalTimeInSeconds % 60; // Segundos restantes

		if (minutes > 0) {
			return `Estimated Time: ${minutes} minutes${
				seconds > 0 ? ` ${seconds} seconds` : ""
			}`;
		} else {
			return `Estimated Time: ${seconds} seconds`;
		}
	};

	useEffect(() => {
		if (
			!user &&
			localStorage.getItem("ANONYMOUS_USER_EMAIL") &&
			localStorage.getItem("ANONYMOUS_USER_PW")
		) {
			mutateLogin({ email: localStorage.getItem("ANONYMOUS_USER_EMAIL")! });
		}
	}, [user, mutateLogin]);

	const isPasswordSimilarToEmail = (
		email: string,
		password: string
	): boolean => {
		if (!email || !password) return false;

		const emailParts = email.split("@");
		if (emailParts.length < 2) return false;

		const userPart = emailParts[0]!.toLowerCase();
		const domainPart = emailParts[1]!.split(".")[0]?.toLowerCase();

		const normalizedPassword = password.toLowerCase();

		return (
			normalizedPassword.includes(userPart) ||
			normalizedPassword.includes(domainPart)
		);
	};

	const resendValidation = () => {
		if (!resendDisabled) {
			if (validateEmail(user?.email ?? "")) {
				mutateStartReset();
				setResendDisabled(true);
				let seconds = 30;
				const interval = setInterval(() => {
					seconds -= 1;
					setCountdown(seconds);
					if (seconds <= 0) {
						setCountdown(30);
						clearInterval(interval);
						setResendDisabled(false);
					}
				}, 1000);
			} else {
				enqueueSnackbar(t("auth.invalidEmail"), {
					variant: "error",
				});
			}
		}
	};

	return (
		<Modal open={open} onClose={handleClose} sx={{ overflow: "hidden" }}>
			{!user &&
			localStorage.getItem("ANONYMOUS_USER_EMAIL") &&
			localStorage.getItem("ANONYMOUS_USER_PW") ? (
				<Box
					sx={{
						height: "100vh",
						width: "100vw",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "#FFFFFF",
						position: "relative",
					}}
				>
					<CircularProgress size={24} />
				</Box>
			) : (
				<Box
					sx={{
						height: "100vh",
						width: "100vw",
						display: "flex",
						flexDirection: "column",
						justifyContent: "space-between",
						backgroundColor: "#FFFFFF",
						position: "relative",
					}}
				>
					{/* Header */}
					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							alignItems: "center",
							width: "100%",
							padding: "16px 24px",
							borderBottom: "1px solid #ccc",
							position: "absolute",
							top: 0,
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
								alignItems: "center",
								flex: 1,
							}}
						>
							<Box sx={{ display: "flex", gap: 2 }}>
								<img src={Logo} alt="climeco" className={classes.logo} />
								<Typography variant="body2" sx={{ fontSize: 16 }}>
									<span style={{ fontWeight: "bold" }}>Supplier Portal </span>|{" "}
									{form?.title}
								</Typography>
							</Box>
							<IconButton onClick={handleClose}>
								<CloseIcon />
							</IconButton>
						</Box>
					</Box>
					<Box sx={{ width: "100%", position: "absolute", top: 73, zIndex: 9 }}>
						<LinearProgress variant="determinate" value={getProgress()} />
						<Typography
							variant="body2"
							sx={{
								textAlign: "left",
								marginTop: 1,
								color: "gray",
								fontSize: 12,
								marginLeft: 1,
							}}
						>
							{getEstimatedTime()}
						</Typography>
					</Box>
					<Box
						sx={{
							flexGrow: 1,
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							padding: 4,
							flexDirection: "column",
						}}
					>
						{currentIndex === -1 && (
							<Box
								sx={{
									backgroundColor: "white",
									borderRadius: 4,
									border: "1px solid #B1D1F0",
									width: "50%",
									textAlign: "center",
									padding: 4,
									maxWidth: 600,
								}}
							>
								<Typography variant="h5" sx={{ marginBottom: 2 }}>
									{form?.title}
								</Typography>
								<Typography variant="body1" sx={{ marginBottom: 4 }}>
									{form?.description}
								</Typography>
							</Box>
						)}

						{currentIndex >= 0 &&
							currentIndex < visibleQuestions.length &&
							currentQuestion && (
								<Box
									sx={{
										backgroundColor: "white",
										borderRadius: 4,
										border: "1px solid #B1D1F0",
										width: "50%",
										textAlign: "left",
										maxWidth: 600,
									}}
								>
									<Box sx={{ borderBottom: "1px solid #B1D1F0" }}>
										<Typography variant="h6" sx={{ padding: 2 }}>
											{`${
												currentQuestion.id === -1
													? 1
													: currentQuestion.id === -2
													? 2
													: localStorage.getItem(
															fromCreatedUserKey + formId
													  ) === formId
													? currentIndex + 3
													: currentIndex + 1
											}. ${currentQuestion?.title}`}
										</Typography>
									</Box>
									<Box sx={{ padding: 4 }}>
										{currentQuestion?.type === "TEXT" && (
											<TextField
												fullWidth
												placeholder={t("form.enterAnswer")}
												value={
													(responses ?? [])[currentIndex]?.text_value ?? ""
												}
												onChange={(e) => {
													let auxResponses = [...(responses ?? [])];
													auxResponses[currentIndex].text_value =
														e.target.value;

													handleResponseChange(auxResponses[currentIndex]);
												}}
												multiline
												rows={3}
											/>
										)}

										{/* Date Input */}
										{currentQuestion?.type === "DATETIME" && (
											<TextField
												fullWidth
												type="date"
												value={
													(responses ?? [])[currentIndex]?.date_value?.split(
														"T"
													)[0] ?? ""
												}
												onChange={(e) => {
													let auxResponses = [...(responses ?? [])];
													auxResponses[currentIndex].date_value =
														e.target.value;

													handleResponseChange(auxResponses[currentIndex]);
												}}
											/>
										)}

										{/* Rating Input */}
										{currentQuestion?.type === "RATING" && (
											<Rating
												name={`rating-${currentQuestion.id}`}
												value={
													(responses ?? [])[currentIndex]?.number_value ?? 0
												}
												onChange={(_, newValue) => {
													let auxResponses = [...(responses ?? [])];

													auxResponses[currentIndex].number_value =
														newValue || 0;

													handleResponseChange(auxResponses[currentIndex]);
												}}
											/>
										)}
										{/* Number Input */}
										{currentQuestion?.type === "NUMBER" && (
											<TextField
												type="number"
												fullWidth
												placeholder={t("form.enterNumber")}
												value={
													(responses ?? [])[currentIndex]?.number_value ?? ""
												}
												onChange={(e) => {
													let auxResponses = [...(responses ?? [])];
													auxResponses[currentIndex].number_value = parseFloat(
														e.target.value
													);
													handleResponseChange(auxResponses[currentIndex]);
												}}
											/>
										)}

										{/* Multiple Choice Input */}
										{(currentQuestion?.type === "MULTIPLE_CHOICE" ||
											currentQuestion?.type === "SINGLE_CHOICE") && (
											<FormControl>
												<FormLabel sx={{ marginBottom: 2 }}>
													{currentQuestion?.type === "MULTIPLE_CHOICE"
														? t("form.selectMultipleOptions")
														: t("form.selectSingleOption")}
												</FormLabel>
												{currentQuestion?.type === "MULTIPLE_CHOICE" ? (
													<>
														{currentQuestion.choices?.map((choice, i) => (
															<FormControlLabel
																key={i}
																control={
																	<Checkbox
																		checked={(() => {
																			return (
																				(responses ?? [])[currentIndex]
																					?.json_value ?? []
																			).find((v) => {
																				return v === choice.id;
																			})
																				? true
																				: false;
																		})()}
																		onChange={(e) => {
																			const auxResponses = responses ?? [];
																			if (e.target.checked) {
																				auxResponses[
																					currentIndex
																				].json_value?.push(choice.id);
																			} else {
																				auxResponses[currentIndex].json_value =
																					(
																						auxResponses[currentIndex]
																							.json_value ?? []
																					).filter((c) => c !== choice.id);
																			}

																			handleResponseChange(
																				auxResponses[currentIndex]
																			);
																		}}
																	/>
																}
																label={choice.text}
															/>
														))}
													</>
												) : (
													<RadioGroup
														value={
															(responses ?? [])[currentIndex]?.number_value ??
															-1
														}
														onChange={(e) => {
															const auxResponses = responses ?? [];
															auxResponses[currentIndex].number_value =
																parseInt(e.target.value);

															handleResponseChange(auxResponses[currentIndex]);
														}}
													>
														{currentQuestion.choices?.map((choice, i) => (
															<FormControlLabel
																key={i}
																value={choice.id}
																control={<Radio />}
																label={choice.text}
															/>
														))}
													</RadioGroup>
												)}
											</FormControl>
										)}

										{/* File Upload Input */}
										{currentQuestion.type === "FILE" && (
											<Box>
												<Button variant="outlined" component="label">
													{t("form.uploadFileButton")}
													<input
														type="file"
														hidden
														onChange={(e) => {
															if (e.target.files && e.target.files.length > 0) {
																const file = e.target.files[0];
																const auxResponses = responses ?? [];
																auxResponses[currentIndex].file_value = file;

																handleResponseChange(
																	auxResponses[currentIndex]
																);
															}
														}}
													/>
												</Button>
												{(responses ?? [])[currentIndex] && (
													<Box sx={{ marginTop: 2 }}>
														<Typography variant="body2" color="textPrimary">
															{t("form.uploadedFile")}:{" "}
															{(responses ?? [])[currentIndex]
																?.file_value instanceof File
																? (
																		(responses ?? [])[currentIndex]
																			?.file_value as File
																  ).name
																: (responses ?? [])[
																		currentIndex
																  ]?.file_value?.toString()}
														</Typography>
													</Box>
												)}
											</Box>
										)}
									</Box>
								</Box>
							)}

						{currentIndex === visibleQuestions.length &&
							(!user?.is_verified ? (
								<Box
									sx={{
										height: "100vh",
										display: "flex",
										justifyContent: "center",
										alignItems: "center",
									}}
								>
									<Box
										sx={{
											flexGrow: 1,
											display: "flex",
											justifyContent: "center",
											alignItems: "center",
											flexDirection: "column",
											maxWidth: 600,
											borderRadius: 4,
											border: "1px solid #B1D1F0",
										}}
									>
										<Box
											sx={{
												borderBottom: "1px solid #B1D1F0",
												flex: 1,
												width: "100%",
												textAlign: "center",
											}}
										>
											<Typography variant="h6" sx={{ padding: 2 }}>
												{t("auth.completeSignUp")}
											</Typography>
										</Box>
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
												padding: 4,
												textAlign: "center",
												justifyContent: "center",
											}}
										>
											<ReactCodeInput
												type="text"
												fields={6}
												name=""
												inputMode="numeric"
												value={token}
												onChange={(value) => setToken(value)}
											/>
											<Typography
												variant="body2"
												sx={{
													marginTop: 1,
													marginBottom: 2,
													cursor: resendDisabled ? "default" : "pointer",
													"&:hover": {
														textDecoration: resendDisabled
															? "none"
															: "underline",
													},
												}}
												onClick={() => {
													resendValidation();
												}}
											>
												{resendDisabled
													? t("auth.resendCodeMessage", { countdown })
													: t("auth.resendLink")}
											</Typography>

											<TextField
												inputRef={passwordInputRef}
												type={showPassword ? "text" : "password"}
												InputLabelProps={{ shrink: false }}
												fullWidth
												label=""
												id="password"
												placeholder={t("auth.passwordPlaceholder")}
												value={password}
												sx={{
													marginBottom: 2,
													"& .MuiFormHelperText-root": {
														color: passwordError ? "#eb4343" : "inherit",
													},
												}}
												onChange={(e) => {
													setPassword(e.target.value);
												}}
												helperText={t("auth.passwordCriteriaMessage")}
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<IconButton
																aria-label="toggle password visibility"
																onClick={handleClickShowPassword}
																onMouseDown={handleMouseDownPassword}
																edge="end"
															>
																{showPassword ? (
																	<VisibilityOff />
																) : (
																	<Visibility />
																)}
															</IconButton>
														</InputAdornment>
													),
													sx: {
														"& .MuiFormHelperText-root": {
															color: passwordError ? "#eb4343" : "inherit",
														},
													},
												}}
											/>
											<TextField
												inputRef={passwordInputRef}
												type={showPassword ? "text" : "password"}
												InputLabelProps={{ shrink: false }}
												fullWidth
												label=""
												id="repeatPassword"
												placeholder={t("auth.repeatPasswordPlaceholder")}
												sx={{
													marginBottom: 1,
												}}
												onChange={(e) => {
													setRepeatPassword(e.target.value);
												}}
												InputProps={{
													endAdornment: (
														<InputAdornment position="end">
															<IconButton
																aria-label="toggle password visibility"
																onClick={handleClickShowPassword}
																onMouseDown={handleMouseDownPassword}
																edge="end"
															>
																{showPassword ? (
																	<VisibilityOff />
																) : (
																	<Visibility />
																)}
															</IconButton>
														</InputAdornment>
													),
												}}
											/>
										</Box>
									</Box>
								</Box>
							) : (
								<Box
									sx={{
										backgroundColor: "white",
										borderRadius: 4,
										border: "1px solid #B1D1F0",
										width: "50%",
										textAlign: "center",
										padding: 4,
										maxWidth: 600,
									}}
								>
									<Typography variant="h5" sx={{ marginBottom: 2 }}>
										{t("form.finishedTitle") || "Well done!"}
									</Typography>
									<Typography variant="body1" sx={{ marginBottom: 4 }}>
										{t("form.finishedMessage") ||
											"You have completed the form!"}
									</Typography>
								</Box>
							))}
					</Box>

					<Box
						sx={{
							display: "flex",
							justifyContent: "space-between",
							position: "absolute",
							bottom: 0,
							left: 0,
							right: 0,
							padding: 2,
							background: "#FFFFFF",
							borderTop: "1px solid #EAEBEB",
							fontSize: "16px",
						}}
					>
						<Button
							variant="outlined"
							onClick={handleBack}
							disabled={currentIndex === -1}
							sx={{
								position: "relative",
								color: currentIndex === -1 ? "white" : "black",
								fontSize: "16px",
								paddingLeft: "26px",
								paddingRight: "26px",
							}}
						>
							{isLoadingDelete ? (
								<CircularProgress size={12} />
							) : (
								t("form.back")
							)}
						</Button>

						{currentIndex < visibleQuestions.length - 1 ? (
							<Button
								variant="contained"
								onClick={handleNext}
								sx={{
									position: "relative",
									color: "white",
									fontSize: "16px",
									paddingLeft: "26px",
									paddingRight: "26px",
									animation: isAnimating ? `${pulse} 0.5s ease-in-out` : "none",
									animationIterationCount: 3,
								}}
								disabled={isNextButtonDisabled()}
							>
								{isLoadingMutate ||
								isLoadingCompany ||
								isLoadingRegister ||
								isLoadingLogin ? (
									<CircularProgress size={12} sx={{ color: "white" }} />
								) : (
									t("form.next")
								)}
							</Button>
						) : currentIndex === visibleQuestions.length ? (
							<Button
								type="submit"
								variant="contained"
								color="primary"
								onClick={() => {
									if (!user?.is_verified) {
										if (isPasswordSimilarToEmail(user?.email ?? "", password)) {
											enqueueSnackbar(t("auth.passwordSimilarToEmail"), {
												variant: "error",
											});
										} else if (validatePasswords(password)) {
											mutateReset();
										} else {
											setPasswordError(true);
											let errorMessage = t("auth.passwordErrorMessage");
											if (password !== repeatPassword) {
												errorMessage = t("auth.passwordMismatchMessage");
											}
											enqueueSnackbar(errorMessage, {
												variant: "error",
											});
										}
									} else {
										setOpen(false);
										navigate("/");
									}
								}}
								sx={{
									position: "relative",
									color: "white",
									fontSize: "16px",
									paddingLeft: "26px",
									paddingRight: "26px",
									animation: isAnimating ? `${pulse} 0.5s ease-in-out` : "none",
									animationIterationCount: 3,
								}}
								disabled={
									!user?.is_verified &&
									(isLoadingReset ||
										!token ||
										token.length < 6 ||
										!password ||
										!repeatPassword)
								}
							>
								{isLoadingMutate ? (
									<CircularProgress size={12} sx={{ color: "white" }} />
								) : !user?.is_verified ? (
									t("form.finish")
								) : (
									t("form.closeFormButton")
								)}
							</Button>
						) : (
							<Button
								type="submit"
								variant="contained"
								color="primary"
								onClick={handleNext}
								sx={{
									position: "relative",
									color: "white",
									fontSize: "16px",
									paddingLeft: "26px",
									paddingRight: "26px",
									animation: isAnimating ? `${pulse} 0.5s ease-in-out` : "none",
									animationIterationCount: 3,
								}}
							>
								{isLoadingMutate ? (
									<CircularProgress size={12} sx={{ color: "white" }} />
								) : (
									t("form.submit")
								)}
							</Button>
						)}
					</Box>
				</Box>
			)}
		</Modal>
	);
};
