import { connectionsClient } from "../../utils/ConnectionsClient"
import { AdminAIType } from "./adminAI.types";

class AdminAI {
    async create(query: AdminAIType.Create): Promise<{ ai_response?: string, error?: string, query?: string }> {
        const endpoint = "/admin/ai-query/"
        const response = await connectionsClient.client.post(endpoint, { query: query }, {
            //withCredentials: true
        });
        return response.data;
    }
}

export const adminAI = new AdminAI();