import { ISubmittedSale } from "../../models";
import { connectionsClient } from "../../utils/ConnectionsClient"

class SubmittedSale {
    async list(companyId?: number, search?: string, quarter?: string, year?: string, companies?: Array<number>, page?: number, sort: string = "-updated_at", pageSize: number = 10, signal?: AbortSignal): Promise<{ count: number, results: Array<ISubmittedSale> }> {
        const endpoint = companyId ? "companies/" + companyId + "/submitted-sales" : "submitted-sales"
        const params = new URLSearchParams();
        params.append("page", page?.toString() ?? "1");
        params.append("page_size", pageSize.toString())
        params.append("search", search ?? "");
        params.append("ordering", sort);
        if (quarter && ["q1", "q2", "q3", "q4"].includes(quarter) && year) {
            if (quarter === "q1") {
                params.append("submission_date__lte", `${year}-03-31`);
                params.append("submission_date__gte", `${year}-01-01`);
            } else if (quarter === "q2") {
                params.append("submission_date__lte", `${year}-06-30`);
                params.append("submission_date__gte", `${year}-04-01`);
            } else if (quarter === "q3") {
                params.append("submission_date__lte", `${year}-09-30`);
                params.append("submission_date__gte", `${year}-07-01`);
            } else if (quarter === "q4") {
                params.append("submission_date__lte", `${year}-12-31`);
                params.append("submission_date__gte", `${year}-10-01`);
            }
        }
        if (companies && companies.length > 0) {
            params.append("product__company__in", companies.join(","));
        }
        const showTestData = localStorage.getItem("showTestData");
        if (!showTestData) {
            params.append("product__company__is_test_company", "false");
        }
        const response = await connectionsClient.client.get(endpoint, {
            params,
            signal
        });
        return response.data;
    }

    async create(companyId: number): Promise<ISubmittedSale> {
        const endpoint = companyId ? "companies/" + companyId + "/submitted-sales/" : "submitted-sales/"
        const response = await connectionsClient.client.post(endpoint, {});
        return response.data;
    }
}

export const submittedSale = new SubmittedSale();