import React, { useContext, useEffect, useState } from "react";
import {
	Box,
	Typography,
	Button,
	IconButton,
	TextField,
	Divider,
	Switch,
	FormControlLabel,
	Chip,
	Select,
	Checkbox,
	ListItemText,
	MenuItem,
	Autocomplete,
	CircularProgress,
} from "@mui/material";
import {
	MdAddCircle,
	MdDelete,
	MdArrowUpward,
	MdArrowDownward,
} from "react-icons/md";
import { makeStyles } from "@mui/styles";
import { useTranslation } from "react-i18next";
import { Heading } from "../Heading";
import { useMutation, useQuery } from "react-query";
import { UserContext } from "../../contexts/user";
import { ICompany, IForm } from "../../models";
import { useNavigate, useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { form as formActions } from "../../controllers";
import { company as companyActions } from "../../controllers";

type QuestionType =
	| "multiple_choice"
	| "single_choice"
	| "text"
	| "datetime"
	| "rating"
	| "number"
	| "file";

interface Question {
	id: number;
	type: QuestionType;
	questionText: string;
	allowMultiple?: boolean;
	choices?: { id: number; text: string }[];
	related_question?: number | null;
	dependent_options?: number[];
	is_featured: boolean;
	aggregation_type: "PERCENTAGE" | "VALUE";
	units?: string;
}

const useStyles = makeStyles({
	inputLabel: {
		textTransform: "uppercase",
		fontSize: 10,
		marginTop: 20,
		marginBottom: 2,
		fontWeight: 500,
		color: "#686868",
		display: "flex",
	},
	questionBox: {
		border: "1px solid #ccc",
		padding: 20,
		borderRadius: 8,
		marginBottom: 24,
		backgroundColor: "#f9f9f9",
		position: "relative",
	},
	chip: {
		position: "absolute",
		top: 10,
		right: 10,
		fontSize: 10,
		textTransform: "capitalize",
	},
	questionTextInput: {
		marginBottom: 16,
	},
	sectionTitle: {
		textTransform: "uppercase",
		fontSize: 12,
		marginTop: 32,
		marginBottom: 12,
		fontWeight: 600,
		color: "#686868",
	},
	branchingBox: {
		marginTop: 16,
		padding: 16,
		border: "1px solid #ccc",
		borderRadius: 8,
		backgroundColor: "#f9f9f9",
	},
});

export const FormDetail: React.FC = () => {
	const [questions, setQuestions] = useState<Question[]>([]);
	const classes = useStyles();
	const { t } = useTranslation();
	const { user } = useContext(UserContext);
	const [form, setForm] = useState<IForm | null>();
	const navigate = useNavigate();

	const { id: paramsId } = useParams();
	const id = paramsId ? parseInt(paramsId) : 0;

	const [companies, setCompanies] = useState<ICompany[]>([]);
	const [selectedCompany, setSelectedCompany] = useState<ICompany | null>(null);

	const handleAddQuestion = (type: QuestionType) => {
		const newQuestion: Question = {
			id: -Date.now(),
			type,
			questionText: "",
			choices:
				type === "multiple_choice" || type === "single_choice"
					? [{ id: 1, text: "" }]
					: undefined,
			related_question: null,
			dependent_options: [],
			is_featured: false,
			aggregation_type: "VALUE",
			units: undefined,
		};
		setQuestions((prev) => [...prev, newQuestion]);
	};

	const handleQuestionChange = (id: number, key: string, value: any) => {
		setQuestions((prev) =>
			prev.map((q) => (q.id === id ? { ...q, [key]: value } : q))
		);
	};

	const handleAddChoice = (id: number) => {
		setQuestions((prev) =>
			prev.map((q) =>
				q.id === id
					? {
							...q,
							choices: q.choices
								? [...q.choices, { id: new Date().getTime(), text: "" }]
								: undefined,
					  }
					: q
			)
		);
	};

	const handleChoiceChange = (
		questionId: number,
		choiceIndex: number,
		value: string
	) => {
		setQuestions((prev) =>
			prev.map((q) =>
				q.id === questionId && q.choices
					? {
							...q,
							choices: q.choices.map((c, i) =>
								i === choiceIndex ? { ...c, text: value } : c
							),
					  }
					: q
			)
		);
	};

	const handleDeleteChoice = (questionId: number, choiceIndex: number) => {
		setQuestions((prev) =>
			prev.map((q) =>
				q.id === questionId && q.choices
					? {
							...q,
							choices: q.choices.filter((_, i) => i !== choiceIndex),
					  }
					: q
			)
		);
	};

	const handleDeleteQuestion = (id: number) => {
		setQuestions((prev) => prev.filter((q) => q.id !== id));
	};

	const handleMoveQuestion = (index: number, direction: "up" | "down") => {
		setQuestions((prev) => {
			const newQuestions = [...prev];
			const [movedQuestion] = newQuestions.splice(index, 1);
			const newIndex = direction === "up" ? index - 1 : index + 1;
			newQuestions.splice(newIndex, 0, movedQuestion);

			if (newIndex === 0) {
				movedQuestion.related_question = null;
				movedQuestion.dependent_options = [];
			}

			return newQuestions;
		});
	};

	const getChipColor = (type: QuestionType) => {
		switch (type) {
			case "multiple_choice":
				return "primary";
			case "single_choice":
				return "error";
			case "text":
				return "secondary";
			case "datetime":
				return "info";
			case "rating":
				return "warning";
			case "file":
				return "success";
			case "number":
				return "default";
			default:
				return "default";
		}
	};

	const { refetch } = useQuery(
		"forms",
		() => formActions.getOne(id!, undefined),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setForm(res);
				setSelectedCompany(res.company);
			},
			onError: (error: any) => {
				if (error.response.status === 404) {
					navigate("/404", { replace: true });
				} else {
					enqueueSnackbar(t("productDetail.somethingWrong"), {
						variant: "error",
					});
				}
			},
		}
	);

	const saveQuestionsWithRelations = async (formId: number) => {
		const savedQuestions: Record<number, number> = {};

		for (const question of questions) {
			if (question.id > 0) {
				const payload = {
					title: question.questionText,
					type: question.type.toUpperCase(),
					choices: question.choices ?? [],
					order: questions.findIndex((q) => q.id === question.id) + 1,
					related_question: savedQuestions[question.related_question || -1],
					dependent_options: question.dependent_options ?? [],
					is_featured: question.is_featured ?? false,
					aggregation_type: question.aggregation_type ?? "VALUE",
					units: question.units ?? undefined,
				};

				await formActions.updateQuestion(
					question.id,
					selectedCompany?.id ?? -1,
					formId,
					payload
				);
				savedQuestions[question.id] = question.id;
			} else {
				const payload = {
					title: question.questionText,
					type: question.type.toUpperCase(),
					choices: question.choices ?? [],
					order: questions.findIndex((q) => q.id === question.id) + 1,
					is_featured: question.is_featured ?? false,
					aggregation_type: question.aggregation_type ?? "VALUE",
					units: question.units ?? undefined,
				};

				const response = await formActions.addQuestion(
					selectedCompany?.id ?? -1,
					formId,
					payload
				);
				savedQuestions[question.id] = response.id;
			}
		}

		for (const question of questions) {
			if (question.related_question || question.dependent_options?.length) {
				const payload = {
					title: question.questionText,
					type: question.type.toUpperCase(),
					choices: question.choices ?? [],
					order: questions.findIndex((q) => q.id === question.id) + 1,
					related_question: savedQuestions[question.related_question || -1],
					dependent_options: question.dependent_options ?? [],
					is_featured: question.is_featured ?? false,
					aggregation_type: question.aggregation_type ?? "VALUE",
					units: question.units ?? undefined,
				};

				await formActions.updateQuestion(
					savedQuestions[question.id],
					selectedCompany?.id ?? -1,
					formId,
					payload
				);
			}
		}
	};

	const { mutate } = useMutation(
		async () => {
			if (!selectedCompany?.id) throw new Error(t("form.errorNoCompany"));

			const formData = id
				? await formActions.update(
						id,
						form?.title || "",
						form?.description || "",
						form?.expected_respondents || 0,
						form?.status ?? "DRAFT",
						selectedCompany?.id
				  )
				: await formActions.create(
						form?.title || "",
						form?.description || "",
						form?.expected_respondents || 0,
						form?.status ?? "DRAFT",
						selectedCompany?.id
				  );

			setForm({ ...form!, id: formData.id });

			await saveQuestionsWithRelations(formData.id);

			enqueueSnackbar(t("forms.success"), { variant: "success" });

			navigate("/forms");
		},
		{
			retry: false,
			onError: (error: any) => {
				if (error) {
					enqueueSnackbar(t("productDetail.somethingWrong"), {
						variant: "error",
					});
				}
			},
		}
	);

	const { refetch: refetchQuestions } = useQuery(
		["questions", id],
		async () => {
			if (!user?.company?.id || !id) return [];
			return await formActions.getQuestions(selectedCompany?.id ?? -1, id);
		},
		{
			enabled: false,
			onSuccess: (data) => {
				setQuestions(
					data.map((q) => ({
						id: q.id,
						type: q.type.toLowerCase() as QuestionType,
						questionText: q.title,
						choices: q.choices || undefined,
						related_question: q.related_question || null,
						dependent_options: q.dependent_options || [],
						is_featured: q.is_featured ?? false,
						aggregation_type: q.aggregation_type ?? "VALUE",
						units: q.units ?? undefined,
					}))
				);
			},
			onError: (error) => {
				console.error(error);
				enqueueSnackbar(t("form.errorFetchingQuestions"), {
					variant: "error",
				});
			},
		}
	);
	const handleBranchingChange = (
		id: number,
		key: "related_question" | "dependent_options",
		value: any
	) => {
		setQuestions((prev) =>
			prev.map((q) => (q.id === id ? { ...q, [key]: value } : q))
		);
	};

	const { refetch: refetchCompanies, isLoading: loadingCompanies } = useQuery(
		["companies"],
		async () => {
			if (!user?.is_superuser) return [];
			const response = await companyActions.list(
				"",
				1,
				"-updated_at",
				999,
				undefined,
				true,
				true
			);
			return response.results;
		},
		{
			enabled: false,
			onSuccess: (data) => {
				setCompanies(data);
			},
			onError: (error) => {
				console.error("Error fetching companies:", error);
				enqueueSnackbar(t("form.errorFetchingCompanies"), { variant: "error" });
			},
		}
	);

	useEffect(() => {
		if (user?.is_superuser) {
			refetchCompanies();
		}
	}, [refetchCompanies, user]);

	useEffect(() => {
		if (id) {
			refetch();
		}
	}, [id, refetch]);

	useEffect(() => {
		if (id && selectedCompany) {
			refetchQuestions();
		}
	}, [id, refetchQuestions, selectedCompany]);

	return (
		<Box p={0}>
			<Heading title={t("form.createForm")} showSearch={false} noMarginBottom />
			<span className={classes.inputLabel}>{t("form.title")}</span>
			<TextField
				InputLabelProps={{ shrink: false }}
				fullWidth
				label=""
				id="title"
				placeholder={t("form.title")}
				value={form?.title}
				onChange={(e) => {
					setForm(
						(prevState) =>
							({
								...prevState,
								title: e.target.value,
							} as IForm)
					);
				}}
			/>
			<span className={classes.inputLabel}>{t("form.description")}</span>
			<TextField
				InputLabelProps={{ shrink: false }}
				fullWidth
				label=""
				id="description"
				placeholder={t("form.description")}
				value={form?.description}
				onChange={(e) => {
					setForm(
						(prevState) =>
							({
								...prevState,
								description: e.target.value,
							} as IForm)
					);
				}}
			/>
			<span className={classes.inputLabel}>
				{t("form.expected_respondents")}
			</span>
			<TextField
				type="number"
				InputLabelProps={{ shrink: false }}
				fullWidth
				label=""
				id="expected_respondents"
				placeholder={t("form.expected_respondents")}
				value={form?.expected_respondents}
				onChange={(e) => {
					setForm(
						(prevState) =>
							({
								...prevState,
								expected_respondents: e.target.value
									? parseInt(e.target.value, 10)
									: 0,
							} as IForm)
					);
				}}
			/>

			<FormControlLabel
				control={
					<Switch
						checked={form?.status === "ACTIVE" ? true : false}
						onChange={(e) => {
							setForm(
								(prevState) =>
									({
										...prevState,
										status: e.target.checked ? "ACTIVE" : "DRAFT",
									} as IForm)
							);
						}}
						color="primary"
					/>
				}
				label={form?.status === "ACTIVE" ? t("form.active") : t("form.draft")}
				sx={{
					".MuiFormControlLabel-label": {
						fontSize: "14px",
					},
					display: "flex",
					alignItems: "center",
					marginTop: 2,
				}}
			/>

			<Box mb={3}>
				<span className={classes.inputLabel}>{t("forms.company")}</span>
				<Autocomplete
					options={companies}
					getOptionLabel={(option) => option.name || ""}
					value={selectedCompany}
					onChange={(event, newValue) => setSelectedCompany(newValue)}
					sx={{ minHeight: 50 }}
					renderInput={(params) => (
						<TextField
							{...params}
							placeholder={t("forms.searchCompany")}
							InputProps={{
								...params.InputProps,
								endAdornment: (
									<>
										{loadingCompanies ? (
											<CircularProgress color="inherit" size={20} />
										) : null}
										{params.InputProps.endAdornment}
									</>
								),
							}}
						/>
					)}
				/>
			</Box>

			<Divider sx={{ marginY: 2 }} />
			<Typography className={classes.sectionTitle}>
				{t("form.questionsSectionTitle")}
			</Typography>
			<Box mb={2}>
				<Button
					variant="contained"
					color="primary"
					startIcon={<MdAddCircle />}
					onClick={() => handleAddQuestion("multiple_choice")}
				>
					{t("form.addMultipleChoice")}
				</Button>
				<Button
					variant="contained"
					color="error"
					startIcon={<MdAddCircle />}
					onClick={() => handleAddQuestion("single_choice")}
					style={{ marginLeft: 8 }}
				>
					{t("form.addSingleChoice")}
				</Button>
				<Button
					variant="contained"
					color="secondary"
					startIcon={<MdAddCircle />}
					onClick={() => handleAddQuestion("text")}
					style={{ marginLeft: 8 }}
				>
					{t("form.addTextField")}
				</Button>
				<Button
					variant="contained"
					color="info"
					startIcon={<MdAddCircle />}
					onClick={() => handleAddQuestion("datetime")}
					style={{ marginLeft: 8 }}
				>
					{t("form.addDate")}
				</Button>
				<Button
					variant="contained"
					color="warning"
					startIcon={<MdAddCircle />}
					onClick={() => handleAddQuestion("rating")}
					style={{ marginLeft: 8 }}
				>
					{t("form.addRating")}
				</Button>
				<Button
					variant="contained"
					startIcon={<MdAddCircle />}
					onClick={() => handleAddQuestion("number")}
					style={{ marginLeft: 8, backgroundColor: "#008080", color: "white" }}
				>
					{t("form.addNumberField")}
				</Button>
				<Button
					variant="contained"
					color="success"
					startIcon={<MdAddCircle />}
					onClick={() => handleAddQuestion("file")}
					style={{ marginLeft: 8 }}
				>
					{t("form.addFile")}
				</Button>
			</Box>
			{questions.map((question, index) => (
				<Box key={question.id} className={classes.questionBox}>
					<Chip
						label={t(`form.type.${question.type}`)}
						className={classes.chip}
						color={getChipColor(question.type)}
					/>
					<span className={classes.inputLabel}>
						{t("form.questionNumber", { index: index + 1 })}
					</span>
					<TextField
						fullWidth
						placeholder={t("form.enterQuestionText")}
						variant="outlined"
						value={question.questionText}
						onChange={(e) =>
							handleQuestionChange(question.id, "questionText", e.target.value)
						}
						className={classes.questionTextInput}
					/>
					{["date", "rating", "file", "number"].includes(question.type) && (
						<Typography variant="caption" color="textSecondary">
							{t(`form.requiredInput.${question.type}`)}
						</Typography>
					)}

					{question.type === "number" && (
						<>
							<span className={classes.inputLabel}>{t("form.units")}</span>
							<TextField
								fullWidth
								placeholder={t("form.enterQuestionText")}
								variant="outlined"
								value={question.units}
								onChange={(e) =>
									handleQuestionChange(question.id, "units", e.target.value)
								}
								className={classes.questionTextInput}
							/>
						</>
					)}
					{(question.type === "multiple_choice" ||
						question.type === "single_choice" ||
						question.type === "number") && (
						<>
							<FormControlLabel
								control={
									<Switch
										checked={question?.is_featured}
										onChange={(e) => {
											handleQuestionChange(
												question.id,
												"is_featured",
												e.target.checked
											);
										}}
										color="primary"
									/>
								}
								label={t("forms.isFeatured")}
								sx={{
									".MuiFormControlLabel-label": {
										fontSize: "14px",
									},
									display: "flex",
									alignItems: "center",
									marginTop: 2,
								}}
							/>
							{question.is_featured && (
								<Box
									sx={{
										position: "relative",
										width: "100%",
										marginBottom: 2,
										"& .MuiSelect-outlined": {
											borderRadius: "8px",
											borderColor: "#ccc",
											backgroundColor: "#fff",
											border: "1px solid #C9C9C9",
											width: "100%",
											padding: "12px",
										},
										"& .MuiOutlinedInput-root": {
											padding: "1px",
											width: "100%",
										},
									}}
								>
									<Typography className={classes.inputLabel}>
										{t("forms.aggregationType")}
									</Typography>
									<Select
										value={question.aggregation_type}
										onChange={(e) =>
											handleQuestionChange(
												question.id,
												"aggregation_type",
												e.target.value
											)
										}
										fullWidth
									>
										<MenuItem value="VALUE">
											{t("forms.aggregationTypeValue")}
										</MenuItem>
										<MenuItem value="PERCENTAGE">
											{t("forms.aggregationTypePercentage")}
										</MenuItem>
									</Select>
								</Box>
							)}
						</>
					)}

					{(question.type === "multiple_choice" ||
						question.type === "single_choice") && (
						<Box>
							<Typography className={classes.inputLabel}>
								{t("form.choices")}
							</Typography>
							{question.choices?.map((choice, i) => (
								<Box key={i} display="flex" alignItems="center" mb={1}>
									<TextField
										fullWidth
										placeholder={t("form.enterChoice", { index: i + 1 })}
										value={choice.text}
										onChange={(e) =>
											handleChoiceChange(question.id, i, e.target.value)
										}
										variant="outlined"
									/>
									<IconButton
										aria-label="delete choice"
										color="error"
										onClick={() => handleDeleteChoice(question.id, i)}
									>
										<MdDelete />
									</IconButton>
								</Box>
							))}
							<Button
								onClick={() => handleAddChoice(question.id)}
								color="primary"
							>
								{t("form.addChoice")}
							</Button>
						</Box>
					)}
					{/* Branching: Related Questions Selector */}
					{index !== 0 && (
						<Box
							sx={{
								position: "relative",
								width: "100%",
								marginBottom: 2,
								"& .MuiSelect-outlined": {
									borderRadius: "8px",
									borderColor: "#ccc",
									backgroundColor: "#fff",
									border: "1px solid #C9C9C9",
									width: "100%",
									padding: "12px",
								},
								"& .MuiOutlinedInput-root": {
									padding: "1px",
									width: "100%",
								},
							}}
						>
							<Typography className={classes.inputLabel}>
								Related Question
							</Typography>
							<Select
								value={question.related_question || ""}
								onChange={(e) =>
									handleBranchingChange(
										question.id,
										"related_question",
										Number(e.target.value)
									)
								}
								label={t("form.relatedQuestion")}
							>
								{questions
									.filter(
										(q) =>
											q.id !== question.id &&
											["multiple_choice", "single_choice"].includes(q.type)
									)
									.map((q) => (
										<MenuItem key={q.id} value={q.id}>
											{q.questionText}
										</MenuItem>
									))}
							</Select>
						</Box>
					)}
					{/* Branching: Dependent Options Selector */}
					{question.related_question && (
						<Box
							sx={{
								position: "relative",
								width: "100%",
								marginBottom: 2,
								"& .MuiSelect-outlined": {
									borderRadius: "8px",
									borderColor: "#ccc",
									backgroundColor: "#fff",
									border: "1px solid #C9C9C9",
									width: "100%",
									padding: "12px",
								},
								"& .MuiOutlinedInput-root": {
									padding: "1px",
									width: "100%",
								},
							}}
						>
							<Typography className={classes.inputLabel}>
								Dependent Options
							</Typography>
							<Select
								multiple
								value={question.dependent_options || []}
								onChange={(e) =>
									handleBranchingChange(
										question.id,
										"dependent_options",
										e.target.value as number[]
									)
								}
								renderValue={(selected) =>
									(selected as number[])
										.map(
											(optionId) =>
												questions
													.find((q) => q.id === question.related_question)
													?.choices?.find((c) => c.id === optionId)?.text
										)
										.filter((text) => text)
										.join(", ")
								}
							>
								{questions
									.find((q) => q.id === question.related_question)
									?.choices?.map((choice) => (
										<MenuItem key={choice.id} value={choice.id}>
											<Checkbox
												checked={
													question.dependent_options?.includes(choice.id) ||
													false
												}
											/>
											<ListItemText primary={choice.text} />
										</MenuItem>
									))}
							</Select>
						</Box>
					)}
					<Box display="flex" justifyContent="space-between" mt={2}>
						<Box>
							<IconButton
								disabled={index === 0}
								onClick={() => handleMoveQuestion(index, "up")}
							>
								<MdArrowUpward />
							</IconButton>
							<IconButton
								disabled={index === questions.length - 1}
								onClick={() => handleMoveQuestion(index, "down")}
							>
								<MdArrowDownward />
							</IconButton>
						</Box>
						<IconButton
							aria-label="delete"
							color="error"
							onClick={() => handleDeleteQuestion(question.id)}
						>
							<MdDelete />
						</IconButton>
					</Box>
				</Box>
			))}
			<Divider sx={{ marginY: 2 }} />
			<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
				<Button
					onClick={() => {
						mutate();
					}}
					color="primary"
					variant="contained"
				>
					{id ? t("form.save") : t("form.createForm")}
				</Button>
			</Box>
		</Box>
	);
};
