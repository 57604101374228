import { useEffect, useState } from "react";
import { PropTypes } from "./PurchaseOffsets.types";
import {
	Alert,
	Box,
	Button,
	CircularProgress,
	Typography,
} from "@mui/material";
import { useQuery } from "react-query";
import { offset as offsetActions } from "../../controllers";
import { useSnackbar } from "notistack";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

export function PurchaseOffsets({
	quantity,
	productsCount,
	needsFirstTimeFee,
	certificationProcessId,
	height,
	inModal,
	callback,
	offsetsLoadingMessages,
	messagesIndex,
	setLoadingOffsetsResult,
	closeModal,
	lcaIds,
	lcaVersionIds,
	isForForecasted2025Sales,
	existingIframeSrc,
}: PropTypes) {
	const [iframeSrc, setIframeSrc] = useState<string | null>(
		existingIframeSrc ?? null
	);
	const [error, setError] = useState<string | null>(null);
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();

	useEffect(() => {
		if (existingIframeSrc) {
			setIframeSrc(existingIframeSrc);
		}
	}, [existingIframeSrc]);

	useEffect(() => {
		enum PurchaseOffsetCartEvent {
			PurchaseCompleted = "PURCHASE_COMPLETED",
			PurchaseCanceled = "PURCHASE_CANCELED",
		}

		const parseEvents = (event: MessageEvent) => {
			const trustedDomains = [
				"https://climecostage.wpengine.com",
				"https://climecogreen.com",
				"https://www.climecostage.wpengine.com",
				"https://www.climecogreen.com",
				"https://staging.climecogreen.com",
				"https://www.staging.climecogreen.com",
			];

			if (
				trustedDomains.find((trustedDomain) => trustedDomain === event.origin)
			) {
				try {
					let parsedMessage = JSON.parse(event.data);
					if (
						parsedMessage.event === PurchaseOffsetCartEvent.PurchaseCompleted
					) {
						// Respond to the event of purchasing being completed
						if (callback) {
							callback();
							if (inModal && closeModal) {
								setTimeout(() => {
									try {
										closeModal();
									} catch (error) {
										console.log(error);
									}
								}, 5000);
							}
						}
					} else if (
						parsedMessage.event === PurchaseOffsetCartEvent.PurchaseCanceled
					) {
						// Respond to the event of purchasing being canceled
					}
				} catch (error) {
					console.log(error);
				}
			}
		};
		window.removeEventListener("message", parseEvents);

		window.addEventListener("message", parseEvents);

		return () => {
			window.removeEventListener("message", parseEvents);
		};
	}, [callback, closeModal, inModal]);

	useQuery(
		"offsets-order",
		() =>
			offsetActions.create({
				quantity: Math.ceil(quantity),
				productsCount,
				firstTimeFeePaid: needsFirstTimeFee,
				certificationProcessId: certificationProcessId,
				lcaIds: lcaIds,
				lcaVersionIds: lcaVersionIds,
				successURL: window.location.href + "?success=true",
				cancelURL: window.location.href + "?error=true",
				isForForecasted2025Sales: isForForecasted2025Sales,
			}),
		{
			enabled: !existingIframeSrc,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				if (res.url) {
					setIframeSrc(res.url);
					if (callback) {
						callback();
					}
				} else if (res.code === "insufficientStock") {
					enqueueSnackbar(t("purchaseOffsets.insufficientStock"), {
						variant: "error",
					});
					setError(t("purchaseOffsets.insufficientStockMessage"));
				} else {
					enqueueSnackbar(t("general.errorMessage"), {
						variant: "error",
					});
					setError(t("general.errorMessage"));
				}
				if (setLoadingOffsetsResult) {
					setLoadingOffsetsResult(false);
				}
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
				setError(t("general.errorMessage"));
				if (setLoadingOffsetsResult) {
					setLoadingOffsetsResult(false);
				}
			},
		}
	);

	return (
		<Box>
			{iframeSrc ? (
				<Box
					sx={{
						display: "flex",
						width: "100%",
						minHeight: height ?? 700,
						alignItems: "center",
						justifyContent: "flex-start",
						flexDirection: "column",
						position: "relative",
						padding: 2,
					}}
				>
					<Typography
						sx={{ textAlign: "center", marginBottom: 2, marginTop: 4 }}
						variant="body1"
					>
						{certificationProcessId
							? t("purchaseOffsets.invoiceMessageCertification")
							: t("purchaseOffsets.invoiceMessage")}
					</Typography>
					<Button
						sx={{ width: "auto" }}
						variant="contained"
						onClick={() => {
							window.open(iframeSrc, "_blank");
						}}
					>
						{t("purchaseOffsets.seeInvoiceButton")}
					</Button>
				</Box>
			) : (
				<Box
					sx={{
						display: "flex",
						alignContent: "center",
						justifyContent: "center",
						padding: 4,
					}}
				>
					{error ? (
						<Alert severity="error">
							{t("purchaseOffsets.contactSupport")}
						</Alert>
					) : (
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								padding: 4,
								flexDirection: "column",
							}}
						>
							<CircularProgress size={24} />
							{offsetsLoadingMessages && messagesIndex !== null && (
								<motion.div
									key={messagesIndex}
									initial={{ opacity: 0, x: -100 }}
									animate={{ opacity: 1, x: 0 }}
									exit={{ opacity: 0, x: 100 }}
								>
									<Typography
										variant="body2"
										sx={{
											marginTop: 2,
											maxWidth: 400,
											textAlign: "center",
										}}
									>
										{offsetsLoadingMessages[messagesIndex!]}
									</Typography>
								</motion.div>
							)}
						</Box>
					)}
				</Box>
			)}
		</Box>
	);
}
