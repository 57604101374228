import { useState, useEffect, useContext } from "react";
import { PropTypes } from "./SaleActionBar.types";
import { Autocomplete, Box, CircularProgress, TextField } from "@mui/material";
import { ICompany } from "../../models";
import { company as companyActions } from "../../controllers";
import { useQuery } from "react-query";
import { enqueueSnackbar } from "notistack";
import { UserContext } from "../../contexts/user";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";

export function SaleActionBar({
	selectedRows,
	refetch,
	filters,
	setFilters,
	setPaginationModel,
}: PropTypes) {
	const { user } = useContext(UserContext);
	const [searchParams] = useSearchParams();
	const [selectedCompanies, setSelectedCompanies] = useState<Array<ICompany>>(
		[]
	);
	const [companies, setCompanies] = useState<Array<ICompany>>([]);
	const [companiesSearch, setCompaniesSearch] = useState("");
	const { t } = useTranslation();

	const handleSearchChange = (value: string) => {
		setCompaniesSearch(value);
	};

	const { refetch: refetchCompanies, isLoading: isLoadingCompanies } = useQuery(
		"companies",
		() => companyActions.list(companiesSearch, undefined, "name", 999),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setCompanies(res.results);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	useEffect(() => {
		refetchCompanies();
	}, [refetchCompanies, companiesSearch]);

	const { refetch: refetchCompaniesInSearch } = useQuery(
		"companies-in-search",
		() =>
			companyActions.list(
				"",
				undefined,
				"name",
				999,
				searchParams.get("companies")
					? searchParams
							.get("companies")!
							.split(",")
							.map((c) => parseInt(c))
					: []
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setSelectedCompanies(res.results);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	useEffect(() => {
		if (searchParams.get("companies")) {
			refetchCompaniesInSearch();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [refetchCompaniesInSearch]);

	return (
		<>
			<Box
				sx={{
					display: "flex",
					flex: 1,
					alignItems: "center",
					justifyContent: "space-between",
					flexDirection: {
						xs: "column",
						sm: "column",
						md: "column",
						lg: "column-reverse",
					},
					gap: {
						xs: 2,
						sm: 2,
						md: 2,
						lg: 0,
					},
					marginTop: {
						xs: 2,
						sm: 2,
						md: 2,
						lg: 0,
					},
					width: "100%",
					maxWidth: {
						md: "100%",
						lg: "calc(100% - 340px)",
					},
					"& .MuiAutocomplete-inputRoot": {
						paddingTop: "1px!important",
						paddingBottom: "1px!important",
					},
				}}
			>
				{user && user.is_superuser && (
					<Box
						sx={{
							display: "flex",
							flexDirection: {
								xs: "column",
								sm: "column",
								md: "column",
								lg: "row",
							},
							flex: 1,
							alignItems: "center",
							justifyContent: "flex-start",
							marginTop: "0px",
							alignSelf: "flex-start",
							gap: {
								xs: 2,
								sm: 2,
								md: 2,
								lg: 0,
							},
							maxWidth: "100%",
						}}
					>
						<Box
							sx={{
								position: "relative",
								flex: 1,
								minWidth: { xs: 240, sm: 240, md: 240, lg: 240, xl: 300 },
								maxWidth: "100%",
							}}
						>
							<Autocomplete
								disablePortal
								id="company-box"
								options={companies}
								value={selectedCompanies}
								sx={{
									width: "100%",
									borderRadius: "24px",
									borderColor: "#ececec",
									"& .MuiFormControl-root": {
										border: "none!important",
										width: "100%",
									},
									"& .MuiInputBase-root": {
										padding: "8px",
										minHeight: "38px",
										border: "none!important",
										alignItems: "center",
										flexWrap: "nowrap",
										position: "relative",
									},
									"& .MuiAutocomplete-endAdornment": {
										position: "sticky",
										right: "-50px!important",
										marginBottom: "-28px!important",
										marginRight: "-28px",
									},
								}}
								multiple
								getOptionKey={(company) => company?.id ?? ""}
								getOptionLabel={(company) => company?.name ?? ""}
								onChange={(_, value) => {
									setSelectedCompanies(value);
									setPaginationModel({
										page: 0,
										pageSize: 10,
									});
									setFilters((prev) => ({
										...prev,
										companies: value.map((company) => company!.id),
									}));
								}}
								renderInput={(params) => (
									<TextField
										{...params}
										variant="outlined"
										placeholder={t("general.company")}
										sx={{
											maxHeight: 56,
											overflow: "auto!important",
											border: "1px solid #ececec",
											borderRadius: 2,
											backgroundColor: "#fff",
											"& input": {
												fontSize: 14,
											},
										}}
										onChange={(ev) => {
											if (ev.target.value !== "" || ev.target.value !== null) {
												handleSearchChange(ev.target.value);
											}
										}}
										InputProps={{
											...params.InputProps,
											endAdornment: (
												<>
													{isLoadingCompanies ? (
														<CircularProgress color="inherit" size={20} />
													) : null}
													{params.InputProps.endAdornment}
												</>
											),
										}}
									/>
								)}
							/>
						</Box>
					</Box>
				)}
				<Box
					sx={{
						display: "flex",
						flex: 1,
						alignItems: "center",
						justifyContent: "flex-end",
						flexDirection: {
							xs: "row",
							sm: "row",
							md: "row",
							lg: "row",
						},
						gap: {
							xs: 0.5,
							sm: 0.5,
							md: 0.5,
							lg: 0.5,
						},
						marginTop: {
							xs: 0,
							sm: 0,
							md: 0,
							lg: 0,
						},
						width: "100%",
					}}
				></Box>
			</Box>
		</>
	);
}
