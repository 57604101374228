import { useContext, useEffect, useState, useCallback } from "react";
import {
	Alert,
	Box,
	Typography,
	TextField,
	Divider,
	IconButton,
	Button,
	Tooltip,
	CircularProgress,
} from "@mui/material";
import { makeStyles, createStyles } from "@mui/styles";
import {
	sale as saleActions,
	submittedSale as submittedSaleActions,
	strings as stringActions,
	//estimation as estimationActions,
} from "../../controllers";
import DataTable from "../DataTable/DataTable";
import { Heading } from "../Heading";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
	GridRenderCellParams,
	GridSortItem,
	GridSortModel,
} from "@mui/x-data-grid";
import {
	IBalance,
	IEntity,
	IEstimation,
	IPayment,
	ISale,
	ISubmittedSale,
} from "../../models";
import { UserContext } from "../../contexts/user";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSnackbar } from "notistack";
import { SaleActionBar } from "../SaleActionBar";
import { Filters } from "../../routes/Routes.types";
import { BasicModal, PurchaseAgree, UploadFile } from "../../components";
import { PurchaseOffsets } from "../PurchaseOffsets";
import { NumericFormat } from "react-number-format";
//import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import { useTranslation } from "react-i18next";
import { ProductStatusChip } from "../ProductDetail/ProductStatusChip";
import { keyframes } from "@mui/system";

const useStyles = makeStyles(() =>
	createStyles({
		customHeader: {
			paddingLeft: "28px!important",
		},
	})
);

let loadingOffsetsInterval: NodeJS.Timer | null = null;

const pulse = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
`;

const Q2Date = new Date("2025-04-01T00:00:00Z");

export function SalesReport() {
	const navigate = useNavigate();
	const classes = useStyles();
	const { user, getUser } = useContext(UserContext);
	const { t } = useTranslation();
	const [searchParams, setSearchParams] = useSearchParams();
	const [search, setSearch] = useState(searchParams.get("search") ?? "");
	const [filters, setFilters] = useState<Filters>({
		...(user?.is_superuser
			? {
					companies: searchParams.get("companies")
						? searchParams
								.get("companies")!
								.split(",")
								.map((c) => parseInt(c))
						: [],
			  }
			: {}),
	});
	const [paginationModel, setPaginationModel] = useState({
		page: searchParams.get("page") ? parseInt(searchParams.get("page")!) : 0,
		pageSize:
			searchParams.get("pageSize") &&
			!isNaN(parseInt(searchParams.get("pageSize")!))
				? parseInt(searchParams.get("pageSize")!)
				: 10,
	});
	const [sortModel, setSortModel] = useState<GridSortModel>(
		new Array<GridSortItem>({
			field: searchParams.get("order")
				? searchParams.get("order")!.replaceAll("-", "")
				: "updated_at",
			sort: searchParams.get("order")
				? searchParams.get("order")!.includes("-")
					? "desc"
					: "asc"
				: "desc",
		})
	);
	const [sales, setSales] = useState<Array<IEstimation>>([]);
	const [rowCount, setRowCount] = useState<number>(0);
	const [selectedRows, setSelectedRows] = useState<Array<IEntity>>([]);
	const [balance, setBalance] = useState<IBalance | null>(null);
	const [payments, setPayments] = useState<IPayment[] | null>(null);
	const [submittedSales, setSubmittedSales] =
		useState<Array<ISubmittedSale> | null>(null);
	const [showConfirmSalesModal, setShowConfirmSalesModal] = useState(false);
	const [showPurchaseOffsetsModal, setShowPurchaseOffsetsModal] =
		useState(false);
	const [showSubmitSalesModal, setShowSubmitSalesModal] =
		useState<Boolean | null>(null);
	const [animateSubmitSalesButton, setAnimateSubmitSalesButton] =
		useState(false);
	const [showPurchaseOffsets, setShowPurchaseOffsets] = useState(false);
	const [loadingOffsetsResult, setLoadingOffsetsResult] = useState(false);
	const [messagesIndex, setMessagesIndex] = useState(0);
	const [showImportFileModal, setShowImportFileModal] = useState(false);
	const [importFile, setImportFile] = useState<File | null>(null);
	const [exporting, setExporting] = useState(false);
	const [importing, setImporting] = useState(false);
	const [exportedSales, setExportedSales] = useState<Array<IEstimation> | null>(
		null
	);
	const [missingSales, setMissingSales] = useState<number | null>(null);
	const [allProductsAreInactive, setAllProductsAreInactive] = useState(false);
	const [offsetsLoadingMessages, setOffsetsLoadingMessages] = useState([
		t("salesReport.confirmingPayment"),
		t("salesReport.reservingOffsets"),
		t("salesReport.checkingDetails"),
		t("salesReport.finalizingCertification"),
		t("salesReport.aboutToFinish"),
	]);
	const { enqueueSnackbar } = useSnackbar();
	const currentYear = new Date().getUTCFullYear();
	const currentMonth = new Date().getUTCMonth();
	const currentQuarter = Math.ceil((currentMonth + 1) / 3);
	const [selectedSale, setSelectedSale] = useState<number | null>(null);
	const [showForecastedEmissions, setShowForecastedEmissions] = useState(false);
	const queryClient = useQueryClient();

	useEffect(() => {
		const searchValue = searchParams.get("search");
		if ((searchValue ?? "") !== search) {
			setPaginationModel((paginationModel) => {
				paginationModel.page = 0;
				return paginationModel;
			});
			setSearchParams((params) => {
				if (search) {
					params.set("search", search);
				} else {
					params.delete("search");
				}
				return params;
			});
		}
	}, [search, searchParams, setSearchParams]);

	useEffect(() => {
		const companiesValue = searchParams.get("companies");
		if (companiesValue !== (filters.companies?.join(",") || null)) {
			setPaginationModel((paginationModel) => {
				paginationModel.page = 0;
				return paginationModel;
			});
			setSearchParams((params) => {
				if ((filters.companies ?? []).length > 0) {
					params.set("companies", filters.companies!.join(","));
				} else {
					params.delete("companies");
				}
				return params;
			});
		}
	}, [filters, searchParams, setSearchParams]);

	useEffect(() => {
		const pageValue = searchParams.get("page");
		const pageSizeValue = searchParams.get("page");
		if (
			pageValue !== paginationModel.page.toString() ||
			pageSizeValue !== paginationModel.pageSize.toString()
		) {
			setSearchParams((params) => {
				if (pageValue !== paginationModel.page.toString()) {
					if (paginationModel.page !== 0) {
						params.set("page", paginationModel.page.toString());
					} else {
						params.delete("page");
					}
				}
				if (pageSizeValue !== paginationModel.pageSize.toString()) {
					if (paginationModel.pageSize !== 10) {
						params.set("pageSize", paginationModel.pageSize.toString());
					} else {
						params.delete("pageSize");
					}
				}
				return params;
			});
		}
	}, [paginationModel, searchParams, setSearchParams]);

	useEffect(() => {
		const orderValue = searchParams.get("order");
		if (
			sortModel.length > 0 &&
			orderValue !==
				(sortModel[0].sort === "desc" ? "-" : "") + sortModel[0].field
		) {
			setSearchParams((params) => {
				if (
					sortModel[0].sort !== "desc" ||
					sortModel[0].field !== "updated_at"
				) {
					console.log(
						(sortModel[0].sort === "desc" ? "-" : "") + sortModel[0].field
					);
					params.set(
						"order",
						(sortModel[0].sort === "desc" ? "-" : "") + sortModel[0].field
					);
				} else {
					params.delete("order");
				}

				return params;
			});
		}
	}, [sortModel, searchParams, setSearchParams]);

	useEffect(() => {
		if (loadingOffsetsResult) {
			loadingOffsetsInterval = setInterval(() => {
				setMessagesIndex((prevIndex) =>
					prevIndex === offsetsLoadingMessages.length - 1 ? 0 : prevIndex + 1
				);
			}, 5000);
		} else if (loadingOffsetsInterval) {
			clearInterval(loadingOffsetsInterval);
		}

		return () => {
			if (loadingOffsetsInterval) {
				clearInterval(loadingOffsetsInterval);
			}
		};
	}, [loadingOffsetsResult, offsetsLoadingMessages.length]);

	useQuery("strings", () => stringActions.list(), {
		enabled: true,
		refetchOnWindowFocus: false,
		retry: false,
		onSuccess: (res) => {
			if (res.results.length > 0) {
				setOffsetsLoadingMessages([
					(res.results[0] as any)["offsets_loading_1"],
					(res.results[0] as any)["offsets_loading_2"],
					(res.results[0] as any)["offsets_loading_3"],
					(res.results[0] as any)["offsets_loading_4"],
					(res.results[0] as any)["offsets_loading_5"],
				]);
			}
		},
		onError: (error: any) => {
			console.log(error);
		},
	});

	// TODO: Make an endpoint to check all sales for the current quarter are submitted

	const { refetch: refetchSubmittedSales } = useQuery(
		"submitted-sales",
		({ signal }) =>
			submittedSaleActions.list(
				!user?.is_superuser ? user?.company.id : undefined,
				undefined,
				`q${new Date().getUTCMonth() / 3 + 1}`,
				new Date().getUTCFullYear().toString(),
				filters.companies,
				1,
				undefined,
				1,
				signal
			),
		{
			enabled:
				!user?.is_superuser &&
				(user?.email.includes("climeco.com") ||
					new Date().getTime() > new Date(Q2Date).getTime()),
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setSubmittedSales(res.results);
			},
			onError: (error: any) => {
				console.log(error);
				setExporting(false);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	const { mutate: mutateConfirmSales, isLoading: isLoadingConfirmSales } =
		useMutation(
			"confirm-sales-mutate",
			() => submittedSaleActions.create(user!.company.id),
			{
				retry: false,

				onSuccess: async (res) => {
					await refetchSubmittedSales();
					setShowConfirmSalesModal(false);
				},
				onError: (error: any) => {
					console.log(error);
					enqueueSnackbar(t("general.errorMessage"), {
						variant: "error",
					});
				},
			}
		);

	useEffect(() => {
		console.log(submittedSales, missingSales, allProductsAreInactive);
		if (
			submittedSales &&
			submittedSales.length === 0 &&
			missingSales !== null &&
			missingSales === 0 &&
			!allProductsAreInactive
		) {
			setShowSubmitSalesModal(true);
		}
	}, [missingSales, submittedSales, allProductsAreInactive]);

	const { refetch, isLoading, isRefetching, isFetching } = useQuery(
		"sales",
		({ signal }) =>
			(exporting ? saleActions.downloadList : saleActions.list)(
				!user?.is_superuser ? user?.company.id : undefined,
				search,
				undefined,
				undefined,
				filters.companies,
				(exporting ? 0 : paginationModel.page) + 1,
				sortModel.length > 0
					? `${sortModel[0].sort === "desc" ? "-" : ""}${
							sortModel[0].field === "company"
								? "company__name"
								: sortModel[0].field
					  }`
					: "-updated_at",
				exporting ? 100000 : paginationModel.pageSize,
				signal
			),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				if (exporting) {
					setExportedSales(res.results);
					setExporting(false);
				} else {
					setSales(res.results);
					setRowCount(res.count);
				}
			},
			onError: (error: any) => {
				console.log(error);
				setExporting(false);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	const { refetch: refetchBalance } = useQuery(
		"balance",
		({ signal }) =>
			saleActions.getBalance(
				user?.is_superuser && filters.companies ? filters.companies : [],
				!user?.is_superuser ? user?.company.id : undefined,
				undefined,
				undefined,
				undefined,
				signal
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setBalance(res);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), { variant: "error" });
			},
		}
	);

	const getBalance = useCallback(() => {
		if (
			!user?.is_superuser ||
			(user?.is_superuser && filters.companies && filters.companies?.length > 0)
		) {
			refetchBalance();
		} else if (user?.is_superuser) {
			setBalance(null);
		}
	}, [user?.is_superuser, filters.companies, refetchBalance]);

	const { refetch: refetchMissingSales } = useQuery(
		"missing-sales",
		() =>
			saleActions.getMissingSales(
				user!.company.id,
				`${new Date().getUTCFullYear() - (currentQuarter === 1 ? 1 : 0)}-${
					currentQuarter === 1
						? "10"
						: currentQuarter === 2
						? "01"
						: currentQuarter === 3
						? "04"
						: "07"
				}-01`,
				`${new Date().getUTCFullYear() - (currentQuarter === 1 ? 1 : 0)}-${
					currentQuarter === 1
						? "12"
						: currentQuarter === 2
						? "03"
						: currentQuarter === 3
						? "06"
						: "09"
				}-28`
			),
		{
			enabled:
				!user?.is_superuser &&
				(user?.email.includes("climeco.com") ||
					new Date().getTime() > new Date(Q2Date).getTime()),
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setMissingSales(res.count);
				setAllProductsAreInactive(res.all_products_are_inactive);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), { variant: "error" });
			},
		}
	);

	const { refetch: refetchPendingPayments } = useQuery(
		"pending-payments",
		() => saleActions.getPayments(user!.company.id, true),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setPayments(res);
				if (res.filter((p) => p.invoice_url).length > 0) {
					// Not blocking it right now
					//setShowPurchaseOffsets(true);
				}
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), { variant: "error" });
			},
		}
	);

	const { mutate } = useMutation(
		"sales-detail-update",
		({
			saleId,
			amount,
			companyId,
		}: {
			saleId: number;
			amount: number;
			companyId?: number;
		}) => saleActions.update(saleId, amount, companyId),
		{
			retry: false,
		}
	);

	useEffect(() => {
		if (showSubmitSalesModal === false) {
			document.querySelector("main")?.scrollTo({ top: 0, behavior: "smooth" });
			setAnimateSubmitSalesButton(true);
		} else {
			setAnimateSubmitSalesButton(false);
		}
	}, [showSubmitSalesModal]);

	useEffect(() => {
		queryClient.invalidateQueries("sales");
		queryClient.cancelQueries({ queryKey: "sales" });
		queryClient.invalidateQueries("balance");
		queryClient.cancelQueries({ queryKey: "balance" });
		refetch();
		if (
			!user?.is_superuser &&
			(user?.email.includes("climeco.com") ||
				new Date().getTime() > new Date(Q2Date).getTime())
		) {
			refetchSubmittedSales();
			refetchMissingSales();
		}
		getBalance();
	}, [
		refetch,
		refetchSubmittedSales,
		refetchMissingSales,
		getBalance,
		search,
		filters,
		paginationModel,
		sortModel,
		queryClient,
		user,
	]);

	const handleSaleAmountChange = (
		estimationId: number,
		newValue: number,
		saleId: number
	) => {
		setSales((currentSales) => {
			return currentSales.map((sale) => {
				if (sale.id === estimationId && sale.product.sales) {
					const index = sale.product.sales.findIndex((s) => s.id === saleId);
					if (index !== -1) {
						sale.product.sales[index]!.amount = newValue;
					}
				}
				return sale;
			});
		});
	};

	const columns = [
		{
			field: "id",
			renderCell: (params: GridRenderCellParams<IEstimation>) => {
				return (
					<Typography sx={{ marginLeft: 2, fontSize: 14 }}>
						{Intl.NumberFormat("en", {
							maximumFractionDigits: 0,
						}).format(params.row.id)}
					</Typography>
				);
			},
			headerName: t("salesReport.id"),
			headerClassName: classes.customHeader,
		},
		{
			field: "product__name",
			headerName: t("salesReport.product"),
			flex: 1,
			minWidth: 200,
			renderCell: (params: GridRenderCellParams<IEstimation>) => {
				const fullName =
					params.row.product.name +
					(params.row.product.asin
						? " - " + params.row.product.asin
						: params.row.product.upc
						? " - " + params.row.product.upc
						: params.row.product.sku
						? " - " + params.row.product.sku
						: "");
				return (
					<Tooltip title={fullName}>
						<Typography
							variant="body2"
							onClick={() => {
								navigate("/sales/" + params.row.id);
							}}
							sx={{
								color: "#25406D",
								fontWeight: "bold",
								cursor: "pointer",
								"&:hover": { cursor: "pointer", textDecoration: "underline" },
							}}
						>
							{fullName}
						</Typography>
					</Tooltip>
				);
			},
		},
		{
			field: "product__status",
			headerName: t("productDetail.status"),
			flex: 1,
			minWidth: 150,
			renderCell: (params: GridRenderCellParams<IEstimation>) => {
				return (
					<>
						<ProductStatusChip
							status={
								params.row.product.status === "OUTDATED" ? "OUTDATED" : "ACTIVE"
							}
						/>
					</>
				);
			},
		},
		{
			field: "asin",
			headerName: "ASIN/UPC/SKU",
			sortable: false,
			minWidth: 150,
			renderCell: (params: GridRenderCellParams<IEstimation>) => {
				return (
					<Typography variant="body2">
						{params.row.product.asin
							? params.row.product.asin
							: params.row.product.upc
							? params.row.product.upc
							: params.row.product.sku
							? params.row.product.sku
							: ""}
					</Typography>
				);
			},
		},
		{
			field: "emissions_product",
			headerAlign: "right",
			sortable: false,
			minWidth: 150,
			align: "right",
			renderCell: (params: GridRenderCellParams<IEstimation>) => {
				return Intl.NumberFormat("en", {
					maximumFractionDigits: 4,
				}).format(params.row.product_lca_version_emission?.emissions ?? 0);
			},
			headerName: t("salesReport.emissionsPerProduct"),
			headerClassName: classes.customHeader,
		},
		{
			field: "product.sales",
			headerName: t("salesReport.unitSales"),
			sortable: false,
			minWidth: 380,
			headerAlign: "right",
			align: "right",
			renderHeader: () => {
				return (
					<Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
						<Typography variant="body2" sx={{ fontWeight: 500 }}>
							{t("salesReport.unitSales")}
						</Typography>
					</Box>
				);
			},
			renderCell: (params: GridRenderCellParams<IEstimation>) => {
				return (
					<Box
						sx={{
							display: "flex",
							gap: 3,
							overflow: "auto",
							direction: "rtl",
							height: "60px",
							marginTop: "12px",
						}}
					>
						{[...(params.row.product?.sales ?? [])]
							.filter(
								(sale, index) =>
									!(params.row.product?.sales ?? []).find(
										(s, sIndex) =>
											s.id !== sale.id &&
											s.start_date === sale.start_date &&
											((s.amount !== null && sale.amount === null) ||
												((s.amount ?? 0) >= (sale.amount ?? 0) &&
													sIndex < index))
									) &&
									sale.start_date &&
									new Date(sale.start_date) < new Date()
							)
							.sort((a, b) =>
								a.start_date === b.start_date
									? (a.amount ?? 0) - (b.amount ?? 0)
									: new Date(b.start_date).getTime() -
									  new Date(a.start_date).getTime()
							)
							//.filter((_, index, array) => index >= array.length - 4)
							.map((sale: ISale, index) => {
								const saleYear = new Date(sale.start_date).getUTCFullYear();
								const saleMonth = new Date(sale.start_date).getUTCMonth();
								const saleQuarter = Math.ceil((saleMonth + 1) / 3);
								const isPreviousQuarter =
									(saleYear === currentYear - 1 &&
										saleQuarter === 4 &&
										currentQuarter === 1) ||
									(saleYear === currentYear &&
										saleQuarter === currentQuarter - 1);

								return (
									<Box
										key={sale.id}
										sx={{ minWidth: "62.5px", width: "62.5px" }}
									>
										<Typography fontSize={12} fontWeight={"bold"}>
											{"Q" +
												Math.ceil(
													((new Date(sale.start_date).getUTCMonth() + 1) / 12) *
														4
												).toString() +
												"-" +
												new Date(sale.start_date).getUTCFullYear().toString()}
										</Typography>
										<NumericFormat
											value={sale.amount}
											displayType={"input"}
											thousandSeparator={true}
											allowNegative={false}
											decimalScale={0}
											onValueChange={({ value }) => {
												const numericValue = parseFloat(value);
												handleSaleAmountChange(
													params.row.id,
													isNaN(numericValue) ? 0 : numericValue,
													sale.id
												);
											}}
											onBlur={async () => {
												if (
													(isPreviousQuarter || user?.is_superuser) &&
													sale.amount !== null
												) {
													console.log(sale);
													await new Promise((resolve) => {
														mutate(
															{
																saleId: sale.id,
																amount: sale.amount ?? 0,
																companyId: params.row.product.company!.id,
															},
															{
																onSuccess: () => {
																	resolve(true);
																},
																onError: (error) => {
																	console.log(error);
																	resolve(true);
																},
															}
														);
													});
													getBalance();
													if (
														!user?.is_superuser &&
														(user?.email.includes("climeco.com") ||
															new Date().getTime() > new Date(Q2Date).getTime())
													) {
														refetchMissingSales();
														refetchSubmittedSales();
													}
												}
												setSelectedSale(null);
											}}
											onFocus={() => {
												if (sale.amount === null) {
													setSelectedSale(sale.id);
												}
											}}
											disabled={
												!user?.is_superuser &&
												(params.row.product.status === "OUTDATED" ||
													!isPreviousQuarter ||
													(isPreviousQuarter &&
														(!submittedSales ||
															(submittedSales ?? []).length > 0) &&
														selectedSale !== sale.id))
											}
											customInput={TextField}
											inputProps={{
												style: {
													padding: 0,
													border:
														isPreviousQuarter &&
														sale.amount === null &&
														params.row.product.status !== "OUTDATED"
															? "1px solid red"
															: "1px solid #ececec",
													direction: "ltr",
												},
											}}
										/>
									</Box>
								);
							})}
					</Box>
				);
			},
		},
		/*{
			field: "forecasted_2025_emissions",
			headerAlign: "right",
			sortable: false,
			minWidth: 150,
			align: "right",
			renderCell: (params: GridRenderCellParams<IEstimation>) => {
				return (
					<NumericFormat
						value={params.row.forecasted_2025_emissions}
						displayType={"input"}
						thousandSeparator={true}
						allowNegative={false}
						decimalScale={0}
						disabled={true}
						//onValueChange={({ value }) => {
						//	const numericValue = parseFloat(value);
						//	setSales((currentSales) => {
						//		return currentSales.map((sale) => {
						//			if (sale.id === params.row.id) {
						//				sale.forecasted_2025_emissions = numericValue;
						//			}
						//			return sale;
						//		});
						//	});
						//}}
						//onBlur={async () => {
						//	await new Promise((resolve) => {
						//		mutateForecasted(
						//			{
						//				id: params.row.id,
						//				companyId: params.row.product.company!.id,
						//				forecastedSales: params.row.forecasted_2025_emissions ?? 0,
						//				forecastedSalesId:
						//					params.row.forecasted_2025_emissions_id ?? 0,
						//			},
						//			{
						//				onSuccess: (res) => {
						//					resolve(true);
						//					setSales((currentSales) => {
						//						return currentSales.map((sale) => {
						//							if (sale.id === params.row.id) {
						//								sale.forecasted_2025_emissions_id =
						//									res.forecasted_2025_emissions_id;
						//							}
						//							return sale;
						//						});
						//					});
						//				},
						//				onError: (error) => {
						//					console.log(error);
						//					resolve(true);
						//				},
						//			}
						//		);
						//	});
						//	getBalance();
						//	if (!user?.is_superuser && (user?.email.includes("climeco.com") || new Date().getTime() > new Date(Q2Date).getTime())) {
						//		refetchMissingSales();
						//		refetchSubmittedSales();
						//	}
						//}}
						customInput={TextField}
						inputProps={{
							style: {
								padding: 0,
								border: !(params.row.forecasted_2025_emissions ?? 0)
									? "1px solid red"
									: "1px solid #ececec",
								direction: "ltr",
								width: "58px",
							},
						}}
					/>
				);
			},
			headerName: 2025 + " " + t("salesReport.forecastedSales"),
			headerClassName: classes.customHeader,
		},*/
		{
			field: "emissions_current_year",
			headerAlign: "right",
			sortable: false,
			minWidth: 150,
			align: "right",
			renderCell: (params: GridRenderCellParams<IEstimation>) => {
				return Intl.NumberFormat("en", {
					maximumFractionDigits: 2,
					minimumFractionDigits: 0,
				}).format(
					((params.row.product_lca_version_emission?.emissions ?? 0) *
						(params.row.product?.sales ?? [])
							.filter(
								(sale) =>
									new Date(sale.start_date).getUTCFullYear() ===
									new Date().getUTCFullYear()
							)
							.reduce((prev, current) => {
								return prev + (current.amount ?? 0);
							}, 0)) /
						1000
				);
			},
			headerName:
				t("salesReport.emissionsCurrentYear") +
				" " +
				new Date().getUTCFullYear(),
			headerClassName: classes.customHeader,
		},
		{
			field: "total",
			headerName: t("salesReport.totalUnitsSold"),
			headerAlign: "right",
			sortable: false,
			minWidth: 150,
			align: "right",
			renderCell: (params: GridRenderCellParams<IEstimation>) => {
				return Intl.NumberFormat("en", {
					maximumFractionDigits: 0,
				}).format(
					params.row.product?.sales?.reduce((prev, current) => {
						return prev + (current.amount ?? 0);
					}, 0) ?? 0
				);
			},
		},
		/*{
			field: "emissions",
			headerName: t("salesReport.forecastedEmissions"),
			headerAlign: "right",
			sortable: false,
			minWidth: 160,
			align: "right",
			renderCell: (params: GridRenderCellParams<IEstimation>) => {
				return Intl.NumberFormat("en", {
					maximumFractionDigits: 0,
				}).format(
					Math.ceil(
						((params.row.amount ?? 0) *
							(params.row.product_lca_version_emission?.emissions ?? 0)) /
							1000
					)
				);
			},
		},
		{
			field: "actual_emissions",
			headerName: t("salesReport.actualEmissions"),
			headerAlign: "right",
			sortable: false,
			minWidth: 160,
			align: "right",
			renderCell: (params: GridRenderCellParams<IEstimation>) => {
				return Intl.NumberFormat("en", {
					maximumFractionDigits: 0,
				}).format(
					Math.ceil(
						((params.row.product?.sales?.reduce((prev, current) => {
							return prev + current.amount;
						}, 0) ?? 0) *
							(params.row.product_lca_version_emission?.emissions ?? 0)) /
							1000
					)
				);
			},
		},
		{
			field: "balance",
			headerName: t("salesReport.balance"),
			headerAlign: "right",
			sortable: false,
			minWidth: 150,
			align: "right",
			renderCell: (params: GridRenderCellParams<IEstimation>) => {
				const finalAmount = Math.ceil(
					Math.ceil(
						((params.row.amount ?? 0) *
							(params.row.product_lca_version_emission?.emissions ?? 0)) /
							1000
					) -
						Math.ceil(
							((params.row.product?.sales?.reduce((prev, current) => {
								return prev + current.amount;
							}, 0) ?? 0) *
								(params.row.product_lca_version_emission?.emissions ?? 0)) /
								1000
						)
				);
				return (
					<span style={{ color: finalAmount < 0 ? "#EB4B4B" : "inherit" }}>
						{Intl.NumberFormat("en", {
							maximumFractionDigits: 0,
						}).format(finalAmount === 0 ? Math.abs(finalAmount) : finalAmount)}
					</span>
				);
			},
		},*/
	];

	if (showForecastedEmissions) {
		const forecastedEmissionsColumn = {
			field: "forecasted_2025_emissions",
			headerAlign: "right",
			sortable: false,
			minWidth: 150,
			align: "right",
			renderCell: (params: GridRenderCellParams<IEstimation>) => {
				return (
					<NumericFormat
						value={params.row.forecasted_2025_emissions}
						displayType={"input"}
						thousandSeparator={true}
						allowNegative={false}
						decimalScale={0}
						disabled={true}
						customInput={TextField}
						inputProps={{
							style: {
								padding: 0,
								border: !(params.row.forecasted_2025_emissions ?? 0)
									? "1px solid red"
									: "1px solid #ececec",
								direction: "ltr",
								width: "58px",
							},
						}}
					/>
				);
			},
			headerName: 2025 + " " + t("salesReport.forecastedSales"),
			headerClassName: classes.customHeader,
		};
		columns.splice(6, 0, forecastedEmissionsColumn as any);
	}

	useEffect(() => {
		if (exporting) {
			refetch();
		}
	}, [refetch, exporting]);

	const { mutate: mutateImport } = useMutation(
		"sales-import",
		({ importFile, companyId }: { importFile: File; companyId: number }) =>
			saleActions.import(importFile, companyId),
		{
			retry: false,
		}
	);

	/*const { mutate: mutateForecasted } = useMutation(
		"sales-import",
		({
			id,
			companyId,
			forecastedSales,
			forecastedSalesId,
		}: {
			id: number;
			companyId: number;
			forecastedSales: number;
			forecastedSalesId?: number;
		}) =>
			estimationActions.update2025ForecastedSales({
				id,
				companyId,
				forecastedSales,
				forecastedSalesId,
			}),
		{
			retry: false,
		}
	);*/

	const handleImport = () => {
		setImporting(true);
		mutateImport(
			{
				importFile: importFile!,
				companyId: user?.is_superuser
					? (filters.companies ?? [])[0]!
					: user?.company.id!,
			},
			{
				onSuccess: () => {
					enqueueSnackbar(t("salesReport.salesImported"), {
						variant: "success",
					});
					setImporting(false);
					setShowImportFileModal(false);
					setImportFile(null);
					if (
						!user?.is_superuser &&
						(user?.email.includes("climeco.com") ||
							new Date().getTime() > new Date(Q2Date).getTime())
					) {
						refetchSubmittedSales();
						refetchMissingSales();
					}
					refetch();
					getBalance();
					refetchPendingPayments();
					getUser();
				},
				onError: (error: any) => {
					let message = t("general.errorMessage");
					if (error.response.data.error) {
						message = message + ". " + error.response.data.error;
					}
					enqueueSnackbar(message, {
						variant: "error",
					});
					setImporting(false);
					setShowImportFileModal(false);
					setImportFile(null);
				},
			}
		);
		// TO DO: validate results
		//		-> Products are found?
		//		-> Values are correct?
	};

	useEffect(() => {
		const handleExport = () => {
			const previousQuarter = currentQuarter === 1 ? 4 : currentQuarter - 1;
			const previousQuarterYear =
				currentQuarter === 1 ? currentYear - 1 : currentYear;
			const columns: string[] = [
				"ID",
				"Product Name",
				"ASIN/UPC/SKU",
				`Q${previousQuarter} ${previousQuarterYear} Sales`,
				//"Forecasted 2025 Sales",
			];

			const sanitizeTitle = (title: string): string =>
				title.replace(/[^a-zA-Z0-9\s]/g, " ");

			const startDate = `${previousQuarterYear}-${
				currentQuarter === 1
					? "10"
					: currentQuarter === 2
					? "01"
					: currentQuarter === 3
					? "04"
					: "07"
			}-01`;

			const csvContent = (exportedSales ?? []).map((row) => {
				const sanitizedTitle = sanitizeTitle(row.product.name ?? "");
				return `"${row.product.id}","${sanitizedTitle}","${
					row.product.asin ?? row.product.upc ?? row.product.sku ?? ""
				}",${
					row.product.sales?.find(
						(s) => s.start_date.split("T")[0] === startDate
					)?.amount ?? ""
				}`; //,${row.forecasted_2025_emissions}`;
			});

			const header = columns.map((col) => `"${col}"`).join(",");
			const csv = [header, ...csvContent].join("\n");

			const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
			const link = document.createElement("a");
			link.href = URL.createObjectURL(blob);
			link.download = "data.csv";
			link.click();
		};

		if (exportedSales !== null) {
			handleExport();
		}
	}, [currentQuarter, currentYear, exportedSales]);

	const finalBalance = (() => {
		const num =
			Math.sign(
				(balance?.purchased_offsets ?? 0) -
					(balance?.sales_amount ?? 0) +
					(balance?.offsets_amount ?? 0) /*-
				(balance?.estimated_2025_amount ?? 0) /*-
				(balance?.estimated_amount ?? 0)*/
			) *
			Math.ceil(
				Math.abs(
					(balance?.purchased_offsets ?? 0) -
						(balance?.sales_amount ?? 0) +
						(balance?.offsets_amount ?? 0) /* -
					(balance?.estimated_2025_amount ?? 0) /*-
					(balance?.estimated_amount ?? 0)*/
				)
			);
		return Math.ceil(num) === -0
			? 0
			: Math.sign(num) * Math.ceil(Math.abs(num));
	})();

	const decertifyingBalanceCompanies = [238, 364, 363, 362, 361, 332, 349, 329];

	const salesNotComplete =
		balance?.missing_2025_forecasted_sales &&
		!decertifyingBalanceCompanies.find((c) => c === user?.company.id); //|| balance?.missing_q4_2024_sales;

	const isBalancePositive = finalBalance >= -0;

	const areForecastedEmissionsToBePaid =
		(balance?.estimated_2025_amount ?? 0) > 0 &&
		user?.company.paid_forecasted_2025_sales === "NOT_STARTED";

	const areForecastedEmissionsPending =
		(balance?.estimated_2025_amount ?? 0) > 0 &&
		user?.company.paid_forecasted_2025_sales === "PENDING";

	/*const areForecastedEmissionsPaid =
		(balance?.estimated_2025_amount ?? 0) > 0 &&
		user?.company.paid_forecasted_2025_sales === "COMPLETED";*/

	return (
		<Box>
			<Heading
				title={t("salesReport.salesReport")}
				showSearch
				search={search}
				setSearch={setSearch}
				actions={() => (
					<SaleActionBar
						selectedRows={selectedRows}
						refetch={refetch}
						filters={filters}
						setFilters={setFilters}
						setPaginationModel={setPaginationModel}
					/>
				)}
			/>
			{(user?.is_superuser || user?.email.includes("climeco.com")) && (
				<Box
					sx={{ display: "flex", justifyContent: "flex-end", marginBottom: 2 }}
				>
					<Button
						variant="contained"
						sx={{
							minHeight: 34,
						}}
						onClick={() => {
							setShowForecastedEmissions(!showForecastedEmissions);
						}}
					>
						{showForecastedEmissions
							? "Hide forecasted emissions"
							: "Show forecasted emissions"}
					</Button>
				</Box>
			)}
			<Box
				sx={{
					padding: 2,
					background: "#efefef",
					display: "flex",
					gap: 8,
					justifyContent: "flex-end",
					borderRadius: 1,
					overflowX: "auto",
					flexDirection: {
						xs: "column",
						sm: "row",
					},
					textAlign: {
						xs: "right",
						sm: "left",
					},
					marginBottom: 2,
				}}
			>
				<>
					{showForecastedEmissions && (
						<>
							<Box sx={{ textAlign: "center" }}>
								<Typography fontWeight={"bold"} fontSize={14}>
									{t("salesReport.forecastedEmissionsTitle")}
									{" 2025 "}
									<span style={{ fontWeight: "normal" }}>
										{t("salesReport.mtCO2e")}
									</span>
									<Tooltip
										sx={{ padding: 0 }}
										title={
											t("salesReport.productEmissionsBasedOnForecasted") +
											" 2025 "
										}
									>
										<IconButton sx={{ height: 12 }}>
											<InfoIcon sx={{ fontSize: 14 }} />
										</IconButton>
									</Tooltip>
								</Typography>
								<Typography fontSize={14}>
									{balance?.estimated_2025_amount
										? Intl.NumberFormat("en", {
												maximumFractionDigits: 0,
										  }).format(
												Math.ceil(
													(balance?.estimated_2025_amount ?? 0) +
														(balance?.estimated_2025_amount_existing_certifications ??
															0)
												)
										  )
										: 0}
									{(areForecastedEmissionsToBePaid ||
										areForecastedEmissionsPending) &&
										balance?.estimated_2025_amount && (
											<small>
												{" "}
												|{" "}
												{Intl.NumberFormat("en", {
													maximumFractionDigits: 0,
												}).format(balance?.estimated_2025_amount)}{" "}
												{t("salesReport.toBePaid")}
											</small>
										)}
									{
										//(areForecastedEmissionsToBePaid ||
										//	areForecastedEmissionsPending ||
										//	areForecastedEmissionsPaid) && (
										//	<Chip
										//		size={"small"}
										//		sx={{
										//			backgroundColor: areForecastedEmissionsToBePaid
										//				? "#eb4343"
										//				: areForecastedEmissionsPending
										//				? "#f9ca24"
										//				: "#6ab04c",
										//			color: areForecastedEmissionsToBePaid
										//				? "#fff"
										//				: areForecastedEmissionsPending
										//				? "#000"
										//				: "#fff",
										//			fontSize: 8,
										//			fontWeight: "bold",
										//			marginLeft: 1,
										//			padding: "2px",
										//		}}
										//		label={
										//			areForecastedEmissionsToBePaid
										//				? t("salesReport.notPaid")
										//				: areForecastedEmissionsPending
										//				? t("salesReport.pending")
										//				: t("salesReport.paid")
										//		}
										//	/>
										//)
									}
								</Typography>
							</Box>
							<Box>
								<Divider orientation={"vertical"} />
							</Box>
						</>
					)}
				</>
				<Box sx={{ textAlign: "center" }}>
					<Typography fontWeight={"bold"} fontSize={14}>
						{t("salesReport.actualEmissionsTitle")}{" "}
						<span style={{ fontWeight: "normal" }}>
							{t("salesReport.mtCO2e")}
						</span>
						<Tooltip
							sx={{ padding: 0 }}
							title={t("salesReport.offsetsUsedActualSales")}
						>
							<IconButton sx={{ height: 12 }}>
								<InfoIcon sx={{ fontSize: 14 }} />
							</IconButton>
						</Tooltip>
					</Typography>
					<Typography fontSize={14}>
						{balance?.sales_amount
							? Intl.NumberFormat("en", {
									maximumFractionDigits: 0,
							  }).format(Math.ceil(balance?.sales_amount))
							: "--"}
					</Typography>
				</Box>

				<Box sx={{ textAlign: "center" }}>
					<Typography fontWeight={"bold"} fontSize={14}>
						{t("salesReport.purchasedOffsets")}{" "}
						<span style={{ fontWeight: "normal" }}>
							{t("salesReport.mtCO2e")}
						</span>
						<Tooltip sx={{ padding: 0 }} title={t("salesReport.totalOffsets")}>
							<IconButton sx={{ height: 12 }}>
								<InfoIcon sx={{ fontSize: 14 }} />
							</IconButton>
						</Tooltip>
					</Typography>
					<Typography fontSize={14}>
						{balance?.offsets_amount || balance?.purchased_offsets
							? Intl.NumberFormat("en", {
									maximumFractionDigits: 0,
							  }).format(
									Math.ceil(
										(balance?.offsets_amount ?? 0) +
											(balance?.purchased_offsets ?? 0)
									)
							  )
							: "--"}
					</Typography>
				</Box>
				<Box sx={{ textAlign: "center" }}>
					<Typography fontWeight={"bold"} fontSize={14}>
						{t("salesReport.offsetBalance")}{" "}
						<span style={{ fontWeight: "normal" }}>
							{t("salesReport.mtCO2e")}
						</span>
						<Tooltip
							sx={{ padding: 0 }}
							title={t("salesReport.purchasedOffsetsMinusForecastedOffsets")}
						>
							<IconButton sx={{ height: 12 }}>
								<InfoIcon sx={{ fontSize: 14 }} />
							</IconButton>
						</Tooltip>
					</Typography>
					<Typography
						fontSize={14}
						sx={{
							color: isBalancePositive ? "inherit" : "red",
						}}
					>
						{Intl.NumberFormat("en", {
							maximumFractionDigits: 0,
						}).format(finalBalance)}
					</Typography>
				</Box>
			</Box>

			{/*!user?.is_superuser &&
				(balance?.estimated_2025_amount ?? 0) > 0 &&
				user?.company.paid_forecasted_2025_sales === "NOT_STARTED" && (
					<Alert sx={{ marginBottom: 2 }} severity={"error"}>
						<>
							{t("salesReport.purchaseFor2025Sales")}
							<b
								style={{ cursor: "pointer" }}
								onClick={() => {
									setShowPurchaseOffsetsModal(true);
								}}
							>
								{". " + t("salesReport.clickHereToPurchase")}
							</b>
						</>
					</Alert>
				)*/}
			{!user?.is_superuser &&
				!allProductsAreInactive &&
				(user?.email.includes("climeco.com") ||
					new Date().getTime() > new Date(Q2Date).getTime()) &&
				sales.length > 0 &&
				missingSales !== null &&
				missingSales > 0 && (
					<>
						<Alert sx={{ marginTop: 2, marginBottom: 2 }} severity={"error"}>
							{t("salesReport.missingSales", {
								sales: missingSales,
							})}
						</Alert>
					</>
				)}
			{!user?.is_superuser &&
				(user?.email.includes("climeco.com") ||
					new Date().getTime() > new Date(Q2Date).getTime()) &&
				sales.length > 0 &&
				!allProductsAreInactive &&
				missingSales !== null &&
				missingSales === 0 &&
				submittedSales &&
				submittedSales.length === 0 && (
					<Box
						sx={{
							display: "flex",
							gap: 2,
							alignItems: "center",
							marginTop: 2,
							marginBottom: 2,
						}}
					>
						<Alert sx={{ flex: 1 }} severity={"error"}>
							{t("salesReport.notSubmittedSales")}
						</Alert>
						<Button
							variant="contained"
							sx={{
								minHeight: 34,
								...(animateSubmitSalesButton && {
									animation: `${pulse} 1s ease-in-out`,
									animationDelay: "0.5s",
									animationIterationCount: 3,
								}),
							}}
							onClick={() => {
								setShowConfirmSalesModal(true);
							}}
						>
							{t("salesReport.submitSales")}
						</Button>
					</Box>
				)}
			{!user?.is_superuser &&
				(user?.email.includes("climeco.com") ||
					new Date().getTime() > new Date(Q2Date).getTime()) &&
				sales.length > 0 &&
				!allProductsAreInactive &&
				missingSales !== null &&
				missingSales === 0 &&
				submittedSales &&
				(submittedSales ?? []).length > 0 && (
					<>
						<Divider />
						<Alert sx={{ marginTop: 2, marginBottom: 2 }} severity={"success"}>
							{t("salesReport.submittedSales")}
							{"."}
							{/** TO DO: TO BE REMOVED WHEN WE RELEASE THE FORECASTED SALES */}
							{(payments ?? []).length === 0 && (
								<>
									<br />
									<b>
										{t("salesReport.sendInvoice")}
										{"."}
									</b>
								</>
							)}
						</Alert>
					</>
				)}
			{showForecastedEmissions &&
				!user?.is_superuser &&
				missingSales !== null &&
				missingSales === 0 &&
				!allProductsAreInactive &&
				submittedSales &&
				(submittedSales ?? []).length > 0 &&
				payments &&
				(payments ?? []).length === 0 &&
				(salesNotComplete ||
					!isBalancePositive ||
					(isBalancePositive &&
						areForecastedEmissionsToBePaid &&
						finalBalance - (balance?.estimated_2025_amount ?? 0) < 0)) && (
					<Alert sx={{ marginBottom: 2 }} severity={"error"}>
						{salesNotComplete ? (
							t("salesReport.purchaseFor2025Sales") +
							" " +
							t("salesReport.completeSales")
						) : (
							<>
								{!isBalancePositive && areForecastedEmissionsToBePaid
									? t("salesReport.balanceNegativeAndEmissionsToBePaid")
									: isBalancePositive && areForecastedEmissionsToBePaid
									? t("salesReport.balancePositiveAndEmissionsToBePaid")
									: t("salesReport.balanceNegative")}{" "}
								<b
									style={{ cursor: "pointer" }}
									onClick={() => {
										setShowPurchaseOffsetsModal(true);
									}}
								>
									{t("salesReport.clickHereToPurchase")}
								</b>
							</>
						)}
					</Alert>
				)}
			{!user?.is_superuser &&
				showForecastedEmissions &&
				(payments ?? []).length > 0 && (
					<>
						<Divider />
						<Alert sx={{ marginTop: 2, marginBottom: 2 }} severity={"info"}>
							{t("salesReport.paymentSubmited")}
							<br />
							<b>{t("salesReport.paymentSubmitedInfo")}</b>
							<br />
							<b
								style={{ cursor: "pointer" }}
								onClick={() => {
									window.open(payments![0].invoice_url, "_blank");
								}}
							>
								<u>{t("climecoCertificationProcess.seeInvoice")}</u>
							</b>
						</Alert>
					</>
				)}
			<Box
				sx={{
					display: "flex",
					alignItems: "flex-end",
					justifyContent: "flex-end",
					width: "100%",
					marginBottom: 2,
					gap: 2,
				}}
			>
				<Button
					variant="outlined"
					sx={{ minHeight: 34 }}
					onClick={() => {
						setExporting(true);
					}}
				>
					{exporting ? (
						<CircularProgress size={12} sx={{ color: "white" }} />
					) : (
						t("salesReport.export") ?? "Export"
					)}
				</Button>
				{(user?.is_superuser ||
					(!user?.is_superuser &&
						(user?.email.includes("climeco.com") ||
							new Date().getTime() > new Date(Q2Date).getTime()) &&
						!allProductsAreInactive &&
						missingSales !== null &&
						submittedSales &&
						(submittedSales ?? []).length === 0)) && (
					<Button
						variant="outlined"
						sx={{ minHeight: 34 }}
						onClick={() => {
							setShowImportFileModal(true);
						}}
						disabled={
							(user?.is_superuser && (filters.companies ?? []).length !== 1) ||
							importing
						}
					>
						{importing ? (
							<CircularProgress size={12} sx={{ color: "white" }} />
						) : (
							t("salesReport.import") ?? "Import"
						)}
					</Button>
				)}
			</Box>
			<DataTable
				rows={sales}
				rowHeight={100}
				columns={columns}
				setSelectedRows={setSelectedRows}
				showCheckbox={false}
				rowCount={rowCount}
				sx={{
					border: "1px solid #E3E3E3",
					borderRadius: "28px",
					"& .MuiDataGrid-root": {
						border: "none",
					},
					"& .MuiDataGrid-columnHeaders": {},
					"& .MuiDataGrid-footerContainer": {
						borderTop: "none!important",
					},
					"& .MuiDataGrid-columnHeaderTitle": {
						textOverflow: "clip",
						whiteSpace: "normal",
						lineHeight: 1,
					},
					"& .MuiDataGrid-columnHeader--alignRight .MuiDataGrid-columnHeaderTitle":
						{
							textAlign: "right",
						},
				}}
				paginationModel={paginationModel}
				setPaginationModel={setPaginationModel}
				sortModel={sortModel}
				setSortModel={setSortModel}
				isLoading={isLoading || isRefetching || isFetching}
			/>
			<BasicModal
				width={600}
				showModal={showPurchaseOffsets}
				handleClose={() => {
					if ((payments ?? []).filter((p) => p.invoice_url).length > 0) {
						setShowPurchaseOffsets(false);
						navigate("/");
					} else {
						setShowPurchaseOffsets(false);
					}
				}}
				children={
					<PurchaseOffsets
						quantity={Math.ceil(
							Math.abs(
								finalBalance -
									(areForecastedEmissionsToBePaid
										? balance?.estimated_2025_amount ?? 0
										: 0)
							) * 1.1
						)}
						productsCount={0}
						needsFirstTimeFee={false}
						height={"auto"}
						inModal={true}
						closeModal={() => {
							setShowPurchaseOffsets(false);
						}}
						callback={() => {
							enqueueSnackbar("Payment created!", {
								variant: "success",
							});
							refetch();
							if (
								!user?.is_superuser &&
								(user?.email.includes("climeco.com") ||
									new Date().getTime() > new Date(Q2Date).getTime())
							) {
								refetchSubmittedSales();
								refetchMissingSales();
							}
							getBalance();
							refetchPendingPayments();
							getUser();
						}}
						offsetsLoadingMessages={offsetsLoadingMessages}
						messagesIndex={messagesIndex}
						setLoadingOffsetsResult={setLoadingOffsetsResult}
						isForForecasted2025Sales={areForecastedEmissionsToBePaid}
						existingIframeSrc={
							(payments ?? []).filter((p) => p.invoice_url).length > 0
								? ((payments ?? []).find((p) => p.invoice_url) as IPayment)
										.invoice_url
								: undefined
						}
					/>
				}
			/>
			<BasicModal
				width={800}
				showModal={showConfirmSalesModal}
				handleClose={() => {
					setShowConfirmSalesModal(false);
				}}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="h6"
							sx={{ textAlign: "center", marginBottom: 2 }}
						>
							{t("salesReport.confirmSalesTitle")}
						</Typography>
						<Typography
							variant="body1"
							sx={{ textAlign: "center", marginBottom: 2 }}
						>
							{t("salesReport.confirmSalesDescription")}
						</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								variant="outlined"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									marginRight: 2,
								}}
								onClick={() => {
									setShowConfirmSalesModal(false);
								}}
							>
								{t("salesReport.cancel")}
							</Button>
							<Button
								variant="contained"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									minHeight: 34,
								}}
								disabled={isLoadingConfirmSales}
								onClick={() => {
									mutateConfirmSales();
								}}
							>
								{isLoadingConfirmSales ? (
									<CircularProgress size={12} sx={{ color: "white" }} />
								) : (
									t("salesReport.submitSales")
								)}
							</Button>
						</Box>
					</Box>
				}
				hideClose
			/>
			<BasicModal
				width={800}
				showModal={showSubmitSalesModal === true}
				handleClose={() => {
					setShowSubmitSalesModal(false);
				}}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="h6"
							sx={{ textAlign: "center", marginBottom: 2 }}
						>
							{t("salesReport.submitSalesTitle")}
						</Typography>
						<Typography
							variant="body1"
							sx={{ textAlign: "center", marginBottom: 2 }}
						>
							{t("salesReport.submitSalesDescription")}
						</Typography>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								variant="contained"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									marginRight: 2,
								}}
								onClick={() => {
									setShowSubmitSalesModal(false);
								}}
							>
								{t("general.ok")}
							</Button>
						</Box>
					</Box>
				}
				hideClose
			/>
			<BasicModal
				width={800}
				showModal={showPurchaseOffsetsModal}
				handleClose={() => {
					setShowPurchaseOffsetsModal(false);
				}}
				children={
					<PurchaseAgree
						title={t("salesReport.purchaseSummary")}
						text={t("salesReport.agreeText")}
						button1={t("climecoCertificationProcess.cancelButton")}
						button1Callback={() => {
							setShowPurchaseOffsetsModal(false);
						}}
						button2={t("climecoCertificationProcess.agreeButton")}
						button2Callback={() => {
							setShowPurchaseOffsetsModal(false);
							setShowPurchaseOffsets(true);
						}}
						amount={Math.abs(
							finalBalance -
								(areForecastedEmissionsToBePaid
									? balance?.estimated_2025_amount ?? 0
									: 0)
						)}
						balance={0}
					/>
				}
				hideClose
			/>
			<BasicModal
				width={800}
				showModal={showImportFileModal}
				handleClose={() => {
					setShowImportFileModal(false);
				}}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="h6"
							sx={{ textAlign: "center", marginBottom: 2 }}
						>
							{t("salesReport.uploadFile")}
						</Typography>
						<Box sx={{ marginTop: 2, marginBottom: 2 }}>
							<UploadFile
								padding={-1}
								flex={1}
								accept=".csv"
								value={importFile}
								onFileChange={(file) => {
									if (file) {
										setImportFile(file);
									}
								}}
							/>
						</Box>
						<Alert sx={{ marginBottom: 2 }} severity={"error"}>
							{t("bulkProductsTable.uploadInChunks")}
						</Alert>
						<Box
							sx={{
								display: "flex",
								justifyContent: "center",
							}}
						>
							<Button
								variant="outlined"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									marginRight: 2,
								}}
								onClick={() => {
									setImportFile(null);
									setShowImportFileModal(false);
								}}
							>
								{t("salesReport.cancel")}
							</Button>
							<Button
								variant="contained"
								sx={{
									marginTop: 2,
									maxWidth: 300,
									alignSelf: "end",
									minHeight: 34,
								}}
								disabled={importing || !importFile}
								onClick={() => {
									handleImport();
								}}
							>
								{importing ? (
									<CircularProgress size={12} sx={{ color: "white" }} />
								) : (
									t("salesReport.import") ?? "Import"
								)}
							</Button>
						</Box>
					</Box>
				}
				hideClose
			/>
		</Box>
	);
}
