import { useContext, useEffect, useState, useCallback } from "react";
import { PropTypes } from "./BulkProductsTable.types";
import {
	ICompany,
	IProductLCA,
	ICertification,
	IEstimation,
	IProduct,
} from "../../models";
import {
	Box,
	TextField,
	Button,
	IconButton,
	CircularProgress,
	Typography,
	Autocomplete,
	Select,
	MenuItem,
	SelectChangeEvent,
	Tooltip,
} from "@mui/material";
import { UploadFile } from "../UploadFile";
import { NumericFormat } from "react-number-format";
import RemoveIcon from "@mui/icons-material/RemoveCircleOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useMutation, useQuery } from "react-query";
import { useSnackbar } from "notistack";
import { UserContext } from "../../contexts/user";
import { useNavigate } from "react-router-dom";
import {
	product as productActions,
	certification as certificationActions,
	estimation as estimationsActions,
	company as companyActions,
} from "../../controllers";
import { BasicModal } from "../BasicModal";
import { LCARecommendations } from "../LCARecommendations";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Papa from "papaparse";
import RenderIfVisible from "react-render-if-visible";
import { useTranslation } from "react-i18next";

export function BulkProductsTable({
	inModal,
	inModalCallback,
	newIntegration,
	initialProducts,
	setInitialProducts,
	lcaVersion,
	certification,
	setCertification,
	csvLoading,
	setCsvLoading,
	showCSVUploadModal,
	setShowCSVUploadModal,
	handleEvent,
	setHandleEvent,
}: PropTypes) {
	const { t } = useTranslation();
	const [rows, setAuxRows] = useState<Array<IProduct>>(
		initialProducts ?? [
			{
				id: -new Date().getTime(),
				status: "PENDING" as "PENDING",
				name: "",
				description: "",
				asin: "",
				upc: "",
				lca_documents: [
					{
						id: -new Date().getTime(),
						lca_document: undefined,
						certification_date: "",
						lca_provider: "",
						emissions: null,
					},
				],
				product_lca_version_emission: {
					id: -new Date().getTime(),
					lca_version: lcaVersion,
				},
			},
		]
	);
	const setRows = (products: Array<IProduct>) => {
		if (setInitialProducts) {
			setInitialProducts(products);
		}
		setAuxRows(products);
	};
	const { enqueueSnackbar } = useSnackbar();
	const { user } = useContext(UserContext);
	const navigate = useNavigate();
	const [dateType, setDateType] = useState(["text"]);
	const [amazonDateType, setAmazonDateType] = useState(["text"]);
	const [certifying, setCertifying] = useState(false);
	const [companies, setCompanies] = useState<Array<ICompany>>([]);
	const [loading, setLoading] = useState(false);

	const [showLCARecommendations, setLCARecommendations] = useState(false);

	const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

	const ProductStatus = {
		ACTIVE: "ACTIVE",
		PENDING: "PENDING",
		OUTDATED: "OUTDATED",
		INACTIVE: "INACTIVE",
	} as const;

	useEffect(() => {
		setRows([...rows]);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		rows.forEach((row) => {
			if (
				!row.estimations ||
				(row.estimations && row.estimations.length === 0)
			) {
				row.estimations = [
					{
						id: -new Date().getTime(),
						amount: 0,
						product: row,
					},
				];
			}
		});
	}, [rows, certification, setCertification]);

	const handleAddInput = () => {
		setRows([
			...rows,
			{
				id: -new Date().getTime(),
				status: "PENDING",
				name: "",
				description: "",
				asin: "",
				upc: "",
				lca_documents: [
					{
						id: -new Date().getTime(),
						lca_document: undefined,
						certification_date: "",
						lca_provider: "",
						emissions: null,
					},
				],
				brand: "",
				product_lca_version_emission: {
					id: -new Date().getTime(),
					emissions: 0,
					lca_version: lcaVersion,
				},
			},
		]);
		setDateType([...dateType, "text"]);
		setAmazonDateType([...amazonDateType, "text"]);
	};

	const handleRemoveInput = (index: number) => {
		if (rows.length > 1) {
			const newRows = rows.filter((_, rowIndex) => rowIndex !== index);
			setRows(newRows);

			const newDateTypes = dateType.filter((_, dtIndex) => dtIndex !== index);
			setDateType(newDateTypes);

			const newAmazonDateTypes = amazonDateType.filter(
				(_, adtIndex) => adtIndex !== index
			);
			setAmazonDateType(newAmazonDateTypes);
		}
	};

	const handleDuplicateInput = (index: number) => {
		const productToDuplicate = rows[index];

		const newSelectedQuarters = [...selectedQuarters];
		newSelectedQuarters.push(selectedQuarters[index]);
		setSelectedQuarters(newSelectedQuarters);

		const duplicatedProduct = {
			id: -new Date().getTime(),
			status: ProductStatus.PENDING,
			name: productToDuplicate.name,
			description: productToDuplicate.description,
			asin: "",
			upc: "",
			product_lca_version_emission: {
				id: -new Date().getTime(),
				emissions: 0,
				lca_version: lcaVersion,
			},
			lca_documents: [
				{
					id: -new Date().getTime(),
					lca_document:
						productToDuplicate.lca_documents &&
						productToDuplicate.lca_documents.length > 0
							? productToDuplicate.lca_documents[0].lca_document
							: undefined,
					certification_date:
						productToDuplicate.lca_documents &&
						productToDuplicate.lca_documents.length > 0
							? productToDuplicate.lca_documents[0].certification_date
							: "",
					lca_provider:
						productToDuplicate.lca_documents &&
						productToDuplicate.lca_documents.length > 0
							? productToDuplicate.lca_documents[0].lca_provider
							: "",
					emissions: null,
				},
			],
			company_id: productToDuplicate.company_id,
			estimations: [
				{
					id: -new Date().getTime(),
					product: productToDuplicate,
					overwrite_certification_date:
						productToDuplicate &&
						productToDuplicate.estimations &&
						productToDuplicate.estimations[0].overwrite_certification_date,
				},
			],
			brand: productToDuplicate.brand,
		};

		const updatedRows = [...rows];
		updatedRows.splice(index + 1, 0, duplicatedProduct);
		setRows(updatedRows);

		if (
			updatedRows![updatedRows.length - 1].lca_documents![0]
				.certification_date !== ""
		) {
			setDateType((prev) => [...prev, "date"]);
		}
	};

	const { isLoading, mutate } = useMutation(
		"bulk-products",
		({ product }: { product: IProduct }) =>
			productActions.create({
				name: product?.name,
				description: product?.description,
				companyId: user?.is_superuser ? product.company_id : user?.company.id,
				external_id: product?.external_id,
				sku: product?.sku,
				asin: product?.asin,
				ean: product?.ean,
				isbn: product?.isbn,
				upc: product?.upc,
				gtin: product?.gtin,
				websites: product?.websites ?? [""],
				status: user?.is_superuser ? product?.status : undefined,
				brand: product?.brand,
			}),
		{
			retry: false,
		}
	);

	const { isLoading: isLoadingDocuments, mutate: mutateDocuments } =
		useMutation(
			"products-lca-update",
			({
				productLCA,
				productId,
				companyId,
			}: {
				productLCA: IProductLCA;
				productId: number;
				companyId?: number;
			}) =>
				productActions.createLCA({
					companyId: !user?.is_superuser ? user?.company.id : companyId,
					productId: productId,
					emissions: productLCA.emissions!,
					lca_document: productLCA.lca_document!,
					lca_provider: productLCA.lca_provider,
					certification_date: productLCA.certification_date,
				})
		);

	const { mutate: createEstimationMutate, isLoading: isLoadingEstimation } =
		useMutation(
			"start-estimations",
			({
				estimation,
				certification,
			}: {
				estimation: IEstimation;
				certification: ICertification;
			}) =>
				estimationsActions.create({
					amount: estimation.amount,
					product: estimation.product.id,
					certificationProcess: certification!.id,
					companyId: user?.is_superuser
						? estimation.product.company_id
						: user!.company.id,
					overwrite_certification_date: estimation.overwrite_certification_date,
				}),
			{
				retry: false,
			}
		);

	const { mutate: createCertification } = useMutation(
		"certification",
		(_: null) =>
			certificationActions.create({
				isClimeCo: true,
				name: t("bulkProductsTable.new"),
				companyId: user?.is_superuser
					? filteredRows[0].company_id
					: user!.company.id,
				isHistorical:
					!user?.is_superuser && !user?.company.historically_approved,
			}),
		{
			retry: false,
		}
	);

	const filteredRows = rows.filter(
		(r) =>
			r.name &&
			(!user?.company.historically_amazon_certified ||
				(user?.company.historically_amazon_certified && r.asin)) &&
			r.lca_documents &&
			r.lca_documents.length > 0 &&
			r.lca_documents &&
			r.lca_documents.length > 0 &&
			r.lca_documents![0].lca_document &&
			r.lca_documents![0].certification_date &&
			r.lca_documents![0].lca_provider &&
			r.lca_documents![0].emissions
	);

	const bulkProducts = (certify: boolean) => {
		setLoading(true);
		let index = 0;
		enqueueSnackbar(t("bulkProductsTable.processInfo"), {
			variant: "info",
		});
		const createProduct = (product: IProduct, index: number) => {
			mutate(
				{
					product: product,
				},
				{
					onSuccess: async (res) => {
						const auxRows = [...filteredRows];
						auxRows[index].id = res.id;
						setRows(auxRows);
						index += 1;
						await new Promise((resolve, reject) => {
							mutateDocuments(
								{
									productLCA: product.lca_documents![0],
									productId: res.id,
									companyId: user?.is_superuser
										? product.company_id
										: user!.company.id,
								},
								{
									onSuccess: (res) => {
										resolve(true);
									},
									onError: (error: any) => {
										setLoading(false);
										enqueueSnackbar(
											t("bulkProductsTable.errorUpdatingDocuments", {
												productName:
													product.name +
													(product.asin
														? " - " + product.asin
														: product.upc
														? " - " + product.upc
														: product.sku
														? " - " + product.sku
														: ""),
											}),
											{
												variant: "error",
											}
										);
										reject();
									},
								}
							);
						});
						if (index < filteredRows.length) {
							createProduct(filteredRows[index], index);
						} else {
							if (certify) {
								if (
									filteredRows.find(
										(row) =>
											((row as IProduct).certification_processes?.length ?? 0) >
											0
									)
								) {
									setLoading(false);
									enqueueSnackbar(
										t("bulkProductsTable.certificationProcessError"),
										{
											variant: "error",
										}
									);
								} else {
									createCertification(null, {
										onSuccess: (res) => {
											let index = 0;
											const createEstimation = (
												id: number,
												amount: number,
												product: IProduct,
												index: number
											) => {
												createEstimationMutate(
													{
														estimation: {
															id: id,
															amount: amount,
															product: product,
															overwrite_certification_date:
																product?.estimations &&
																product?.estimations.length > 0
																	? product.estimations[0]
																			.overwrite_certification_date
																	: undefined,
														},
														certification: res,
													},
													{
														onSuccess: () => {
															index += 1;
															if (index < filteredRows.length) {
																createEstimation(
																	index,
																	0,
																	filteredRows[index] as IProduct,
																	index
																);
															} else {
																setLoading(false);
																if (
																	!user?.is_superuser &&
																	!user?.company.historically_approved
																) {
																	navigate("/historical-data/" + res.id);
																} else {
																	navigate(
																		"/climeco-certificationss/" + res.id,
																		{
																			state: {
																				previousPage:
																					window.location.href.includes(":3000")
																						? window.location.href.split(
																								":3000"
																						  )[1]
																						: window.location.href.split(
																								".com"
																						  )[1],
																			},
																		}
																	);
																}
															}
														},
														onError: (error) => {
															setLoading(false);
															console.log(error);
															enqueueSnackbar(t("general.errorMessage"), {
																variant: "error",
															});
														},
													}
												);
											};

											createEstimation(
												index,
												0,
												//0,
												filteredRows[index] as IProduct,
												index
											);
										},
										onError: (error) => {
											setLoading(false);
											console.log(error);
											enqueueSnackbar(t("general.errorMessage"), {
												variant: "error",
											});
										},
									});
								}
							} else {
								setLoading(false);
								enqueueSnackbar(t("bulkProductsTable.uploadCSVSuccess"), {
									variant: "success",
								});
								if (inModal) {
									setTimeout(() => {
										inModalCallback && inModalCallback(rows);
									}, 500);
								} else {
									navigate("/products");
								}
							}
						}
					},
					onError: (error: any) => {
						console.log(error);
						if (
							error.response.data.name.length > 0 &&
							error.response.data.name[0] ===
								t("bulkProductsTable.productExistsError")
						) {
							enqueueSnackbar(
								t("bulkProductsTable.recheckProductError", {
									productName: filteredRows[index].name,
								}),
								{
									variant: "error",
								}
							);
						} else {
						}

						index += 1;
						if (index < filteredRows.length) {
							createProduct(filteredRows[index], index);
						} else {
							setCertifying(false);
							setLoading(false);
							enqueueSnackbar(t("bulkProductsTable.uploadCSVSuccess"), {
								variant: "success",
							});
							navigate("/products");
						}
					},
				}
			);
		};

		createProduct(filteredRows[index], index);
	};

	const { refetch: refetchCompanies } = useQuery(
		"companies",
		() => companyActions.list(undefined, undefined, "name", 999),
		{
			enabled: true,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setCompanies(res.results);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), { variant: "error" });
			},
		}
	);

	useEffect(() => {
		if (user?.is_superuser) {
			refetchCompanies();
		}
	}, [refetchCompanies, user?.is_superuser]);

	const generateQuarters = (): string[] => {
		const quarters: string[] = [];
		const currentDate = new Date();
		const currentYear = currentDate.getUTCFullYear();
		const currentQuarter = Math.floor(currentDate.getUTCMonth() / 3) + 1;

		let startYear = 2022;
		let startQuarter = 4;

		for (let year = startYear; year <= currentYear; year++) {
			for (let quarter = startQuarter; quarter <= 4; quarter++) {
				if (year === currentYear && quarter > currentQuarter) {
					break;
				}
				quarters.push(`Q${quarter}-${year}`);
			}
			startQuarter = 1;
		}
		return quarters;
	};

	const quarterToDate = (quarterString: string): string => {
		const [q, year] = quarterString.split("-");
		const month = (parseInt(q[1], 10) - 1) * 3 + 1;
		return `${year}-${month.toString().padStart(2, "0")}-01`;
	};

	const [selectedQuarters, setSelectedQuarters] = useState<string[]>([]);

	const handleSelectChange = (event: SelectChangeEvent, index: number) => {
		const newSelectedQuarters = [...selectedQuarters];
		newSelectedQuarters[index] = event.target.value as string;
		setSelectedQuarters(newSelectedQuarters);
		const date = quarterToDate(event.target.value);
		const auxRows = [...rows];

		auxRows[index].estimations = auxRows[index].estimations ?? [];
		if (auxRows[index].estimations!.length === 0) {
			auxRows[index].estimations!.push({
				id: -new Date().getTime(),
				product: auxRows[index],
				overwrite_certification_date: date,
			});
		} else {
			auxRows[index].estimations![0].overwrite_certification_date = date;
		}
		setRows(auxRows);
	};

	const isValidASIN = (asin: string): boolean => {
		const asinRegex = /^(B[\dA-Z]{9}|\d{9}[\dX])$/;
		return asinRegex.test(asin);
	};

	const sanitizeTitle = (title: string): string => {
		// Normalize accented characters (e.g., á -> a, ç -> c, ñ -> n)
		const normalized = title.normalize("NFD").replace(/[\u0300-\u036f]/g, "");

		// Replace any character that is NOT a letter, number, space, or allowed punctuation with a space
		return normalized.replace(/[^a-zA-Z0-9\s()[\],.?!&]/g, " ");
	};

	const handleFileUpload = useCallback(
		(event: React.ChangeEvent<HTMLInputElement>) => {
			const file = event.target.files?.[0];

			console.log("NOW");
			console.log(file);

			if (file) {
				if (setCsvLoading) {
					setCsvLoading(true);
				}
				let parsedProducts: IProduct[] = [];
				let headersVerified = false;

				Papa.parse(file, {
					header: true,
					worker: true,
					delimiter: ",",
					step: function (results, parser) {
						const parsedCsvData = results.data as any;

						if (
							!parsedCsvData ||
							parsedCsvData["Product Name"] === "\n" ||
							parsedCsvData["Product Name"] === ""
						) {
							return;
						}

						if (!headersVerified) {
							const expectedHeaders = [
								"Product Name",
								"Brand",
								"ASIN",
								"UPC",
								"Estimated Sales",
								"Carbon Footprint (kgCO2eq)",
							];

							const actualHeaders = results.meta.fields || [];

							const headersMatch = expectedHeaders.every((header) =>
								actualHeaders.includes(header)
							);

							if (!headersMatch) {
								enqueueSnackbar(t("bulkProductsTable.csvIncorrect"), {
									variant: "error",
								});
								parser.abort();
								setShowCSVUploadModal && setShowCSVUploadModal(false);
								if (setCsvLoading) {
									setCsvLoading(false);
								}
								return;
							}
							headersVerified = true;
						}

						let wrongLine =
							!parsedCsvData["Product Name"]?.trim() ||
							!parsedCsvData.Brand ||
							isNaN(parseFloat(parsedCsvData["Carbon Footprint (kgCO2eq)"])) ||
							isNaN(parseInt(parsedCsvData["Estimated Sales"]));

						if (wrongLine) {
							enqueueSnackbar(
								t("bulkProductsTable.errors.csvInvalidData", {
									row: parsedProducts.length,
								}),
								{
									variant: "error",
								}
							);
							parser.abort();
							setShowCSVUploadModal && setShowCSVUploadModal(false);
							if (setCsvLoading) {
								setCsvLoading(false);
							}
						} else {
							const row = parsedCsvData;
							const chunkProducts: IProduct[] = [
								{
									id: -(new Date().getTime() + parsedProducts.length),
									status: "PENDING",
									name: sanitizeTitle(row["Product Name"]),
									brand: row.Brand,
									description: row.description || "",
									asin: row.ASIN.trim() !== "" ? row.ASIN.trim() : null,
									upc: row.UPC && row.UPC.trim() !== "" ? row.UPC.trim() : null,
									product_lca_version_emission: {
										id: -(new Date().getTime() + parsedProducts.length),
										emissions:
											parseFloat(row["Carbon Footprint (kgCO2eq)"]) || 0,
										lca_version: lcaVersion,
									},
									estimations: [
										{
											id: -new Date().getTime(),
											amount: parseInt(row["Estimated Sales"]) || 0,
											product: {
												id: -(new Date().getTime() + parsedProducts.length),
												status: "PENDING",
												name: sanitizeTitle(row["Product Name"]),
												brand: row.Brand,
												description: row.description || "",
												asin: row.ASIN,
												upc: row.UPC,
											},
										},
									],
									lca_documents: [],
								},
							];

							parsedProducts = [...parsedProducts, ...chunkProducts];
						}
					},
					complete: function () {
						const updateRowsInChunks = (chunkIndex: number) => {
							const chunkSize = 10;
							const start = chunkIndex * chunkSize;
							const end = start + chunkSize;
							const chunk = parsedProducts.slice(start, end);

							if (chunk.length > 0) {
								setAuxRows((auxRows) => {
									const nonEmptyAuxRows = auxRows.filter((row) => row.name);
									if (setInitialProducts) {
										setInitialProducts([...nonEmptyAuxRows, ...chunk]);
									}
									return [...nonEmptyAuxRows, ...chunk];
								});

								setTimeout(() => updateRowsInChunks(chunkIndex + 1), 500);
							} else {
								setShowCSVUploadModal && setShowCSVUploadModal(false);
								if (setCsvLoading) {
									setShowCSVUploadModal && setShowCSVUploadModal(false);
									setCsvLoading(false);
								}
								setTimeout(() => {
									const productsContainer =
										document.querySelector("#process-container");
									if (productsContainer) {
										productsContainer.scrollTop = 0;
									}
								}, 500);
								console.log("All rows processed and loaded in chunks.");
							}
						};

						updateRowsInChunks(0);
					},
					error: function (error) {
						setShowCSVUploadModal && setShowCSVUploadModal(false);
						if (setCsvLoading) {
							setCsvLoading(false);
						}
						enqueueSnackbar(t("bulkProductsTable.uploadCSVError"), {
							variant: "error",
						});
						console.error("Error parsing CSV: ", error);
					},
				});
			}
		},
		[
			enqueueSnackbar,
			lcaVersion,
			setCsvLoading,
			setInitialProducts,
			setShowCSVUploadModal,
			t,
		]
	);

	useEffect(() => {
		if (handleEvent && setHandleEvent) {
			setHandleEvent(null);
			console.log("handleEvent");
			console.log(handleEvent);
			handleFileUpload(handleEvent);
		}
	}, [handleEvent, handleFileUpload, setHandleEvent]);

	return (
		<Box
			sx={{
				height: "auto",
				maxHeight: "100%",
				width: "100%",
				gap: 2,
			}}
		>
			{!newIntegration && (
				<Box
					sx={{
						marginBottom: 2,
						minHeight: 34,
						minWidth: 120,
						display: "flex",
						justifyContent: "flex-end",
						gap: 2,
					}}
				>
					{(user?.is_superuser ||
						(!user?.is_superuser && user?.company.historically_approved) ||
						inModal) && (
						<Button
							variant="contained"
							onClick={() => {
								bulkProducts(false);
							}}
							disabled={
								rows.find((r, i) => {
									return (
										(!r.name ||
											(r.upc && r.upc.length !== 12) ||
											!r.brand ||
											!r.lca_documents![0].lca_document ||
											!r.lca_documents![0].certification_date ||
											(!!r.lca_documents![0].certification_date &&
												(new Date(
													r.lca_documents![0].certification_date
												).getFullYear() < 2021 ||
													new Date(
														r.lca_documents![0].certification_date
													).getFullYear() > new Date().getFullYear())) ||
											!r.lca_documents![0].lca_provider ||
											!r.lca_documents![0].emissions) &&
										!(
											!r.name &&
											r.upc &&
											r.upc.length !== 12 &&
											!r.brand &&
											(!r.lca_documents![0].lca_document ||
												(!!r.lca_documents![0].certification_date &&
													(new Date(
														r.lca_documents![0].certification_date
													).getFullYear() < 2021 ||
														new Date(
															r.lca_documents![0].certification_date
														).getFullYear() > new Date().getFullYear()))) &&
											!r.lca_documents![0].certification_date &&
											!r.lca_documents![0].lca_provider &&
											!r.lca_documents![0].emissions &&
											i > 0
										)
									);
								}) ||
								rows.filter((r) => r.asin).map((r) => r.asin).length !==
									new Set(rows.filter((r) => r.asin).map((r) => r.asin)).size ||
								rows.filter((r) => r.asin && !isValidASIN(r.asin)).length > 0 ||
								isLoading ||
								isLoadingDocuments ||
								isLoadingEstimation ||
								loading
									? true
									: false
							}
						>
							{!certifying &&
							(isLoading ||
								isLoadingDocuments ||
								isLoadingEstimation ||
								loading) ? (
								<CircularProgress size={12} sx={{ color: "white" }} />
							) : (
								t("bulkProductsTable.save")
							)}
						</Button>
					)}
					{!inModal && (
						<>
							<Button
								variant="contained"
								onClick={() => {
									setCertifying(true);
									bulkProducts(true);
								}}
								disabled={
									rows.find((r, i) => {
										return (
											(!r.name ||
												!r.brand ||
												!r.lca_documents![0].lca_document ||
												!r.lca_documents![0].certification_date ||
												(!!r.lca_documents![0].certification_date &&
													(new Date(
														r.lca_documents![0].certification_date
													).getFullYear() < 2021 ||
														new Date(
															r.lca_documents![0].certification_date
														).getFullYear() > new Date().getFullYear())) ||
												!r.lca_documents![0].lca_provider ||
												!r.lca_documents![0].emissions ||
												(!r.estimations &&
													!user?.company.historically_approved) ||
												(!r.company_id && user?.is_superuser)) &&
											!(
												!r.name &&
												!r.brand &&
												!r.lca_documents![0].lca_document &&
												(!r.lca_documents![0].lca_document ||
													(!!r.lca_documents![0].certification_date &&
														(new Date(
															r.lca_documents![0].certification_date
														).getFullYear() < 2021 ||
															new Date(
																r.lca_documents![0].certification_date
															).getFullYear() > new Date().getFullYear()))) &&
												!r.lca_documents![0].lca_provider &&
												!r.lca_documents![0].emissions &&
												(user?.company.historically_approved ||
													(!r.estimations &&
														!user?.company.historically_approved)) &&
												(!user?.is_superuser ||
													(!r.company_id && user?.is_superuser)) &&
												i > 0
											)
										);
									}) ||
									rows.filter((r) => r.asin).map((r) => r.asin).length !==
										new Set(rows.filter((r) => r.asin).map((r) => r.asin))
											.size ||
									rows.find((r, i) => {
										return rows.find((r2) => r2.company_id !== r.company_id);
									}) ||
									rows
										.filter((r) => r.asin)
										.find((r, i) => {
											return rows
												.filter((r) => r.asin)
												.find((r2, j) => i !== j && r2.asin === r.asin);
										}) ||
									isLoading ||
									isLoadingDocuments ||
									isLoadingEstimation ||
									loading
										? true
										: false
								}
							>
								{certifying &&
								(isLoading ||
									isLoadingDocuments ||
									isLoadingEstimation ||
									loading) ? (
									<CircularProgress size={12} sx={{ color: "white" }} />
								) : (
									t("bulkProductsTable.saveAndCertify")
								)}
							</Button>
						</>
					)}
				</Box>
			)}

			<Box
				sx={{
					maxHeight: lcaVersion ? undefined : "calc(100vh - 300px)",
					overflow: "auto",
					gap: 2,
					display: "flex",
					flexDirection: "column",
					marginTop: 3,
				}}
			>
				{rows.length > 1 && (
					<Box sx={{ display: "flex", justifyContent: "flex-end" }}>
						<Button onClick={() => setShowDeleteConfirmation(true)}>
							{t("bulkProductsTable.deleteAllProducts")}
						</Button>
					</Box>
				)}
				{rows.map((row, index) => (
					<RenderIfVisible
						defaultHeight={292.375}
						visibleOffset={1000}
						key={row.id}
					>
						<Box
							key={row.id}
							sx={{
								border: "1px solid #B1D1F0",
								borderRadius: "12px",
								opacity:
									certification?.is_add_products &&
									(row?.product_lca_version_emissions ?? []).find(
										(e) => e.id > 0
									) !== undefined
										? 0.5
										: 1,
							}}
						>
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									justifyContent: "flex-start",
									alignItems: "flex-start",
									gap: 2,
									padding: 2,
								}}
							>
								<Box
									sx={{
										display: "flex",
										flexDirection: "row",
										justifyContent: "flex-start",
										alignItems: "flex-start",
										flex: 1,
										width: "100%",
									}}
								>
									<Typography
										variant="body2"
										sx={{
											fontSize: "14px",
											color: "#757575",
										}}
									>
										{(index + 1).toString().padStart(2, "0")}
									</Typography>
									<Box
										sx={{
											position: "relative",
											width: "100%",
											minWidth: 200,
											marginLeft: "12px",
										}}
									>
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
												justifyContent: "flex-start",
												alignItems: "flex-start",
												flex: 1,
												width: "100%",
											}}
										>
											<span
												style={{
													textTransform: "capitalize",
													fontSize: 14,
													fontWeight: 700,
													marginBottom: "10px",
												}}
											>
												{t("bulkProductsTable.productName")}
												<span style={{ color: "#B61313" }}>*</span>
											</span>

											<TextField
												sx={{
													display: "flex",
													alignSelf: "start",
													minWidth: 200,
													width: "100%",
													"& input": {
														borderColor: "#C9C9C9",
													},
												}}
												placeholder={t("bulkProductsTable.enterProductName")}
												InputProps={{
													sx: {},
												}}
												inputProps={{}}
												value={row.name}
												onChange={(event) => {
													const auxRows = [...rows];
													auxRows[index].name = sanitizeTitle(
														event.target.value
													);
													setRows(auxRows);
												}}
												disabled={
													(certification?.is_add_products &&
														(row?.product_lca_version_emissions ?? []).find(
															(e) => e.id > 0
														) !== undefined) ||
													lcaVersion?.status === "ACTIVE" /*||
													certification?.is_update_lca*/
												}
											/>
										</Box>
									</Box>
								</Box>
								<Box
									sx={{
										flex: 1,
										marginLeft: "26px",
										width: "calc(100% - 26px)",
									}}
								>
									{!newIntegration && (
										<>
											<TextField
												placeholder={t("bulkProductsTable.brand")}
												sx={{
													display: "flex",
													alignSelf: "start",
													marginTop: 2,
													minWidth: 200,
												}}
												value={row.brand}
												onChange={(event) => {
													const auxRows = [...rows];
													auxRows[index].brand = event.target.value;
													setRows(auxRows);
												}}
											/>
											{user?.is_superuser && (
												<Autocomplete
													disablePortal
													id="companies"
													options={companies}
													value={
														companies.find(
															(company) => company.id === row.company_id
														) || null
													}
													getOptionKey={(option) => option?.id ?? ""}
													getOptionLabel={(option) => option.name}
													renderOption={(props, option) => {
														return (
															<li
																{...props}
																key={option.id}
																style={{ minWidth: 200 }}
															>
																{option.name}
															</li>
														);
													}}
													onChange={(_, value) => {
														if (value) {
															const companyId = value.id;
															const auxRows = [...rows];
															auxRows[index].company_id = companyId;
															setRows(auxRows);
														}
													}}
													renderInput={(params) => (
														<TextField
															sx={{
																display: "flex",
																alignSelf: "start",
																marginTop: 2,
															}}
															placeholder={t("bulkProductsTable.company")}
															{...params}
															variant="outlined"
														/>
													)}
													disabled={
														(certification?.is_add_products &&
															(row?.product_lca_version_emissions ?? [])
																.length > 0) ||
														lcaVersion?.status === "ACTIVE"
													}
												/>
											)}
										</>
									)}

									{newIntegration ? (
										<Box
											sx={{
												display: "flex",
												flexDirection: { xs: "column", md: "row" },
												gap: 2,
												flex: 1,
											}}
										>
											<Box
												sx={{
													display: "flex",
													flexDirection: "column",
													justifyContent: "flex-start",
													alignItems: "flex-start",
													flex: 1,
												}}
											>
												<span
													style={{
														textTransform: "capitalize",
														fontSize: 14,
														fontWeight: 700,
														marginBottom: "10px",
													}}
												>
													{t("bulkProductsTable.brand")}
													<span style={{ color: "#B61313" }}>*</span>
												</span>
												<TextField
													placeholder={t("bulkProductsTable.enterBrand")}
													sx={{
														display: "flex",
														alignSelf: "start",
														minWidth: 200,
														flexGrow: 1,
														flexBasis: 0,
														width: "100%",
													}}
													value={row.brand}
													onChange={(event) => {
														const auxRows = [...rows];
														auxRows[index].brand = event.target.value;
														setRows(auxRows);
													}}
													disabled={
														(certification?.is_add_products &&
															(row?.product_lca_version_emissions ?? [])
																.length > 0) ||
														lcaVersion?.status === "ACTIVE" ||
														certification?.is_update_lca
													}
												/>
											</Box>
											<Box
												sx={{
													display: "flex",
													flexDirection: "column",
													justifyContent: "flex-start",
													alignItems: "flex-start",
													flex: 1,
												}}
											>
												<Tooltip
													title={t("bulkProductsTable.amazonStandardId")}
												>
													<Box sx={{ display: "flex", marginBottom: "10px" }}>
														<span
															style={{
																textTransform: "capitalize",
																fontSize: 14,
																fontWeight: 700,
																marginRight: 2,
															}}
														>
															{t("bulkProductsTable.asin")}
														</span>
														<InfoOutlinedIcon
															sx={{ fontSize: "12px", color: "#525252" }}
														/>
													</Box>
												</Tooltip>
												<TextField
													placeholder={t("bulkProductsTable.enterASIN")}
													sx={{
														display: "flex",
														alignSelf: "start",
														minWidth: 100,
														width: "100%",
														flexGrow: 1,
														flexBasis: 0,
														borderRadius: "8px",
														"& .MuiInputBase-input": {
															border:
																row.asin &&
																row.asin.length > 0 &&
																!isValidASIN(row.asin)
																	? " 1px solid #eb4343"
																	: undefined,
															borderRadius: "8px",
															padding: "11.5px 14px",
														},
														"& .MuiOutlinedInput-notchedOutline": {
															border: "none",
															borderRadius: "8px",
														},
													}}
													value={row.asin}
													onChange={(event) => {
														const auxRows = [...rows];
														auxRows[index].asin = event.target.value;
														setRows(auxRows);
													}}
													disabled={
														(certification?.is_add_products &&
															(row?.product_lca_version_emissions ?? [])
																.length > 0) ||
														lcaVersion?.status === "ACTIVE" ||
														certification?.is_update_lca
													}
												/>
											</Box>
											<Box
												sx={{
													display: "flex",
													flexDirection: "column",
													justifyContent: "flex-start",
													alignItems: "flex-start",
													flex: 1,
												}}
											>
												<span
													style={{
														textTransform: "capitalize",
														fontSize: 14,
														fontWeight: 700,
														marginBottom: "10px",
													}}
												>
													{t("bulkProductsTable.upc")}
												</span>
												<TextField
													placeholder={t("bulkProductsTable.enterUPC")}
													sx={{
														display: "flex",
														alignSelf: "start",
														minWidth: 100,
														flexGrow: 1,
														flexBasis: 0,
														width: "100%",
														borderRadius: "8px",
														"& .MuiInputBase-input": {
															border:
																row.upc && row.upc.length < 12
																	? "1px solid #eb4343"
																	: undefined,
															borderRadius: "8px",
															padding: "11.5px 14px",
														},
														"& .MuiOutlinedInput-notchedOutline": {
															border: "none",
															borderRadius: "8px",
														},
													}}
													type="text"
													inputProps={{
														inputMode: "numeric",
														pattern: "/^-?d+(?:.d+)?$/g",
													}}
													value={row.upc}
													onChange={(event) => {
														const value = event.target.value;
														const isNumeric = /^[0-9]*$/.test(value);
														if (isNumeric && value.length <= 12) {
															const auxRows = [...rows];

															auxRows[index].upc = event.target.value;
															setRows(auxRows);
														}
													}}
													disabled={
														(certification?.is_add_products &&
															(row?.product_lca_version_emissions ?? [])
																.length > 0) ||
														lcaVersion?.status === "ACTIVE" ||
														certification?.is_update_lca
													}
												/>
											</Box>
										</Box>
									) : (
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
												gap: 2,
												marginTop: 2,
												marginBottom: 2,
												alignSelf: "start",
												flex: 1,
											}}
										>
											<Box
												sx={{
													display: "flex",
													flexDirection: "row",
													gap: 2,
													flex: 1,
												}}
											>
												<UploadFile
													padding={-1}
													flex={1}
													accept=".pdf,.docx,.doc"
													value={row.lca_documents![0].lca_document}
													onFileChange={(file) => {
														if (file) {
															const auxRows = [...rows];
															auxRows[index].lca_documents![0].lca_document =
																file;
															setRows(auxRows);
														}
													}}
													disabled={
														(certification?.is_add_products &&
															(row?.product_lca_version_emissions ?? [])
																.length > 0) ||
														lcaVersion?.status === "ACTIVE"
													}
												/>
												<TextField
													placeholder={t("bulkProductsTable.lcaCompletionDate")}
													type={dateType[index]}
													variant="outlined"
													InputProps={{
														inputProps: {
															max: `${new Date().toISOString().split("T")[0]}`,
															style: { minWidth: "150px" },
														},
													}}
													sx={{
														flex: 1,
														minWidth: "150px",
													}}
													value={row.lca_documents![0].certification_date}
													onChange={(event) => {
														const auxRows = [...rows];
														auxRows[
															index
														].lca_documents![0].certification_date =
															event.target.value;
														setRows(auxRows);
													}}
													onFocus={() => {
														const auxDateType = [...dateType];
														auxDateType[index] = "date";
														setDateType(auxDateType);
													}}
													onBlur={() => {
														if (!row.lca_documents![0].certification_date) {
															const auxDateType = [...dateType];
															auxDateType[index] = "text";
															setDateType(auxDateType);
														}
													}}
													error={
														!!row.lca_documents![0].certification_date &&
														(new Date(
															row.lca_documents![0].certification_date
														).getFullYear() < 2021 ||
															new Date(
																row.lca_documents![0].certification_date
															).getFullYear() > new Date().getFullYear())
													}
													helperText={
														!!row.lca_documents![0].certification_date &&
														(new Date(
															row.lca_documents![0].certification_date
														).getFullYear() < 2021 ||
															new Date(
																row.lca_documents![0].certification_date
															).getFullYear() > new Date().getFullYear())
															? t("bulkProductsTable.invalidDateRange")
															: ""
													}
													disabled={
														(certification?.is_add_products &&
															(row?.product_lca_version_emissions ?? [])
																.length > 0) ||
														lcaVersion?.status === "ACTIVE"
													}
												/>
											</Box>
											<Box
												sx={{
													display: "flex",
													flexDirection: "row",
													gap: 2,
													flex: 1,
												}}
											>
												<Autocomplete
													freeSolo
													options={[
														"Planet FWD",
														"WAP Sustainability",
														"Carbon Pass",
														"Sustainabase",
														"Aspire Sustainability",
														"Zero Carbon Industry Operations Center (Shenzhen) Co., Ltd",
														"Shenzhen Zhongrui Standard Technical Services Co., Ltd",
														"Carbon Insight",
													]}
													value={row.lca_documents![0].lca_provider || ""}
													onChange={(event, newValue) => {
														let providerValue;
														if (typeof newValue === "string") {
															providerValue = newValue;
														} else {
															providerValue = newValue ? newValue : "";
														}

														const auxRows = [...rows];
														auxRows[index].lca_documents![0].lca_provider =
															providerValue;
														setRows(auxRows);
													}}
													renderInput={(params) => (
														<TextField
															{...params}
															label=""
															placeholder={t("bulkProductsTable.lcaProvider")}
															onChange={(event) => {
																const auxRows = [...rows];
																auxRows[index].lca_documents![0].lca_provider =
																	event.target.value;
																setRows(auxRows);
															}}
														/>
													)}
													sx={{ flex: 1 }}
													disabled={
														(certification?.is_add_products &&
															(row?.product_lca_version_emissions ?? [])
																.length > 0) ||
														lcaVersion?.status === "ACTIVE"
													}
												/>
												<NumericFormat
													name={"amount"}
													placeholder={t(
														"bulkProductsTable.productCarbonFootprintValue"
													)}
													customInput={TextField}
													type="text"
													thousandSeparator={","}
													suffix={" kg of CO2e"}
													allowNegative={false}
													onFocus={(e) => {}}
													onBlur={() => {}}
													value={row.lca_documents![0].emissions}
													onValueChange={({ value }) => {
														const auxRows = [...rows];
														auxRows[index].lca_documents![0].emissions =
															parseFloat(value);
														setRows(auxRows);
													}}
													style={{
														flex: 1,
														minWidth: "140px",
													}}
													disabled={
														(certification?.is_add_products &&
															(row?.product_lca_version_emissions ?? [])
																.length > 0) ||
														lcaVersion?.status === "ACTIVE"
													}
												/>
											</Box>
										</Box>
									)}

									{newIntegration ? (
										<></>
									) : (
										<Box
											sx={{
												display: "flex",
												flexDirection: "column",
												gap: 2,
												marginBottom: 2,
												alignSelf: "start",
												flex: 1,
											}}
										>
											<Box
												sx={{
													display: "flex",
													flex: 1,
												}}
											>
												{user?.company.historically_amazon_certified ? (
													<TextField
														placeholder={t("bulkProductsTable.asin")}
														sx={{
															display: "flex",
															alignSelf: "start",
															marginTop: 2,
															minWidth: 100,
															width: "100%",
															borderRadius: "8px",
															"& .MuiInputBase-input": {
																border:
																	row.upc && row.upc.length < 12
																		? "1px solid #eb4343"
																		: undefined,
																borderRadius: "8px",
																padding: "11.5px 14px",
															},
															"& .MuiOutlinedInput-notchedOutline": {
																border: "none",
																borderRadius: "8px",
															},
														}}
														value={row.asin}
														onChange={(event) => {
															const auxRows = [...rows];
															auxRows[index].asin = event.target.value;
															setRows(auxRows);
														}}
													/>
												) : (
													<TextField
														placeholder={t("bulkProductsTable.upc")}
														sx={{
															display: "flex",
															alignSelf: "start",
															marginTop: 2,
															minWidth: 100,
															width: "100%",
															borderRadius: "8px",
															"& .MuiInputBase-input": {
																border:
																	row.upc && row.upc.length < 12
																		? "1px solid #eb4343"
																		: undefined,
																borderRadius: "8px",
																padding: "11.5px 14px",
															},
															"& .MuiOutlinedInput-notchedOutline": {
																border: "none",
																borderRadius: "8px",
															},
														}}
														type="text"
														inputProps={{
															inputMode: "numeric",
															pattern: "/^-?d+(?:.d+)?$/g",
														}}
														value={row.upc}
														onChange={(event) => {
															const value = event.target.value;
															const isNumeric = /^[0-9]*$/.test(value);
															if (isNumeric && value.length <= 12) {
																const auxRows = [...rows];

																auxRows[index].upc = event.target.value;
																setRows(auxRows);
															}
														}}
													/>
												)}
											</Box>
											{!user?.company.historically_approved && (
												<Box
													sx={{
														display: "flex",
														flex: 1,
													}}
												>
													<Select
														variant="outlined"
														placeholder={
															user?.company.historically_amazon_certified
																? t("bulkProductsTable.amazonCertificationDate")
																: t("bulkProductsTable.certificationDate")
														}
														value={selectedQuarters[index] || ""}
														onChange={(event) =>
															handleSelectChange(event, index)
														}
														displayEmpty
														inputProps={{ "aria-label": "Without label" }}
														sx={{
															flex: 1,
															backgroundColor: "#fafafa",
															"& .MuiSelect-select": {
																color: selectedQuarters[index]
																	? "rgba(0, 0, 0, 0.87)"
																	: "rgba(0, 0, 0, 0.54)",
															},
															"& fieldset": {
																border: "none",
															},
														}}
													>
														<MenuItem
															value=""
															disabled
															style={{ color: "rgba(0, 0, 0, 0.54)" }}
														>
															{user?.company.historically_amazon_certified
																? t("bulkProductsTable.amazonCertificationDate")
																: t("bulkProductsTable.certificationDate")}
														</MenuItem>
														{generateQuarters().map((quarter) => (
															<MenuItem key={quarter} value={quarter}>
																{quarter}
															</MenuItem>
														))}
													</Select>
												</Box>
											)}
										</Box>
									)}

									{newIntegration && <></>}
									{newIntegration && (
										<Box
											sx={{
												display: "flex",
												flexDirection: { xs: "column", md: "row" },
												gap: 2,
												flex: 1,
												marginTop: 3,
											}}
										>
											<Box
												sx={{
													display: "flex",
													flexDirection: "column",
													justifyContent: "flex-start",
													alignItems: "flex-start",
													flex: 1,
												}}
											>
												<span
													style={{
														textTransform: "capitalize",
														fontSize: 14,
														fontWeight: 700,
														textAlign: "left",
														padding: 0,
														margin: 0,
														marginBottom: "6px",
													}}
												>
													<Typography
														variant="body1"
														sx={{
															textTransform: "capitalize",
															fontSize: 14,
															fontWeight: 700,
														}}
													>
														{t("bulkProductsTable.estimatedSales")}{" "}
														<Typography
															component="span"
															variant="caption"
															fontSize={10}
														>
															{t("bulkProductsTable.remainderOfTheYear")}
														</Typography>
														<span style={{ color: "#B61313" }}>*</span>
													</Typography>
												</span>
												<NumericFormat
													value={
														(row.estimations ?? []).length > 0
															? row.estimations![0].amount
															: undefined
													}
													sx={{ width: "100%" }}
													InputProps={{
														classes: {
															notchedOutline: "none",
														},
														inputProps: {
															style: {
																border: "1px solid #C9C9C9",
																borderRadius: 8,
															},
														},
													}}
													name={"amount"}
													customInput={TextField}
													type="text"
													allowNegative={false}
													decimalScale={0}
													thousandSeparator={","}
													onValueChange={({ value: v }) => {
														const auxRows = [...rows];
														if (
															!auxRows[index].estimations ||
															(auxRows[index].estimations &&
																auxRows[index].estimations?.length === 0)
														) {
															auxRows[index].estimations = [
																{
																	id: -new Date().getTime(),
																	amount: v ? parseInt(v) : undefined,
																	product: row,
																},
															];
														} else {
															auxRows[index].estimations![0].amount = v
																? parseInt(v)
																: undefined;
														}
														setRows(auxRows);
													}}
													placeholder={t("bulkProductsTable.estimatedSales")}
													disabled={
														(certification?.is_add_products &&
															(row?.product_lca_version_emissions ?? []).find(
																(e) => e.id > 0
															) !== undefined) ||
														lcaVersion?.status === "ACTIVE" ||
														certification?.is_update_lca
													}
												/>
											</Box>
											<Box
												sx={{
													display: "flex",
													flexDirection: "column",
													justifyContent: "flex-start",
													alignItems: "flex-start",
													flex: 1,
												}}
											>
												<span
													style={{
														textTransform: "capitalize",
														fontSize: 14,
														fontWeight: 700,
														marginBottom: "10px",
													}}
												>
													{t("bulkProductsTable.carbonFootprint")}
													<span style={{ color: "#B61313" }}>*</span>
												</span>
												<NumericFormat
													name={"amount"}
													placeholder={t(
														"bulkProductsTable.productCarbonFootprintValue"
													)}
													customInput={TextField}
													type="text"
													thousandSeparator={","}
													suffix={" kgCO2 eq"}
													allowNegative={false}
													onFocus={(e) => {}}
													onBlur={() => {}}
													value={row.product_lca_version_emission?.emissions}
													onValueChange={({ value }) => {
														const auxRows = [...rows];
														let productLcaVersionEmission = auxRows[index]
															.product_lca_version_emission ?? {
															id: -new Date().getTime(),
															emissions: 0,
														};
														productLcaVersionEmission.emissions =
															parseFloat(value);
														auxRows[index].product_lca_version_emission =
															productLcaVersionEmission;

														setRows(auxRows);
													}}
													style={{
														flex: 1,
														minWidth: "140px",
														width: "100%",
													}}
													disabled={
														(certification?.is_add_products &&
															(row?.product_lca_version_emissions ?? []).find(
																(e) => e.id > 0
															) !== undefined) ||
														lcaVersion?.status === "ACTIVE"
													}
												/>
											</Box>
											<Box
												sx={{
													display: "flex",
													flexDirection: "column",
													justifyContent: "flex-start",
													alignItems: "flex-start",
													flex: 1,
												}}
											>
												<span
													style={{
														textTransform: "capitalize",
														fontSize: 14,
														fontWeight: 700,
														marginBottom: "10px",
													}}
												>
													{t("bulkProductsTable.offsetsNeeded")}
													<span style={{ color: "#B61313" }}>*</span>
												</span>
												<NumericFormat
													disabled
													name={"amount"}
													placeholder={t(
														"bulkProductsTable.productCarbonFootprintValue"
													)}
													customInput={TextField}
													type="text"
													thousandSeparator={","}
													allowNegative={false}
													onFocus={(e) => {}}
													onBlur={() => {}}
													value={Math.ceil(
														(((row.estimations ?? []).length > 0
															? row.estimations![0].amount ?? 0
															: 0) *
															(row.product_lca_version_emission?.emissions ??
																0)) /
															1000
													)}
													style={{
														flex: 1,
														minWidth: "140px",
														width: "100%",
													}}
													InputProps={{
														classes: {
															notchedOutline: "none",
														},
														inputProps: {
															style: {
																border: "none",
															},
														},
													}}
												/>
											</Box>
										</Box>
									)}
								</Box>
							</Box>
							<Box
								key={row.id}
								display="flex"
								alignItems="center"
								justifyContent="flex-end"
								gap={2}
								sx={{
									borderTop: "1px solid #B1D1F0",
									padding: 2,
								}}
							>
								<Box sx={{ display: "flex", gap: 2, marginRight: 1 }}>
									<Tooltip
										sx={{ padding: 0 }}
										title={t("bulkProductsTable.duplicateProductTooltip")}
									>
										<IconButton
											color="primary"
											onClick={() => handleDuplicateInput(index)}
											disabled={
												certification?.is_update_lca ||
												lcaVersion?.status === "ACTIVE"
											}
										>
											<ContentCopyIcon
												sx={{ fontSize: 20, color: "#34918E" }}
											/>
										</IconButton>
									</Tooltip>
									<Tooltip
										sx={{ padding: 0 }}
										title={t("bulkProductsTable.removeProductTooltip")}
									>
										<IconButton
											color="primary"
											onClick={() => handleRemoveInput(index)}
											disabled={
												rows.length === 1 ||
												(certification?.is_add_products &&
													(row?.product_lca_version_emissions ?? []).length >
														0) ||
												certification?.is_update_lca ||
												lcaVersion?.status === "ACTIVE"
											}
										>
											<RemoveIcon sx={{ fontSize: 20, color: "#C13333" }} />
										</IconButton>
									</Tooltip>
								</Box>
							</Box>
						</Box>
					</RenderIfVisible>
				))}
			</Box>
			{!certification?.is_update_lca && (
				<Box
					sx={{
						display: "flex",
						marginTop: 4,
						marginBottom: 2,
						borderTop: "1px solid #B1D1F0",
						borderBottom: "1px solid #B1D1F0",
						paddingTop: 1,
						paddingBottom: 1,
						alignItems: "center",
						flexDirection: { xs: "column", md: "row" },
						justifyContent: "flex-end",
					}}
				>
					<Box
						sx={{
							display: "flex",
							alignItems: "center",
							justifyContent: "flex-end",
							gap: 2,
						}}
					>
						<Button
							variant="outlined"
							disabled={rows.length >= 1000}
							sx={{
								"&.Mui-disabled": {
									backgroundColor: "#fff",
									opacity: 0.4,
								},
								borderRadius: 2,
								maxWidth: 200,
							}}
							onClick={handleAddInput}
						>
							{t("bulkProductsTable.addNewProduct")}
						</Button>
					</Box>
				</Box>
			)}

			<BasicModal
				width={800}
				showModal={showLCARecommendations}
				handleClose={() => {
					setLCARecommendations(false);
				}}
				children={
					<LCARecommendations setLCARecommendations={setLCARecommendations} />
				}
				hideClose
			/>

			<BasicModal
				width={800}
				showModal={showCSVUploadModal ? showCSVUploadModal : false}
				handleClose={() => {
					setShowCSVUploadModal && setShowCSVUploadModal(false);
				}}
				children={
					<Box
						sx={{
							width: "100%",
							textAlign: "center",
							padding: 4,
							display: "flex",
							flexDirection: "column",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<Typography
							variant="h5"
							sx={{ fontWeight: "bold", marginBottom: 2, maxWidth: 500 }}
						>
							{t("bulkProductsTable.uploadYourProductsInBulk")}
						</Typography>
						<Typography variant="body1" sx={{ marginBottom: 4, maxWidth: 500 }}>
							{t("bulkProductsTable.saveYourExcelAsCSV")}
						</Typography>
						<label htmlFor="upload-csv">
							<input
								accept=".csv"
								style={{ display: "none" }}
								id="upload-csv"
								type="file"
								onChange={handleFileUpload}
							/>
							<Button
								variant="outlined"
								component="span"
								sx={{
									"&.Mui-disabled": {
										backgroundColor: "#fff",
										opacity: 0.4,
									},
									borderRadius: 2,
									maxWidth: 200,
									minHeight: 34,
									minWidth: 102,
								}}
								disabled={csvLoading}
							>
								{csvLoading ? (
									<CircularProgress size={12} sx={{ color: "#34918E" }} />
								) : (
									t("bulkProductsTable.uploadCSV")
								)}
							</Button>
						</label>
					</Box>
				}
			/>

			<BasicModal
				width={500}
				showModal={showDeleteConfirmation}
				handleClose={() => setShowDeleteConfirmation(false)}
			>
				<Box
					sx={{
						width: "100%",
						textAlign: "center",
						padding: 4,
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center",
					}}
				>
					<Typography variant="h6" sx={{ fontWeight: "bold", marginBottom: 2 }}>
						{t("bulkProductsTable.confirmDeleteTitle")}
					</Typography>
					<Typography variant="body1" sx={{ marginBottom: 4 }}>
						{t("bulkProductsTable.confirmDeleteMessage")}
					</Typography>
					<Box sx={{ display: "flex", gap: 2 }}>
						<Button
							variant="outlined"
							onClick={() => setShowDeleteConfirmation(false)}
						>
							{t("bulkProductsTable.confirmDeleteCancel")}
						</Button>
						<Button
							variant="contained"
							color="error"
							onClick={() => {
								setRows([
									{
										id: -new Date().getTime(),
										status: "PENDING",
										name: "",
										description: "",
										asin: "",
										upc: "",
										lca_documents: [
											{
												id: -new Date().getTime(),
												lca_document: undefined,
												certification_date: "",
												lca_provider: "",
												emissions: null,
											},
										],
										product_lca_version_emission: {
											id: -new Date().getTime(),
											emissions: 0,
											lca_version: lcaVersion,
										},
									},
								]);
								setShowDeleteConfirmation(false);
							}}
						>
							{t("bulkProductsTable.confirmDeleteConfirm")}
						</Button>
					</Box>
				</Box>
			</BasicModal>
		</Box>
	);
}
