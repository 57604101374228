import { useContext } from "react";
import { Box } from "@mui/material";
import {
	AppContent,
	Auth,
	FormDisplay,
	OverlayLoading,
} from "../../components";
import { PropTypes } from "./MainLayout.types";
import { UserContext } from "../../contexts/user";
import { BrowserRouter, Route, Routes } from "react-router-dom";

export function MainLayout(props: PropTypes) {
	const { user, isLoading } = useContext(UserContext);

	return (
		<Box sx={{ display: "flex" }}>
			{isLoading ? (
				<OverlayLoading />
			) : user ? (
				<AppContent />
			) : window.location.href.includes("form-display") ? (
				<BrowserRouter>
					<Routes>
						<Route
							path="companies/:companyId/form-display/:id"
							element={<FormDisplay />}
						/>
					</Routes>
				</BrowserRouter>
			) : (
				<Auth />
			)}
		</Box>
	);
}
