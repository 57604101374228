import { useState, useEffect } from "react";
import { Box, Button, IconButton, Tooltip } from "@mui/material";
import DataTable from "../DataTable/DataTable";
import { Heading } from "../Heading";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
	GridRenderCellParams,
	GridSortItem,
	GridSortModel,
} from "@mui/x-data-grid";
import { useQuery, useMutation } from "react-query";
import { form as formActions } from "../../controllers";
import { useSnackbar } from "notistack";
import { IEntity, IForm } from "../../models";
import { useTranslation } from "react-i18next";
import { IResponse } from "../../models/response-model";
import {
	TbChartBar as Stats,
	TbPencil as Edit,
	TbCopy as Duplicate,
	TbLink as Copy,
} from "react-icons/tb";

export function Forms() {
	const { enqueueSnackbar } = useSnackbar();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [searchParams, setSearchParams] = useSearchParams();
	const [search, setSearch] = useState(searchParams.get("search") ?? "");
	const [paginationModel, setPaginationModel] = useState({
		page: searchParams.get("page") ? parseInt(searchParams.get("page")!) : 0,
		pageSize:
			searchParams.get("pageSize") &&
			!isNaN(parseInt(searchParams.get("pageSize")!))
				? parseInt(searchParams.get("pageSize")!)
				: 10,
	});
	const [sortModel, setSortModel] = useState<GridSortModel>(
		new Array<GridSortItem>({
			field: searchParams.get("order")
				? searchParams.get("order")!.replaceAll("-", "")
				: "updated_at",
			sort: searchParams.get("order")?.includes("-") ? "desc" : "asc",
		})
	);
	const [rowCount, setRowCount] = useState<number>(0);
	const [forms, setForms] = useState<Array<IForm>>([]);

	const [, setSelectedRows] = useState<Array<IEntity>>([]);

	useEffect(() => {
		const searchValue = searchParams.get("search");
		if ((searchValue ?? "") !== search) {
			setPaginationModel((paginationModel) => {
				paginationModel.page = 0;
				return paginationModel;
			});
			setSearchParams((params) => {
				if (search) {
					params.set("search", search);
				} else {
					params.delete("search");
				}
				return params;
			});
		}
	}, [search, searchParams, setSearchParams]);

	const { refetch, isLoading, isRefetching, isFetching } = useQuery(
		"forms",
		() =>
			formActions.list(
				undefined,
				"",
				paginationModel.page + 1,
				sortModel.length > 0
					? `${sortModel[0].sort === "desc" ? "-" : ""}${sortModel[0].field}`
					: "-updated_at"
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setForms(res.results);
				setRowCount(res.count);
			},
			onError: () => {
				enqueueSnackbar(t("forms.errorFetching"), { variant: "error" });
			},
		}
	);

	const { mutate: duplicateForm, isLoading: isDuplicating } = useMutation(
		async ({ formId, companyId }: { formId: number; companyId?: number }) => {
			const originalForm = await formActions.getOne(formId, companyId);

			const duplicatedForm = await formActions.create(
				`${originalForm.title} (Copy)`,
				originalForm.description ?? "",
				originalForm.expected_respondents ?? 20,
				originalForm.status,
				companyId
			);

			const questions = await formActions.getQuestions(companyId!, formId);

			for (const question of questions) {
				await formActions.addQuestion(companyId!, duplicatedForm.id, {
					title: question.title,
					type: question.type,
					choices: question.choices,
					order: question.order,
				});
			}

			return duplicatedForm;
		},
		{
			onSuccess: () => {
				enqueueSnackbar(t("forms.duplicateSuccess"), { variant: "success" });
				refetch();
			},
			onError: (error) => {
				console.error("Error duplicating form:", error);
				enqueueSnackbar(t("forms.duplicateError"), { variant: "error" });
			},
		}
	);

	useEffect(() => {
		refetch();
	}, [refetch, search, paginationModel, sortModel]);

	const columns = [
		{
			field: "id",
			headerName: t("forms.id"),
			flex: 1,
			valueGetter: ({ value }: { value: number }) => value.toString(),
		},
		{
			field: "title",
			headerName: t("forms.title"),
			flex: 2,
			minWidth: 200,
		},
		{
			field: "description",
			headerName: t("forms.description"),
			flex: 3,
			minWidth: 250,
		},
		{
			field: "created_at",
			headerName: t("forms.createdAt"),
			flex: 1,
			valueGetter: (params: GridRenderCellParams<Date>) =>
				new Date(params.value).toLocaleDateString(),
		},
		{
			field: "action",
			headerName: t("forms.actions"),
			sortable: false,
			headerAlign: "right",
			align: "right",
			width: 240,
			renderCell: (row: GridRenderCellParams<IResponse>) => (
				<Box sx={{ display: "flex", gap: 1 }}>
					<Tooltip title="Edit form">
						<IconButton
							aria-label="edit"
							onClick={() => navigate(`/forms/${row.id}`)}
							edge="end"
						>
							<Edit style={{ fontSize: 18, color: "#000" }} />
						</IconButton>
					</Tooltip>
					<Tooltip title="Stats">
						<IconButton
							aria-label="stats"
							onClick={() => navigate(`/form-stats/${row.id}`)}
							edge="end"
						>
							<Stats style={{ fontSize: 18, color: "#000" }} />
						</IconButton>
					</Tooltip>
					<Tooltip title="Copy link">
						<IconButton
							aria-label="copy"
							onClick={async () => {
								try {
									await navigator.clipboard.writeText(
										window.location.origin +
											`/companies/${row.row.company!.id}/form-display/${
												row.id
											}/`
									);
									enqueueSnackbar(t("climecoCertificationProcess.linkCopied"), {
										variant: "success",
									});
								} catch (err) {
									console.error("Failed to copy: ", err);
									enqueueSnackbar(
										t("climecoCertificationProcess.linkCopyFailed"),
										{
											variant: "error",
										}
									);
								}
							}}
							edge="end"
						>
							<Copy style={{ fontSize: 18, color: "#000" }} />
						</IconButton>
					</Tooltip>
					<Tooltip title="Duplicate form">
						<IconButton
							aria-label="duplicate"
							onClick={() =>
								duplicateForm({
									formId: Number(row.id),
									companyId: row.row.company?.id,
								})
							}
							disabled={isDuplicating}
							edge="end"
						>
							<Duplicate style={{ fontSize: 18, color: "#000" }} />
						</IconButton>
					</Tooltip>
				</Box>
			),
		},
	];

	return (
		<Box>
			<Heading
				title={t("forms.title")}
				showSearch={true}
				setSearch={setSearch}
				actions={() => (
					<Box
						sx={{ width: "100%", display: "flex", justifyContent: "flex-end" }}
					>
						<Button
							variant="contained"
							color="primary"
							sx={{ alignSelf: "flex-end" }}
							onClick={() => navigate("/form")}
						>
							{t("forms.createFormButton")}
						</Button>
					</Box>
				)}
			/>
			<DataTable
				rows={forms}
				columns={columns}
				setSelectedRows={setSelectedRows}
				rowCount={rowCount}
				paginationModel={paginationModel}
				setPaginationModel={setPaginationModel}
				sortModel={sortModel}
				setSortModel={setSortModel}
				isLoading={isLoading || isRefetching || isFetching}
				sx={{
					border: "1px solid #E3E3E3",
					borderRadius: "8px",
					marginTop: 2,
				}}
			/>
		</Box>
	);
}
