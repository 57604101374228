import React, { useState, useEffect } from "react";
import { TextField, IconButton, Box, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/AddCircleOutline";
import RemoveIcon from "@mui/icons-material/RemoveCircleOutline";
import { PropTypes } from "./AttestationEntityArrayInput.types";
import { IAttestationEntity } from "../../models";
import { useTranslation } from "react-i18next";

const AttestationEntityArrayInput = ({
	array,
	setArray,
	title,
	showRelationship = false,
	required,
	disabled,
}: PropTypes) => {
	const { t } = useTranslation();
	const initialArray =
		array && array.length > 0
			? array
			: [{ name: "", address: "", url: "", relationship: "" }];
	const [inputValues, setInputValues] =
		useState<IAttestationEntity[]>(initialArray);

	useEffect(() => {
		return setInputValues(
			array && array.length > 0
				? array
				: [{ name: "", address: "", url: "", relationship: "" }]
		);
	}, [array]);

	const handleInputChange = (index: number, value: IAttestationEntity) => {
		const newInputValues = [...inputValues];
		newInputValues[index] = value;
		setInputValues(newInputValues);
		setArray(newInputValues);
	};

	const handleAddInput = () => {
		setInputValues([
			...inputValues,
			{ name: "", address: "", url: "", relationship: "" },
		]);
		setArray([
			...inputValues,
			{ name: "", address: "", url: "", relationship: "" },
		]);
	};

	const handleRemoveInput = (index: number) => {
		if (index === 0 && inputValues.length === 1) return;
		const newInputValues = [...inputValues];
		newInputValues.splice(index, 1);
		setInputValues(newInputValues);
		setArray(newInputValues);
	};

	return (
		<Box sx={{ width: "100%" }}>
			<Typography
				sx={{
					display: "flex",
					marginTop: 1,
					marginBottom: "14px",
					fontSize: 14,
					fontWeight: 700,
				}}
			>
				{title}
			</Typography>
			{inputValues.map((value, index) => (
				<Box
					key={index + " - " + value}
					display="flex"
					alignItems="center"
					justifyContent={"space-between"}
					gap={2}
					marginBottom={2}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: { xs: "column", sm: "column", md: "row" },
							gap: 1,
							width: "100%",
						}}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								width: "100%",
							}}
						>
							<span
								style={{
									display: "flex",
									marginTop: 8,
									marginBottom: "14px",
									fontSize: 14,
									fontWeight: 700,
								}}
							>
								{t("companyOnboarding.name")}
								{required["name"] && (
									<span
										style={{
											fontSize: 18,
											display: "inline-block",
											height: "5px",
											verticalAlign: "sub",
											color: "#B61313",
										}}
									>
										*
									</span>
								)}
							</span>
							<TextField
								placeholder={t("companyOnboarding.name")}
								variant="outlined"
								value={value.name}
								onChange={(e) =>
									handleInputChange(index, {
										...value,
										name: e.target.value,
									})
								}
								sx={{ flex: 1 }}
								disabled={disabled}
							/>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								width: "100%",
							}}
						>
							<span
								style={{
									display: "flex",
									marginTop: 8,
									marginBottom: "14px",
									fontSize: 14,
									fontWeight: 700,
								}}
							>
								{t("companyOnboarding.address")}
								{required["address"] && (
									<span
										style={{
											fontSize: 18,
											display: "inline-block",
											height: "5px",
											verticalAlign: "sub",
											color: "#B61313",
										}}
									>
										*
									</span>
								)}
							</span>
							<TextField
								placeholder={t("companyOnboarding.address")}
								variant="outlined"
								value={value.address}
								onChange={(e) =>
									handleInputChange(index, {
										...value,
										address: e.target.value,
									})
								}
								sx={{ flex: 1 }}
								disabled={disabled}
							/>
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								width: "100%",
							}}
						>
							<span
								style={{
									display: "flex",
									marginTop: 8,
									marginBottom: "14px",
									fontSize: 14,
									fontWeight: 700,
								}}
							>
								{t("companyOnboarding.website")}
								{required["url"] && (
									<span
										style={{
											fontSize: 18,
											display: "inline-block",
											height: "5px",
											verticalAlign: "sub",
											color: "#B61313",
										}}
									>
										*
									</span>
								)}
							</span>
							<TextField
								placeholder={t("companyOnboarding.website")}
								variant="outlined"
								value={value.url}
								onChange={(e) =>
									handleInputChange(index, {
										...value,
										url: e.target.value,
									})
								}
								sx={{ flex: 1 }}
								disabled={disabled}
							/>
						</Box>
						{showRelationship && (
							<Box
								sx={{
									display: "flex",
									flexDirection: "column",
									width: "100%",
								}}
							>
								<span
									style={{
										display: "flex",
										marginTop: 8,
										marginBottom: "14px",
										fontSize: 14,
										fontWeight: 700,
									}}
								>
									{t("companyOnboarding.relationship")}
									{required["relationship"] && (
										<span
											style={{
												fontSize: 18,
												display: "inline-block",
												height: "5px",
												verticalAlign: "sub",
												color: "#B61313",
											}}
										>
											*
										</span>
									)}
								</span>
								<TextField
									placeholder={t("companyOnboarding.relationship")}
									variant="outlined"
									value={value.relationship}
									onChange={(e) =>
										handleInputChange(index, {
											...value,
											relationship: e.target.value,
										})
									}
									sx={{ flex: 1 }}
									disabled={disabled}
								/>
							</Box>
						)}
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: { xs: "column", sm: "row" },
							gap: 1,
						}}
					>
						<IconButton
							color="primary"
							onClick={() => handleRemoveInput(index)}
							disabled={disabled || (index === 0 && inputValues.length === 1)}
						>
							<RemoveIcon />
						</IconButton>
						<IconButton
							color="primary"
							onClick={handleAddInput}
							disabled={disabled}
						>
							<AddIcon />
						</IconButton>
					</Box>
				</Box>
			))}
		</Box>
	);
};

export default AttestationEntityArrayInput;
