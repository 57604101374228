import React, { useContext, useEffect, useState } from "react";
import {
	Box,
	Typography,
	Grid,
	Button,
	List,
	ListItem,
	ListItemText,
	Divider,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Heading } from "../Heading";
import { useQuery } from "react-query";
import { form as formActions } from "../../controllers";
import { UserContext } from "../../contexts/user";
import { enqueueSnackbar } from "notistack";
import { IQuestion, IUser } from "../../models";
import { BasicModal } from "../BasicModal";
import { IResponse } from "../../models/response-model";

export const FormStats: React.FC = () => {
	const { t } = useTranslation();

	const { user } = useContext(UserContext);
	const { id: formId } = useParams();
	const [questions, setQuestions] = useState<Array<IQuestion> | null>(null);
	const [stats, setStats] = useState<{
		users_started_forms: number;
		responses: Object;
	} | null>(null);
	const [responses, setResponses] = useState<Array<IUser> | null>(null);

	const [showResponsesModal, setShowResponsesModal] = useState(false);
	const [selectedResponse, setSelectedResponse] = useState(-1);

	const { refetch: getQuestions } = useQuery(
		["questions", formId],
		() => formActions.getQuestions(Number(user?.company.id), Number(formId)),
		{
			enabled: false,
			onSuccess: (data) => {
				setQuestions(data);
			},
		}
	);

	const { refetch: refetchStats } = useQuery(
		"form-stats",
		() => formActions.getFormStats(user?.company.id ?? -1, Number(formId)),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				setStats(res);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	const { refetch: refetchResponse } = useQuery(
		"form-responses",
		() => formActions.getFormResponses(user?.company.id ?? -1, Number(formId)),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				res = res.map((r) => {
					if (r.responses) {
						r.responses = r.responses.reverse();
					}
					return r;
				});
				setResponses(res);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	useEffect(() => {
		if (user?.company) {
			refetchStats();
			getQuestions();
			refetchResponse();
		}
	}, [user, refetchStats, getQuestions, refetchResponse]);

	const getResponseValue = (response: IResponse) => {
		switch (response.question?.type) {
			case "NUMBER":
				return response.number_value ?? "";
			case "TEXT":
				return response.text_value ?? "";
			case "DATETIME":
				return response.date_value ?? "";
			case "MULTIPLE_CHOICE":
				return response.question &&
					response.question.choices &&
					response.json_value
					? response.json_value
							.map(
								(i) =>
									response.question!.choices!.find((c) => c.id === i)?.text ??
									""
							)
							.join(", ")
					: "";
			case "SINGLE_CHOICE":
				return response.question && response.question.choices
					? response.question.choices.find(
							(c) => c.id === response.number_value
					  )?.text ?? ""
					: "";
			case "RATING":
				return response.number_value?.toString();
			case "FILE":
				const file = response.file_value?.toString() ?? "";
				return (
					<a href={file} download target="_blank" rel="noreferrer">
						{file.split("/")[file.split("/").length - 1]}
					</a>
				);
			default:
				return "";
		}
	};

	return (
		<Box>
			<Heading
				title={t("forms.summaryTitle")}
				showSearch={false}
				noMarginBottom
			/>

			<Grid container spacing={4}>
				{stats && (
					<Grid item xs={12} sm={6} md={4} key={1}>
						<Box
							sx={{
								padding: 3,
								textAlign: "center",
								border: "1px solid #B1D1F0",
								borderRadius: 4,
							}}
						>
							<Typography
								variant="h4"
								sx={{ fontWeight: "bold", marginBottom: 1 }}
							>
								{stats["users_started_forms"]!}
							</Typography>
							<Typography variant="body2" color="textSecondary">
								{"Users started the survey"}
							</Typography>
						</Box>
					</Grid>
				)}
				{stats &&
					questions &&
					questions.map((q, index) => {
						let response = (stats["responses"] as Record<number, number>)
							? (stats["responses"] as Record<number, number>)[q.id]
							: null;

						return (
							<Grid item xs={12} sm={6} md={4} key={q.id}>
								<Box
									sx={{
										padding: 3,
										textAlign: "center",
										border: "1px solid #B1D1F0",
										borderRadius: 4,
									}}
								>
									<Typography
										variant="h4"
										sx={{ fontWeight: "bold", marginBottom: 1 }}
									>
										{response ? response : 0}
									</Typography>
									<Typography variant="body2" color="textSecondary">
										{t("forms.responsesPerQuestion", { questionId: index + 1 })}
									</Typography>
								</Box>
							</Grid>
						);
					})}
			</Grid>

			<Divider sx={{ my: 4 }} />

			<Typography variant="h4" sx={{ fontWeight: 600 }}>
				{t("forms.responsesPerUser")}
			</Typography>

			{responses && responses.length === 0 ? (
				<Typography variant="body1" sx={{ fontWeight: 600 }}>
					{"No responses yet"}
				</Typography>
			) : (
				<List>
					{responses &&
						responses.map((response, index) => (
							<React.Fragment key={response.id}>
								<ListItem
									sx={{ display: "flex", justifyContent: "space-between" }}
								>
									<ListItemText
										primary={
											response.first_name
												? response.first_name + " - " + response.company.name
												: response.company.name
										}
										secondary={response.email}
									/>
									<Button
										variant="outlined"
										onClick={() => {
											setSelectedResponse(index);
											setShowResponsesModal(true);
										}}
									>
										{t("forms.viewDetails")}
									</Button>
								</ListItem>
								<Divider />
							</React.Fragment>
						))}
				</List>
			)}
			<BasicModal
				width={800}
				showModal={showResponsesModal}
				handleClose={() => {
					setShowResponsesModal(false);
				}}
				children={
					<Box sx={{ width: "100%" }}>
						<Typography
							variant="h6"
							sx={{ textAlign: "center", marginBottom: 2 }}
						>
							Responses
						</Typography>
						<List>
							{responses &&
								selectedResponse > -1 &&
								responses[selectedResponse].responses
									?.filter((r) => r.question!.form!.id === Number(formId))
									.map((r) => {
										return (
											<React.Fragment key={r.id}>
												<ListItem
													sx={{
														display: "flex",
														justifyContent: "space-between",
													}}
												>
													<ListItemText
														primary={r.question?.title}
														secondary={getResponseValue(r)}
													/>
												</ListItem>
												<Divider />
											</React.Fragment>
										);
									})}
						</List>
					</Box>
				}
			/>
		</Box>
	);
};
