import React, { useState, useContext, useEffect } from "react";
import {
	Typography,
	Box,
	Button,
	Divider,
	ListItem,
	ListItemText,
	List,
	Chip,
} from "@mui/material";

import { form as formActions } from "../../controllers";
import { UserContext } from "../../contexts/user";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { useQuery } from "react-query";
import { PropTypes } from "./FormResults.types";
import { BasicModal } from "../BasicModal";
import { IResponse } from "../../models/response-model";
import { IUser } from "../../models";

export default function FormResults({ forms }: PropTypes) {
	const { user } = useContext(UserContext);
	const { enqueueSnackbar } = useSnackbar();
	const { t } = useTranslation();
	const [responses, setResponses] = useState<Array<IResponse> | null>(null);
	const [userResponses, setUserRespones] = useState<Array<IUser> | null>(null);

	const [selectedResponse, setSelectedResponse] = useState(-1);
	const [showResponsesModal, setShowResponsesModal] = useState(false);

	const [selected, setSelected] = useState("bySupplier");

	const getResponseValue = (response: IResponse) => {
		switch (response.question?.type) {
			case "NUMBER":
				return response.number_value?.toString() ?? "";
			case "TEXT":
				return response.text_value ?? "";
			case "DATETIME":
				return response.date_value ?? "";
			case "MULTIPLE_CHOICE":
				return response.json_value
					? response.json_value
							.map(
								(i) =>
									response.question?.choices?.find((c) => c.id === i)?.text ??
									""
							)
							.join(", ")
					: "";
			case "SINGLE_CHOICE":
				return (
					response.question?.choices?.find(
						(c) => c.id === response.number_value
					)?.text ?? ""
				);
			case "RATING":
				return response.number_value?.toString() ?? "";
			case "FILE":
				return response.file_value?.toString() ?? "";
			default:
				return "";
		}
	};

	const { refetch: refetchResponse } = useQuery(
		"form-responses",
		() =>
			formActions.getFormResponses(
				forms?.company_id ?? -1,
				Number(forms?.form_id)
			),
		{
			enabled: false,
			refetchOnWindowFocus: false,
			retry: false,
			onSuccess: (res) => {
				res = res.map((r) => {
					if (r.responses) {
						r.responses = r.responses.reverse();
					}
					return r;
				});
				console.log(res);
				const filteredResponses = res.flatMap((r) => r.responses || []);
				setResponses(filteredResponses);

				setUserRespones(res);
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	useEffect(() => {
		if (forms?.form_id) {
			refetchResponse();
		}
	}, [refetchResponse, forms, user]);

	const groupedQuestions = responses
		?.filter((response) => response.question?.is_featured)
		.reduce((acc, response) => {
			const questionId = response.question?.id;
			if (!questionId) return acc;

			if (!acc[questionId]) {
				acc[questionId] = [];
			}
			acc[questionId].push(response);
			return acc;
		}, {} as Record<number, IResponse[]>);

	const handleExportCSV = () => {
		if (!responses) return;

		const csvHeader = ["Question Title", "Response", "Responded By"];
		const csvRows = responses.map((response) => {
			const questionTitle = response.question?.title || "No title";
			const responseValue = getResponseValue(response).replace(/,/g, ";");
			const userEmail = response.user?.email || "Anonymous";
			return [questionTitle, responseValue, userEmail];
		});

		const csvContent = [csvHeader, ...csvRows]
			.map((row) => row.join(","))
			.join("\n");

		const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
		const url = URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute("download", "survey_results.csv");
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	const exportQuestionResponsesToCSV = (
		questionTitle: string,
		questionResponses: IResponse[],
		getResponseValue: (response: IResponse) => string
	) => {
		const csvHeader = ["Response", "Responded By"];
		const csvRows = questionResponses.map((response) => {
			const responseValue = String(getResponseValue(response)).replace(
				/,/g,
				";"
			);
			const userEmail = response.user?.email || "Anonymous";
			return [responseValue, userEmail];
		});

		const csvContent = [csvHeader, ...csvRows]
			.map((row) => row.join(","))
			.join("\n");

		const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
		const url = URL.createObjectURL(blob);
		const link = document.createElement("a");
		link.href = url;
		link.setAttribute(
			"download",
			`${questionTitle.replace(/ /g, "_")}_responses.csv`
		);
		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);
	};

	return (
		<>
			<Box sx={{ marginTop: 2 }}>
				<Box
					sx={{
						display: "flex",
						flexDirection: {
							xs: "column",
							sm: "row",
						},
						alignItems: "flex-start",
						justifyContent: "space-between",
						width: "100%",
						gap: 1,
						marginBottom: 3,
					}}
				>
					<Box
						sx={{
							flex: 1,
							textAlign: {
								xs: "center",
								sm: "left",
							},
						}}
					>
						<Typography variant="h4" sx={{ fontWeight: 600 }}>
							{t("forms.surveyResults")}
						</Typography>
						<Typography
							variant="body2"
							sx={{
								marginTop: 1,
							}}
						>
							{t("forms.surveyResultsSubtitle")}
						</Typography>
					</Box>
					<Button
						variant="contained"
						sx={{
							backgroundColor: "#00796B",
							color: "#fff",
							fontWeight: "bold",
							borderRadius: "24px",
							textTransform: "none",
							padding: "6px 16px",
						}}
						onClick={() => {
							handleExportCSV();
						}}
					>
						{t("forms.exportCSV")}
					</Button>
				</Box>

				<Box
					sx={{
						display: "flex",
						flexWrap: "wrap",
						gap: 4,
						marginTop: 2,
						marginBottom: 4,
					}}
				>
					{groupedQuestions &&
						Object.entries(groupedQuestions).map(
							([questionId, questionResponses]) => {
								const question = questionResponses[0]?.question;
								if (!question) return null;

								let highestResponse = "";
								let highestValue: string | React.ReactNode = "";

								if (question.aggregation_type === "PERCENTAGE") {
									if (question.type === "NUMBER") {
										const count = questionResponses.reduce(
											(prev, current) => prev + current.number_value,
											0
										);
										const percentage = count / questionResponses.length;
										highestResponse = "Average";
										highestValue = (
											<>
												{`${new Intl.NumberFormat("en-US", {
													minimumFractionDigits: 0,
													maximumFractionDigits: 2,
												}).format(percentage)}`}
												{question.units ? (
													<small style={{ fontSize: 10, paddingLeft: "2px" }}>
														{question.units}
													</small>
												) : (
													""
												)}
											</>
										);
									} else {
										const choices = question.choices || [];
										const percentages = choices.map((choice) => {
											const count = questionResponses.filter(
												(r) =>
													(question.type === "SINGLE_CHOICE" &&
														r.number_value === choice.id) ||
													(question.type === "MULTIPLE_CHOICE" &&
														r.json_value?.includes(choice.id))
											).length;
											const percentage =
												(count / questionResponses.length) * 100;
											return { choice: choice.text, percentage };
										});
										const maxPercentage = percentages.reduce(
											(prev, current) =>
												current.percentage > prev.percentage ? current : prev,
											{ choice: "", percentage: 0 }
										);
										highestResponse = maxPercentage.choice;
										highestValue = `${new Intl.NumberFormat("en-US", {
											minimumFractionDigits: 0,
											maximumFractionDigits: 2,
										}).format(maxPercentage.percentage)}%`;
									}
								} else if (question.aggregation_type === "VALUE") {
									if (question.type === "NUMBER") {
										const count = questionResponses.reduce(
											(prev, current) => prev + current.number_value,
											0
										);
										highestResponse = "Total";
										highestValue = (
											<>
												{`${new Intl.NumberFormat("en-US", {
													minimumFractionDigits: 0,
													maximumFractionDigits: 2,
												}).format(count)}`}
												{question.units ? (
													<small style={{ fontSize: 10, paddingLeft: "2px" }}>
														{question.units}
													</small>
												) : (
													""
												)}
											</>
										);
									} else {
										const choices = question.choices || [];
										const counts = choices.map((choice) => {
											const count = questionResponses.filter(
												(r) =>
													(question.type === "SINGLE_CHOICE" &&
														r.number_value === choice.id) ||
													(question.type === "MULTIPLE_CHOICE" &&
														r.json_value?.includes(choice.id))
											).length;
											return { choice: choice.text, count };
										});
										const maxCount = counts.reduce(
											(prev, current) =>
												current.count > prev.count ? current : prev,
											{ choice: "", count: 0 }
										);
										highestResponse = maxCount.choice;
										highestValue = (
											<>
												{`${maxCount.count}`}
												<small style={{ fontSize: 10, paddingLeft: "2px" }}>
													time(s)
												</small>
											</>
										);
									}
								}

								return (
									<Box
										sx={{
											flex: "1 1 calc(25% - 32px)",
											padding: 3,
											textAlign: "left",
											border: "1px solid #B1D1F0",
											borderRadius: 4,
											backgroundColor: "#fff",
											maxWidth: "200px",
										}}
									>
										<Typography
											variant="h4"
											sx={{ fontSize: 30, marginBottom: 0.5 }}
										>
											{highestValue}
										</Typography>
										<Chip
											sx={{
												marginBottom: 1,
												fontSize: "10px",
												paddingLeft: "12px",
												paddingRight: "12px",
												color: "white",
												backgroundColor: "#03b093",
											}}
											size="small"
											label={highestResponse}
										/>
										<Typography variant="body2" sx={{ color: "textSecondary" }}>
											{question.title}
										</Typography>
									</Box>
								);
							}
						)}
				</Box>

				<Box
					sx={{
						position: "relative",
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						backgroundColor: "#F5F9FF",
						border: "1px solid #B1D1F0",
						borderRadius: "24px",
						width: "240px",
						height: "40px",
						overflow: "hidden",
					}}
				>
					<Box
						sx={{
							position: "absolute",
							top: 0,
							left: selected === "byQuestion" ? "0" : "50%",
							width: "50%",
							height: "100%",
							backgroundColor: "#fff",
							borderRadius: "24px",
							boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
							transition: "all 0.3s ease",
						}}
					/>

					<Box
						onClick={() => setSelected("byQuestion")}
						sx={{
							zIndex: 1,
							width: "50%",
							textAlign: "center",
							cursor: "pointer",
							color: selected === "byQuestion" ? "#003399" : "#6B7280",
							fontWeight: "bold",
							textTransform: "none",
						}}
					>
						<Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
							{t("forms.byQuestion")}
						</Typography>
					</Box>

					<Box
						onClick={() => setSelected("bySupplier")}
						sx={{
							zIndex: 1,
							width: "50%",
							textAlign: "center",
							cursor: "pointer",
							color: selected === "bySupplier" ? "#003399" : "#6B7280",
							textTransform: "none",
						}}
					>
						<Typography sx={{ fontSize: "12px", fontWeight: "bold" }}>
							{t("forms.bySupplier")}
						</Typography>
					</Box>
				</Box>

				{selected === "bySupplier" && (
					<Box
						sx={{
							border: "1px solid #B1D1F0",
							borderRadius: 4,
							padding: 2,
							marginTop: 4,
							maxHeight: 400,
							overflow: "auto",
						}}
					>
						{userResponses && userResponses.length === 0 ? (
							<Typography
								variant="body1"
								sx={{ fontWeight: 600, fontSize: 12 }}
							>
								{"No responses yet"}
							</Typography>
						) : (
							<List>
								{userResponses &&
									userResponses.map((response, index) => (
										<React.Fragment key={response.id}>
											<ListItem
												sx={{
													display: "flex",
													justifyContent: "space-between",
												}}
											>
												<ListItemText
													primary={
														response.first_name
															? response.first_name +
															  " - " +
															  response.company.name
															: response.company.name
													}
													secondary={response.email}
												/>
												<Button
													variant="outlined"
													onClick={() => {
														setSelectedResponse(index);
														setShowResponsesModal(true);
													}}
												>
													{t("forms.viewDetails")}
												</Button>
											</ListItem>
											<Divider />
										</React.Fragment>
									))}
							</List>
						)}
					</Box>
				)}
				{selected === "byQuestion" && (
					<Box
						sx={{
							border: "1px solid #B1D1F0",
							borderRadius: 4,
							padding: 2,
							marginTop: 4,
							maxHeight: 400,
							overflow: "auto",
						}}
					>
						{!responses || responses.length === 0 ? (
							<Typography
								variant="body1"
								sx={{ fontWeight: 600, fontSize: 12 }}
							>
								{"No responses yet"}
							</Typography>
						) : (
							<List>
								{Object.entries(
									responses.reduce((grouped, response) => {
										const questionId = response.question?.id;
										if (!questionId) return grouped;
										if (!grouped[questionId]) {
											grouped[questionId] = [];
										}
										grouped[questionId].push(response);
										return grouped;
									}, {} as Record<number, IResponse[]>)
								).map(([questionId, questionResponses]) => {
									const questionTitle =
										questionResponses[0]?.question?.title || "";

									return (
										<Box key={questionId} sx={{ marginBottom: 2 }}>
											<Box
												sx={{
													display: "flex",
													flexDirection: "row",
													justifyContent: "space-between",
													alignItems: "center",
												}}
											>
												<Typography
													variant="h6"
													sx={{ fontSize: 16, fontWeight: "bold" }}
												>
													{questionTitle}
												</Typography>
												<Button
													variant="text"
													sx={{
														color: "#00796B",
														fontWeight: "bold",
													}}
													onClick={() => {
														exportQuestionResponsesToCSV(
															questionTitle,
															questionResponses,
															getResponseValue
														);
													}}
												>
													{t("forms.exportCSV")}
												</Button>
											</Box>
											<List>
												{questionResponses.map((response, index) => (
													<ListItem
														key={index}
														sx={{
															display: "flex",
															flexDirection: "row",
															alignItems: "center",
															justifyContent: "space-between",
														}}
													>
														<ListItemText
															primary={getResponseValue(response)}
														/>
														<Typography
															variant="body2"
															sx={{ marginLeft: 2, color: "text.secondary" }}
														>
															{response.user?.email}
														</Typography>
													</ListItem>
												))}
											</List>
											<Divider />
										</Box>
									);
								})}
							</List>
						)}
					</Box>
				)}
			</Box>
			<BasicModal
				width={800}
				showModal={showResponsesModal}
				handleClose={() => setShowResponsesModal(false)}
			>
				<Box sx={{ width: "100%" }}>
					<Typography
						variant="h6"
						sx={{ textAlign: "center", marginBottom: 2 }}
					>
						Responses
					</Typography>
					<List>
						{userResponses &&
							selectedResponse > -1 &&
							userResponses[selectedResponse].responses
								?.filter((r) => r.question?.form?.id === Number(forms?.form_id))
								.map((r) => (
									<React.Fragment key={r.id}>
										<ListItem>
											<ListItemText
												primary={r.question?.title}
												secondary={getResponseValue(r)}
											/>
										</ListItem>
										<Divider />
									</React.Fragment>
								))}
					</List>
				</Box>
			</BasicModal>
		</>
	);
}
