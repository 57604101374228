import { useState, useRef, useEffect } from "react";
import {
	Alert,
	Box,
	CircularProgress,
	IconButton,
	TextField,
} from "@mui/material";
import { Heading } from "../Heading";
import { useMutation } from "react-query";
import { adminAI as adminAIActions } from "../../controllers";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { IMessage } from "../../models/message-model";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import PendingIcon from "@mui/icons-material/Pending";
import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkGfm from "remark-gfm";

export function AdminAI() {
	const { t } = useTranslation();
	const { enqueueSnackbar } = useSnackbar();
	const [messages, setMessages] = useState<Array<IMessage>>([]);
	const [question, setQuestion] = useState("");
	const messagesEndRef = useRef<HTMLDivElement>(null);

	const scrollToBottom = () => {
		messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
	};

	useEffect(() => {
		scrollToBottom();
	}, [messages]);

	const { isLoading, mutate } = useMutation(
		"admin-ai",
		({ question }: { question: string }) =>
			adminAIActions.create({ query: question }),
		{
			retry: false,
			onSuccess: (res) => {
				if (res.error) {
					console.log(res.error);
					enqueueSnackbar(res.error, {
						variant: "error",
					});
				} else {
					setMessages((messages) => {
						return [
							...messages,
							{
								id: new Date().getTime(),
								text: res.ai_response ?? "",
								query: res.query,
								type: "RESPONSE",
							},
						];
					});
				}
			},
			onError: (error: any) => {
				console.log(error);
				enqueueSnackbar(t("general.errorMessage"), {
					variant: "error",
				});
			},
		}
	);

	const handleSendQuestion = () => {
		setMessages((messages) => {
			return [
				...messages,
				{
					id: new Date().getTime(),
					text: question,
					type: "QUESTION",
				},
			];
		});
		setQuestion("");
		mutate({ question });
		setTimeout(() => {
			scrollToBottom();
		}, 500);
	};

	const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
		if (event.key === "Enter" && !event.ctrlKey && !event.metaKey) {
			event.preventDefault();
			handleSendQuestion();
		}
	};

	return (
		<Box sx={{ height: "100%" }}>
			<Heading title={"Admin AI"} showSearch={false} actions={() => <></>} />
			<Box
				sx={{
					display: "flex",
					flex: 1,
					justifyContent: "space-between",
					flexDirection: "column",
					gap: 2,
					height: "calc(100% - 58px)",
				}}
			>
				<Box
					flex={1}
					sx={{
						gap: 2,
						display: "flex",
						flexDirection: "column",
						overflow: "auto",
					}}
				>
					{messages.map((message) => {
						return message.type === "RESPONSE" ? (
							<>
								<ReactMarkdown
									rehypePlugins={[rehypeRaw]}
									remarkPlugins={[remarkGfm]}
								>
									{message.text}
								</ReactMarkdown>
								{message.query && (
									<Alert severity="info">
										Query used: <b>{message.query}</b>
									</Alert>
								)}
							</>
						) : (
							<Box
								sx={{
									backgroundColor: "#f2f2f2",
									textAlign: "right",
									maxWidth: "80%",
									alignSelf: "flex-end",
									padding: 2,
									borderRadius: 2,
								}}
							>
								<b>{message.text}</b>
							</Box>
						);
					})}
					{isLoading && (
						<Box
							sx={{
								display: "flex",
								alignItems: "center",
								justifyContent: "center",
								padding: 1,
							}}
						>
							<CircularProgress size={24} />
						</Box>
					)}
					{messages.length === 0 && (
						<Alert severity="info">
							There are no messages in this session yet. Ask anything you want
							to know from your system data.
							<br />
							<b>
								Have in mind, for now the messages will disappear the moment you
								reload this page.
							</b>
						</Alert>
					)}
					<div ref={messagesEndRef} />
				</Box>
				<Box
					sx={{
						display: "flex",
						gap: 2,
						justifyContent: "space-between",
						alignItems: "flex-start",
						backgroundColor: "#fafafa",
						paddingRight: 2,
					}}
				>
					<TextField
						value={question}
						onChange={(e) => {
							setQuestion(e.target.value);
						}}
						onKeyDown={handleKeyDown}
						sx={{
							flex: 1,
							backgroundColor: "transparent",
							"& textarea": { backgroundColor: "white" },
						}}
						multiline
						placeholder="Ask anything about the system data"
					></TextField>
					<IconButton
						color="primary"
						sx={{
							backgroundColor: "#34918E",
							borderRadius: "50%",
							marginTop: 2,
							"&:hover": {
								backgroundColor: "#34918E",
							},
							"&:disabled": {
								backgroundColor: "#34918E",
								opacity: 0.5,
							},
						}}
						onClick={handleSendQuestion}
						disabled={question.trim().length === 0 || isLoading}
					>
						{isLoading ? (
							<PendingIcon sx={{ color: "white" }} />
						) : (
							<ArrowUpwardIcon sx={{ color: "white" }} />
						)}
					</IconButton>
				</Box>
			</Box>
		</Box>
	);
}
